// Replace all instances of a string within another string (buggy on IE)
export function replaceAll(str, find, replace) {
  let string = str;
  if (Array.isArray(find)) {
    find.forEach(
      (test, index) => {
        string = string.replace(new RegExp(find[index], 'g'), replace[index]);
      },
    );
  } else {
    string = str.replace(new RegExp(find, 'g'), replace);
  }
  return string;
}

const adjustDescriptionLength = (content, limit) => {
  let adjustedLengthContent = content;
  if (adjustedLengthContent.join(' ').length > 350) {      
    adjustedLengthContent = adjustedLengthContent.slice(0, limit - 5);
    if (adjustedLengthContent.join(' ').length > 350) {
      adjustedLengthContent = adjustDescriptionLength(adjustedLengthContent, limit - 5);
    }
  }
  return adjustedLengthContent;
};

export function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return '';
}

export function integerWithEuropeThousandSeparator(num) {
  let value = '';
  const sep = '.';
  if (num !== null && num !== undefined && !(num instanceof String)) {
    value = num.toString();
  } else {
    value = num;
  }
  return value !== undefined && value !== null ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep) : '0';
}

// https://stackoverflow.com/questions/2685911/is-there-a-way-to-round-numbers-into-a-reader-friendly-format-e-g-1-1k?noredirect=1&lq=1

export function abbreviateNumber(number, decPlaces = 2) {
  if (isNaN(number)) {
    number = number.toString();
  }
  let parsedValue = parseInt(number.replace(/\./g, ''), 10);
  const abbrev = ['k', 'm', 'b', 't'];
  decPlaces = Math.pow(10, decPlaces);
  for (let i = abbrev.length - 1; i >= 0; i -= 1) {
    const size = Math.pow(10, (i + 1) * 3);
    if (size <= parsedValue) {
      parsedValue = Math.round(parsedValue * decPlaces / size) / decPlaces;
      if ((parsedValue === 1000) && (i < abbrev.length - 1)) {
        parsedValue = 1;
        i += 1;
      }
      parsedValue += abbrev[i];
      break;
    }
  }
  return parsedValue;
}

export function stringStartsWith(string, search, position) {
  position = position || 0;
  return string.indexOf(search, position) === position;
}
