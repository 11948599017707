import React from 'react';

import GridItem from 'shared-components/components/GridItem';

export default () => (
  <GridItem
    behavior="fixed"
    className="lndg__header c-bg--w bb--s is-rel"
  >
    <div className="lndg__header__logo mr--m" />
    <p className="lndg__header__sentence tp-s--l tp-w--l c-txt--f0">
      Trova la casa giusta per te
    </p>
  </GridItem>
);
