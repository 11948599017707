import React from 'react';
import PropTypes from 'prop-types';
import { useCtxGlobal } from 'client/app/Container';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';

import Buttons from './Buttons';

const Agent = ({
  device,
  data,
  user,
  isLocalStorageAvailable,
  hasPlaceholder,
  logoSrc,
  imgServer,
}) => {
  const {
    publisherName,
    description,
    hasCrossSubscription,
    publisherInitials,
    publisherParent,
    publisherPhoto,
  } = data;

  const descriptionComp = description && (
    <p className="pbInfos__agnt--desc tp-w--s c-txt--f0">{description}</p>
  );

  const parentTitle = `Vedi tutti gli annunci dell'agenzia ${publisherParent.name}`;

  return (
    <Grid className="pbInfos__cont pbInfos__cont--agnt c-bg--w is-rel">
      <Grid
        className="pbInfos__cont--all-data"
      >
        <Grid
          className="pbInfos__circle-data is-rel mr--ml"
        >
          <div className="pbInfos__pic b-r--pill b--m tp-w--m c-txt--w c-bg--f3 tp--up">
            {publisherPhoto ? (
              <img
                className="b-r--pill"
                src={`${imgServer}100x100/${publisherPhoto}`}
                alt={publisherName}
                width="76"
                height="76"
              />
            ) : (
              publisherInitials
            )}
          </div>
          <Grid direction="column">
            <p className="pbInfos__agnt--lbl tp-s--x2s tp-w--l c-txt--secondary c-bg--bg">
              Agente Pro
            </p>
            <h1 className="pbInfos__name c-txt--secondary">{publisherName}</h1>
          </Grid>
        </Grid>
        <>
          {/* onmobile */}
          <div className="pbInfos--agnt--desc-cnt-nodesk">
            {descriptionComp}
          </div>
          <Grid
            className="pbInfos__box-logo pt--xs pb--xs pl--s pr--s"
            direction="column"
            align="baseline"
          >
            <p className="pbInfos__agnt--prnt tp-w--s c-txt--f0 tp-s--x2s">
              Agente immobiliare per{' '}
            </p>
            <Grid>
              {!hasPlaceholder && (
                <a href={publisherParent.url} title={parentTitle}>
                  <img
                    className={`pbInfos__logo pbInfos__logo--abs${hasCrossSubscription
                      ? ' pbInfos__logo--pm'
                      : ' pbInfos__logo--csa'
                      }`}
                    src={logoSrc}
                    alt={publisherParent.name}
                  />
                </a>
              )}
              <Grid direction="column" className="pbInfos__agnt--box ml--ls">
                <a
                  className="pbInfos__agnt--prnt--lnk tp-w--s tp-s--m c-txt--f0"
                  href={publisherParent.url}
                  title={parentTitle}
                >
                  {publisherParent.name}<br />
                  {publisherName}
                </a>
              </Grid>
            </Grid>
          </Grid>
        </>
        <GridItem className="pbInfos__agnt">
          <Buttons
            publisher={data}
            device={device}
            user={user}
            isLocalStorageAvailable={isLocalStorageAvailable}
            logoSrc={logoSrc}
          />
        </GridItem>
      </Grid>
      {/* on desktop */}
      <Grid className="pbInfos--agnt--desc-cnt pbInfos--agnt--desc-cnt-nomob">
        {descriptionComp}
        <GridItem />
      </Grid>

    </Grid>
  );
};

export default Agent;

Agent.propTypes = {
  device: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  isLocalStorageAvailable: PropTypes.bool,
  hasPlaceholder: PropTypes.bool,
  logoSrc: PropTypes.string,
  imgServer: PropTypes.string,
};

Agent.defaultProps = {
  device: '',
  data: {},
  user: {},
  isLocalStorageAvailable: false,
  hasPlaceholder: false,
  logoSrc: '',
  imgServer: '',
};
