import React from 'react';
import PropTypes from 'prop-types';

import Info from 'shared-vectors/icons/CercaCasaInfo';
import InfoNoRes from 'shared-vectors/icons/CercaCasaInfoNoRes';
import { Icon } from 'shared-vectors';

import GridItem from 'shared-components/components/GridItem';

const Count = ({
  total,
  hideBtn,
  isFetching,
  setDialog,
  className,
}) => {
  const isNoRes = parseInt(total, 10) === 0;

  const infoDialog = () => setDialog(isNoRes ? 'no_res' : 'with_res');

  const animated = () => (
    <svg
      className="lndg__foot__anim"
      version="1.1"
      id="L5"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 0 0"
    >
      <circle fill="#000" stroke="none" cx="24" cy="50" r="6">
        <animateTransform 
          attributeName="transform" 
          dur="1s" 
          type="translate" 
          values="0 15 ; 0 -15; 0 15" 
          repeatCount="indefinite" 
          begin="0.1"
        />
      </circle>
      <circle fill="#000" stroke="none" cx="48" cy="50" r="6">
        <animateTransform 
          attributeName="transform" 
          dur="1s" 
          type="translate" 
          values="0 10 ; 0 -10; 0 10" 
          repeatCount="indefinite" 
          begin="0.2"
        />
      </circle>
      <circle fill="#000" stroke="none" cx="72" cy="50" r="6">
        <animateTransform 
          attributeName="transform" 
          dur="1s" 
          type="translate" 
          values="0 5 ; 0 -5; 0 5" 
          repeatCount="indefinite" 
          begin="0.3"
        />
      </circle>
    </svg>
  );

  return (
    <GridItem className={`lndg__foot__txt pr--m tp-s--s tp-w--m c-txt--f0 ${className}`}>
      <span className={`lndg__foot__txt__cnt pr--s ${isNoRes ? ' nores is-clickable' : ''}`} onClick={isNoRes ? infoDialog : null}>
        <span className="lndg__foot__ico is-clickable" onClick={!isNoRes ? infoDialog : null}>
          {isNoRes ? <Icon name={InfoNoRes} illustration /> : <Icon name={Info} fill />}
        </span>
        Adesso&nbsp;<span className="tp-w--l">{((!hideBtn && isFetching) || total === null) ? animated() : total}</span>&nbsp;immobili  
      </span>
    </GridItem>
  );
};

export default Count;

Count.propTypes = {
  total: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  isFetching: PropTypes.bool,
  hideBtn: PropTypes.bool,
  setDialog: PropTypes.func,
  className: PropTypes.string,
};

Count.defaultProps = {
  total: null,
  isFetching: false,
  hideBtn: false,
  setDialog: () => {},
  className: '',
};
