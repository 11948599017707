/* eslint-disable no-console */
// @Flow
export const FETCH_AGENT_PRO = 'FETCH_AGENT_PRO';
export const FETCH_AGENT_PRO_START = 'FETCH_AGENT_PRO_START';
export const FETCH_AGENT_PRO_SUCCESS = 'FETCH_AGENT_PRO_SUCCESS';
export const FETCH_AGENT_PRO_ERROR = 'FETCH_AGENT_PRO_ERROR';

export const FETCH_SEARCH_AGENT_PRO = 'FETCH_SEARCH_AGENT_PRO';
export const FETCH_SEARCH_AGENT_PRO_START = 'FETCH_SEARCH_AGENT_PRO_START';
export const FETCH_SEARCH_AGENT_PRO_SUCCESS = 'FETCH_SEARCH_AGENT_PRO_SUCCESS';
export const FETCH_SEARCH_AGENT_PRO_ERROR = 'FETCH_SEARCH_AGENT_PRO_ERROR';

const completeParams = (params, action) => {
  const { location } = typeof window !== 'undefined' ? window : {};

  if (!('uri' in params) && location) {
    const { pathname = '', search = '' } = location;
    params.uri = `${pathname}${search}`;
    console.log('uri not found in action: ', action, 'added:', params.uri);
  }

  return params;
};

const fetchAgentProFn = (fetch: Function, verb: string, params: Object, action: string) => fetch('/portal-srp/api/v1/agentPro', {
  method: verb,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Connection: 'keep-alive',
  },
  body: JSON.stringify(completeParams(params, action)),
  credentials: 'same-origin',
});

export function fetchAgentPro(params: Object) {
  return ({ fetch }: { fetch: Function }) => ({
    type: FETCH_AGENT_PRO,
    payload: {
      search: 'something',
      promise: fetchAgentProFn(fetch, 'POST', params, FETCH_AGENT_PRO)
        .then(response => response.json())
        .catch(err => console.error('error', err)),
    },
  });
}


const fetchSearchAgentProFn = (fetch: Function, verb: string, params: Object, action: string) => fetch('/portal-srp/api/v1/agentPro/search', {
  method: verb,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Connection: 'keep-alive',
  },
  body: JSON.stringify(completeParams(params, action)),
  credentials: 'same-origin',
});

export function fetchSearchAgentPro(params: Object) {
  return ({ fetch }: { fetch: Function }) => ({
    type: FETCH_SEARCH_AGENT_PRO,
    payload: {
      search: 'something',
      promise: fetchSearchAgentProFn(fetch, 'POST', params, FETCH_SEARCH_AGENT_PRO)
        .then(response => response.json())
        .catch(err => console.error('error', err)),
    },
  });
}
