import { canUseDOM } from '../../components';

export const FETCH_CERCACASA_PARAMS_CLIENT = 'FETCH_CERCACASA_PARAMS_CLIENT';
export const FETCH_CERCACASA_PARAMS = 'FETCH_CERCACASA_PARAMS';
export const FETCH_CERCACASA_PARAMS_START = 'FETCH_CERCACASA_PARAMS_START';
export const FETCH_CERCACASA_PARAMS_SUCCESS = 'FETCH_CERCACASA_PARAMS_SUCCESS';
export const FETCH_CERCACASA_PARAMS_ERROR = 'FETCH_CERCACASA_PARAMS_ERROR';

export const FETCH_CERCACASA_CHATPARAMS_CLIENT = 'FETCH_CERCACASA_CHATPARAMS_CLIENT';
export const FETCH_CERCACASA_CHATPARAMS = 'FETCH_CERCACASA_CHATPARAMS';
export const FETCH_CERCACASA_CHATPARAMS_START = 'FETCH_CERCACASA_CHATPARAMS_START';
export const FETCH_CERCACASA_CHATPARAMS_SUCCESS = 'FETCH_CERCACASA_CHATPARAMS_SUCCESS';
export const FETCH_CERCACASA_CHATPARAMS_ERROR = 'FETCH_CERCACASA_CHATPARAMS_ERROR';

export const FETCH_CERCACASA_SAVEPARAMS_CLIENT = 'FETCH_CERCACASA_SAVEPARAMS_CLIENT';
export const FETCH_CERCACASA_SAVEPARAMS = 'FETCH_CERCACASA_SAVEPARAMS';
export const FETCH_CERCACASA_SAVEPARAMS_START = 'FETCH_CERCACASA_SAVEPARAMS_START';
export const FETCH_CERCACASA_SAVEPARAMS_SUCCESS = 'FETCH_CERCACASA_SAVEPARAMS_SUCCESS';
export const FETCH_CERCACASA_SAVEPARAMS_ERROR = 'FETCH_CERCACASA_SAVEPARAMS_ERROR';

export const FETCH_CERCACASA_THANKYOU_CLIENT = 'FETCH_CERCACASA_THANKYOU_CLIENT';
export const FETCH_CERCACASA_THANKYOU = 'FETCH_CERCACASA_THANKYOU';
export const FETCH_CERCACASA_THANKYOU_START = 'FETCH_CERCACASA_THANKYOU_START';
export const FETCH_CERCACASA_THANKYOU_SUCCESS = 'FETCH_CERCACASA_THANKYOU_SUCCESS';
export const FETCH_CERCACASA_THANKYOU_ERROR = 'FETCH_CERCACASA_THANKYOU_ERROR';

export const FETCH_CERCACASA_ROOMSANDBATHROOMS = 'FETCH_CERCACASA_ROOMSANDBATHROOMS';
export const FETCH_CERCACASA_ROOMSANDBATHROOMS_START = 'FETCH_CERCACASA_ROOMSANDBATHROOMS_START';
export const FETCH_CERCACASA_ROOMSANDBATHROOMS_SUCCESS = 'FETCH_CERCACASA_ROOMSANDBATHROOMS_SUCCESS';
export const FETCH_CERCACASA_ROOMSANDBATHROOMS_ERROR = 'FETCH_CERCACASA_ROOMSANDBATHROOMS_ERROR';

export const FETCH_CERCACASA_BUDGET = 'FETCH_CERCACASA_BUDGET';
export const FETCH_CERCACASA_BUDGET_START = 'FETCH_CERCACASA_BUDGET_START';
export const FETCH_CERCACASA_BUDGET_SUCCESS = 'FETCH_CERCACASA_BUDGET_SUCCESS';
export const FETCH_CERCACASA_BUDGET_ERROR = 'FETCH_CERCACASA_BUDGET_ERROR';

export const FETCH_CERCACASA_WHERE = 'FETCH_CERCACASA_WHERE';
export const FETCH_CERCACASA_WHERE_START = 'FETCH_CERCACASA_WHERE_START';
export const FETCH_CERCACASA_WHERE_SUCCESS = 'FETCH_CERCACASA_WHERE_SUCCESS';
export const FETCH_CERCACASA_WHERE_ERROR = 'FETCH_CERCACASA_WHERE_ERROR';

export const FETCH_CERCACASA_FILTERS = 'FETCH_CERCACASA_FILTERS';
export const FETCH_CERCACASA_FILTERS_START = 'FETCH_CERCACASA_FILTERS_START';
export const FETCH_CERCACASA_FILTERS_SUCCESS = 'FETCH_CERCACASA_FILTERS_SUCCESS';
export const FETCH_CERCACASA_FILTERS_ERROR = 'FETCH_CERCACASA_FILTERS_ERROR';

export const RESET_CERCACASA_WHERE = 'RESET_CERCACASA_WHERE';

const proxy = '/portal-srp/api/v1/cerca-casa/';
const method = 'POST';

const fetchFn = (fetch, url, verb, params) => fetch(url, {
  method: verb,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Connection: 'keep-alive',
  },
  body: JSON.stringify(params),
  credentials: 'same-origin',
});

const getFetchBody = params => ({
  site: params.apireq.site, 
  domain: params.domain, 
  locale: params.locale, 
  apireq: params.apireq, 
  uri: params.uri,
  queryFilters: params.queryFilters,
  baseUri: params.uri,
});

export function fetchCercacasaParams(params) {
  if (canUseDOM) {
    return () => ({
      type: FETCH_CERCACASA_PARAMS_CLIENT,
    });
  }
  return ({ fetch }) => ({
    type: FETCH_CERCACASA_PARAMS,
    payload: {
      promise: fetchFn(fetch, proxy, method, getFetchBody(params))
        .then(response => response.json())
        .catch(err => console.error('Error get CERCACASA params', err)),
    },
  });
}

export function fetchCercacasaChatParams(params) {
  if (canUseDOM) {
    return () => ({
      type: FETCH_CERCACASA_CHATPARAMS_CLIENT,
    });
  }
  const url = `${proxy}chat`;
  return ({ fetch }) => ({
    type: FETCH_CERCACASA_CHATPARAMS,
    payload: {
      promise: fetchFn(fetch, url, method, getFetchBody(params))
        .then(response => response.json())
        .catch(err => console.error('Error get CERCACASA params', err)),
    },
  });
}

export function fetchCercacasaSaveParams(params) {
  if (canUseDOM) {
    return () => ({
      type: FETCH_CERCACASA_SAVEPARAMS_CLIENT,
    });
  }
  const url = `${proxy}savealert`;
  return ({ fetch }) => ({
    type: FETCH_CERCACASA_SAVEPARAMS,
    payload: {
      promise: fetchFn(fetch, url, method, getFetchBody(params))
        .then(response => response.json())
        .catch(err => console.error('Error get CERCACASA params', err)),
    },
  });
}

export function fetchCercacasaThankyouParams(params) {
  if (canUseDOM) {
    return () => ({
      type: FETCH_CERCACASA_THANKYOU_CLIENT,
    });
  }
  const url = `${proxy}thankyou`;
  return ({ fetch }) => ({
    type: FETCH_CERCACASA_THANKYOU,
    payload: {
      promise: fetchFn(fetch, url, method, getFetchBody(params))
        .then(response => response.json())
        .catch(err => console.error('Error get CERCACASA params', err)),
    },
  });
}

export function fetchLandingRoomAndBathrooms(params) {
  const url = `${proxy}available`;
  const verb = 'POST';
  return ({ fetch }) => ({
    type: FETCH_CERCACASA_ROOMSANDBATHROOMS,
    payload: {
      promise: fetchFn(fetch, url, verb, getFetchBody(params))
        .then(response => response.json())
        .catch(err => console.error('Error get CERCACASA params', err)),
    },
  });
}

export function fetchLandingBudget(params) {
  return ({ fetch }) => ({
    type: FETCH_CERCACASA_BUDGET,
    payload: {
      promise: fetchFn(fetch, proxy, method, getFetchBody(params))
        .then(response => response.json())
        .catch(err => console.error('Error get CERCACASA params', err)),
    },
  });
}

export function fetchLandingWhere(params) {
  return ({ fetch }) => ({
    type: FETCH_CERCACASA_WHERE,
    payload: {
      promise: fetchFn(fetch, proxy, method, getFetchBody(params))
        .then(response => response.json())
        .catch(err => console.error('Error get CERCACASA params', err)),
    },
  });
}

export function resetLandingWhere() {
  return {
    type: RESET_CERCACASA_WHERE,
    payload: { message: null },
  };
}

export function fetchLandingFilters(params) {
  return ({ fetch }) => ({
    type: FETCH_CERCACASA_FILTERS,
    payload: {
      promise: fetchFn(fetch, proxy, method, getFetchBody(params))
        .then(response => response.json())
        .catch(err => console.error('Error get CERCACASA params', err)),
    },
  });
}
