export default async (state, type, payload) => {
  let newState = state;

  switch (type) {
    case 'updateUser': {
      newState = {
        user: payload,
      };
      break;
    }
  }

  return newState;
};
