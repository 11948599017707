import React from 'react';

import { isValidString } from 'shared-utils/src/checkVarType';

import './style.scss';

export default ({
  action,
  label,
  className,
  leftIcon,
  rightIcon,
  hasBorder,
  hasShadow,
  children,
}) => {
  const isLink = isValidString(action);

  const Button = isLink ? 'a' : 'button';

  const props = {
    [isLink ? 'href' : 'onClick']: action,
    type: isLink ? null : 'button',
  };

  let paddings = 'pr--ms pl--ms';
  if (leftIcon) {
    paddings = 'pr--ms pl--s';
  }
  if (rightIcon) {
    paddings = 'pr--s pl--ms';
  }
  if (leftIcon && rightIcon) {
    paddings = 'pr--ms pl--ms';
  }

  return (
    <Button className={`csa_pill pt--xs pb--xs ${paddings} tp-s--s tp-w--m c-txt--f0 c-bg--w is-clickable${className ? ` ${className}` : ''}${hasShadow ? ' wShadow' : ''}${hasBorder ? ' wBorder' : ''}`} {...props}>
      {leftIcon || null}
      {label || children || null}
      {rightIcon || null}
    </Button>
  );
};
