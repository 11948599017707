import { canUseDOM } from '../../components';

export const FETCH_SUBSCRIPTION_CLIENT = 'FETCH_SUBSCRIPTION_CLIENT';
export const FETCH_SUBSCRIPTION = 'FETCH_SUBSCRIPTION';
export const FETCH_SUBSCRIPTION_START = 'FETCH_SUBSCRIPTION_START';
export const FETCH_SUBSCRIPTION_SUCCESS = 'FETCH_SUBSCRIPTION_SUCCESS';
export const FETCH_SUBSCRIPTION_ERROR = 'FETCH_SUBSCRIPTION_ERROR';

export const FETCH_DEACTIVATION_CLIENT = 'FETCH_DEACTIVATION_CLIENT';
export const FETCH_DEACTIVATION = 'FETCH_DEACTIVATION';
export const FETCH_DEACTIVATION_START = 'FETCH_DEACTIVATION_START';
export const FETCH_DEACTIVATION_SUCCESS = 'FETCH_DEACTIVATION_SUCCESS';
export const FETCH_DEACTIVATION_ERROR = 'FETCH_DEACTIVATION_ERROR';

export const EDIT_FREQUENCY_EMAIL = 'EDIT_FREQUENCY_EMAIL';
export const EDIT_FREQUENCY_EMAIL_START = 'EDIT_FREQUENCY_EMAIL_START';
export const EDIT_FREQUENCY_EMAIL_SUCCESS = 'EDIT_FREQUENCY_EMAIL_SUCCESS';
export const EDIT_FREQUENCY_EMAIL_ERROR = 'EDIT_FREQUENCY_EMAIL_ERROR';

const fetchFn = (fetch, url, verb, params) => fetch(url, {
  method: verb,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Connection: 'keep-alive',
  },
  body: JSON.stringify(params),
  credentials: 'same-origin',
});

export function fetchSubscription(params) {
  if (canUseDOM) {
    return () => ({
      type: FETCH_SUBSCRIPTION_CLIENT,
    });
  }
  const url = '/portal-srp/api/v1/account/subscription';
  const verb = 'POST';
  const body = {
    site: params.apireq.site, 
    domain: params.domain, 
    locale: params.locale, 
    apireq: params.apireq, 
    baseUri: params.uri,
  };
  return ({ fetch }) => ({
    type: FETCH_SUBSCRIPTION,
    payload: {
      promise: fetchFn(fetch, url, verb, body)
        .then(response => response.json())
        .catch(err => console.error('Error fetch subscription', err)),
    },
  });
}

export function fetchDeactivationMessage(params) {
  if (canUseDOM) {
    return () => ({
      type: FETCH_DEACTIVATION_CLIENT,
    });
  }
  const url = '/portal-srp/api/v1/account/deactivation-message';
  const verb = 'POST';
  const body = {
    site: params.apireq.site, 
    domain: params.domain, 
    locale: params.locale, 
    apireq: params.apireq, 
    baseUri: params.uri,
  };

  return ({ fetch }) => ({
    type: FETCH_DEACTIVATION,
    payload: {
      promise: fetchFn(fetch, url, verb, body)
        .then(response => response.json())
        .catch(err => console.error('Error fetch deactivation', err)),
    },
  });
}
