export const popularLocalities = [
  {
    id: 'IT-LOM-015146',
    name: 'Milano',
    hkey: '9f6485c2',
    level: 9,
    slug: 'milano',
    has_children: true,
    display: {
      en: 'Milano (IT)',
      fr: 'Milano (IT)',
      de: 'Milano (IT)',
      lu: 'Milano (IT)',
    },
    parents: [
      {
        id: 'IT',
        name: 'Italia',
        hkey: 'e0a6f613',
        level: 2,
        slug: 'italia',
      },
      {
        id: 'IT-LOM',
        name: 'Lombardia',
        hkey: '3709b35a',
        level: 4,
        slug: 'lombardia',
      },
      {
        id: 'IT-LOM-MI',
        name: 'Milano',
        hkey: 'f600a8b5',
        level: 6,
        slug: 'milano',
      },
    ],
    type: 'town',
    parent: {
      country: {
        id: 'IT',
        name: 'Italia',
        hkey: 'e0a6f613',
        level: 2,
        slug: 'italia',
      },
      region: {
        id: 'IT-LOM',
        name: 'Lombardia',
        hkey: '3709b35a',
        level: 4,
        slug: 'lombardia',
      },
      province: {
        id: 'IT-LOM-MI',
        name: 'Milano',
        hkey: 'f600a8b5',
        level: 6,
        slug: 'milano',
        short: 'MI',
      },
    },
    levels: {
      L2: 'Italia',
      L4: 'Lombardia',
      L6: 'Milano',
      L9: 'Milano',
    },
    slugs: {
      L2: 'italia',
      L4: 'lombardia',
      L6: 'milano',
      L9: 'milano',
    },
    geo: {
      center_point: {
        lat: 45.4642035,
        lon: 9.189982,
      },
    },
    lat: 45.4642035,
    lon: 9.189982,
    children_uri: '/lh/v2/town/milano?site=it_casa',
  },
  {
    id: 'IT-LAZ-058091',
    name: 'Roma',
    hkey: 'a0d22860',
    level: 9,
    slug: 'roma',
    has_children: 'true',
    display: {
      en: 'Roma (IT)',
      fr: 'Roma (IT)',
      de: 'Roma (IT)',
      lu: 'Roma (IT)',
    },
    parents: [
      {
        id: 'IT',
        name: 'Italia',
        hkey: 'e0a6f613',
        level: 2,
        slug: 'italia',
      },
      {
        id: 'IT-LAZ',
        name: 'Lazio',
        hkey: 'c300acc3',
        level: 4,
        slug: 'lazio',
      },
      {
        id: 'IT-LAZ-RM',
        name: 'Roma',
        hkey: 'ed427fcb',
        level: 6,
        slug: 'roma',
      },
    ],
    type: 'town',
    parent: {
      country: {
        id: 'IT',
        name: 'Italia',
        hkey: 'e0a6f613',
        level: 2,
        slug: 'italia',
      },
      region: {
        id: 'IT-LAZ',
        name: 'Lazio',
        hkey: 'c300acc3',
        level: 4,
        slug: 'lazio',
      },
      province: {
        id: 'IT-LAZ-RM',
        name: 'Roma',
        hkey: 'ed427fcb',
        level: 6,
        slug: 'roma',
        short: 'RM',
      },
    },
    levels: {
      L2: 'Italia',
      L4: 'Lazio',
      L6: 'Roma',
      L9: 'Roma',
    },
    slugs: {
      L2: 'italia',
      L4: 'lazio',
      L6: 'roma',
      L9: 'roma',
    },
    geo: {
      center_point: {
        lat: 41.9027835,
        lon: 12.4963655,
      },
    },
    lat: 41.9027835,
    lon: 12.4963655,
    children_uri: '/lh/v2/town/roma?site=it_casa',
  },
  {
    id: 'IT-LIG-010025',
    name: 'Genova',
    hkey: '70d91309',
    level: 9,
    slug: 'genova',
    has_children: 'true',
    display: {
      en: 'Genova (IT)',
      fr: 'Genova (IT)',
      de: 'Genova (IT)',
      lu: 'Genova (IT)',
    },
    parents: [
      {
        id: 'IT',
        name: 'Italia',
        hkey: 'e0a6f613',
        level: 2,
        slug: 'italia',
      },
      {
        id: 'IT-LIG',
        name: 'Liguria',
        hkey: '414e49e5',
        level: 4,
        slug: 'liguria',
      },
      {
        id: 'IT-LIG-GE',
        name: 'Genova',
        hkey: '518fbe72',
        level: 6,
        slug: 'genova',
      },
    ],
    type: 'town',
    parent: {
      country: {
        id: 'IT',
        name: 'Italia',
        hkey: 'e0a6f613',
        level: 2,
        slug: 'italia',
      },
      region: {
        id: 'IT-LIG',
        name: 'Liguria',
        hkey: '414e49e5',
        level: 4,
        slug: 'liguria',
      },
      province: {
        id: 'IT-LIG-GE',
        name: 'Genova',
        hkey: '518fbe72',
        level: 6,
        slug: 'genova',
        short: 'GE',
      },
    },
    levels: {
      L2: 'Italia',
      L4: 'Liguria',
      L6: 'Genova',
      L9: 'Genova',
    },
    slugs: {
      L2: 'italia',
      L4: 'liguria',
      L6: 'genova',
      L9: 'genova',
    },
    geo: {
      center_point: {
        lat: 44.4056499,
        lon: 8.946256,
      },
    },
    lat: 44.4056499,
    lon: 8.946256,
    children_uri: '/lh/v2/town/genova?site=it_casa',
  },
  {
    id: 'IT-PIE-001272',
    name: 'Torino',
    hkey: 'fd9f383e',
    level: 9,
    slug: 'torino',
    has_children: true,
    display: {
      en: 'Torino (IT)',
      fr: 'Torino (IT)',
      de: 'Torino (IT)',
      lu: 'Torino (IT)',
    },
    parents: [
      {
        id: 'IT',
        name: 'Italia',
        hkey: 'e0a6f613',
        level: 2,
        slug: 'italia',
      },
      {
        id: 'IT-PIE',
        name: 'Piemonte',
        hkey: 'ae0eceb2',
        level: 4,
        slug: 'piemonte',
      },
      {
        id: 'IT-PIE-TO',
        name: 'Torino',
        hkey: '35acc337',
        level: 6,
        slug: 'torino',
      },
    ],
    type: 'town',
    parent: {
      country: {
        id: 'IT',
        name: 'Italia',
        hkey: 'e0a6f613',
        level: 2,
        slug: 'italia',
      },
      region: {
        id: 'IT-PIE',
        name: 'Piemonte',
        hkey: 'ae0eceb2',
        level: 4,
        slug: 'piemonte',
      },
      province: {
        id: 'IT-PIE-TO',
        name: 'Torino',
        hkey: '35acc337',
        level: 6,
        slug: 'torino',
        short: 'TO',
      },
    },
    levels: {
      L2: 'Italia',
      L4: 'Piemonte',
      L6: 'Torino',
      L9: 'Torino',
    },
    slugs: {
      L2: 'italia',
      L4: 'piemonte',
      L6: 'torino',
      L9: 'torino',
    },
    geo: {
      center_point: {
        lat: 45.070312,
        lon: 7.6868565,
      },
    },
    lat: 45.070312,
    lon: 7.6868565,
    children_uri: '/lh/v2/town/torino?site=it_casa',
  },
  {
    id: 'IT-TOS-048017',
    name: 'Firenze',
    hkey: '8e5f4403',
    level: 9,
    slug: 'firenze',
    has_children: true,
    display: {
      en: 'Firenze (IT)',
      fr: 'Firenze (IT)',
      de: 'Firenze (IT)',
      lu: 'Firenze (IT)',
    },
    parents: [
      {
        id: 'IT',
        name: 'Italia',
        hkey: 'e0a6f613',
        level: 2,
        slug: 'italia',
      },
      {
        id: 'IT-TOS',
        name: 'Toscana',
        hkey: '01cc1e04',
        level: 4,
        slug: 'toscana',
      },
      {
        id: 'IT-TOS-FI',
        name: 'Firenze',
        hkey: '93abf9f1',
        level: 6,
        slug: 'firenze',
      },
    ],
    type: 'town',
    parent: {
      country: {
        id: 'IT',
        name: 'Italia',
        hkey: 'e0a6f613',
        level: 2,
        slug: 'italia',
      },
      region: {
        id: 'IT-TOS',
        name: 'Toscana',
        hkey: '01cc1e04',
        level: 4,
        slug: 'toscana',
      },
      province: {
        id: 'IT-TOS-FI',
        name: 'Firenze',
        hkey: '93abf9f1',
        level: 6,
        slug: 'firenze',
        short: 'FI',
      },
    },
    levels: {
      L2: 'Italia',
      L4: 'Toscana',
      L6: 'Firenze',
      L9: 'Firenze',
    },
    slugs: {
      L2: 'italia',
      L4: 'toscana',
      L6: 'firenze',
      L9: 'firenze',
    },
    geo: {
      center_point: {
        lat: 43.7695604,
        lon: 11.2558136,
      },
    },
    lat: 43.7695604,
    lon: 11.2558136,
    children_uri: '/lh/v2/town/firenze?site=it_casa',
  },
  {
    id: 'IT-EMI-037006',
    name: 'Bologna',
    hkey: '01f0eef0',
    level: 9,
    slug: 'bologna',
    has_children: true,
    display: {
      en: 'Bologna (IT)',
      fr: 'Bologna (IT)',
      de: 'Bologna (IT)',
      lu: 'Bologna (IT)',
    },
    parents: [
      {
        id: 'IT',
        name: 'Italia',
        hkey: 'e0a6f613',
        level: 2,
        slug: 'italia',
      },
      {
        id: 'IT-EMI',
        name: 'Emilia-Romagna',
        hkey: 'b4de99bc',
        level: 4,
        slug: 'emilia-romagna',
      },
      {
        id: 'IT-EMI-BO',
        name: 'Bologna',
        hkey: '924479fd',
        level: 6,
        slug: 'bologna',
      },
    ],
    type: 'town',
    parent: {
      country: {
        id: 'IT',
        name: 'Italia',
        hkey: 'e0a6f613',
        level: 2,
        slug: 'italia',
      },
      region: {
        id: 'IT-EMI',
        name: 'Emilia-Romagna',
        hkey: 'b4de99bc',
        level: 4,
        slug: 'emilia-romagna',
      },
      province: {
        id: 'IT-EMI-BO',
        name: 'Bologna',
        hkey: '924479fd',
        level: 6,
        slug: 'bologna',
        short: 'BO',
      },
    },
    levels: {
      L2: 'Italia',
      L4: 'Emilia-Romagna',
      L6: 'Bologna',
      L9: 'Bologna',
    },
    slugs: {
      L2: 'italia',
      L4: 'emilia-romagna',
      L6: 'bologna',
      L9: 'bologna',
    },
    geo: {
      center_point: {
        lat: 44.494887,
        lon: 11.3426162,
      },
    },
    lat: 44.494887,
    lon: 11.3426162,
    children_uri: '/lh/v2/town/bologna?site=it_casa',
  },
  {
    id: 'IT-CAM-063049',
    name: 'Napoli',
    hkey: 'bc4bb37a',
    level: 9,
    slug: 'napoli',
    has_children: true,
    display: {
      en: 'Napoli (IT)',
      fr: 'Napoli (IT)',
      de: 'Napoli (IT)',
      lu: 'Napoli (IT)',
    },
    parents: [
      {
        id: 'IT',
        name: 'Italia',
        hkey: 'e0a6f613',
        level: 2,
        slug: 'italia',
      },
      {
        id: 'IT-CAM',
        name: 'Campania',
        hkey: 'ad9fcc34',
        level: 4,
        slug: 'campania',
      },
      {
        id: 'IT-CAM-NA',
        name: 'Napoli',
        hkey: '7e02e070',
        level: 6,
        slug: 'napoli',
      },
    ],
    type: 'town',
    parent: {
      country: {
        id: 'IT',
        name: 'Italia',
        hkey: 'e0a6f613',
        level: 2,
        slug: 'italia',
      },
      region: {
        id: 'IT-CAM',
        name: 'Campania',
        hkey: 'ad9fcc34',
        level: 4,
        slug: 'campania',
      },
      province: {
        id: 'IT-CAM-NA',
        name: 'Napoli',
        hkey: '7e02e070',
        level: 6,
        slug: 'napoli',
        short: 'NA',
      },
    },
    levels: {
      L2: 'Italia',
      L4: 'Campania',
      L6: 'Napoli',
      L9: 'Napoli',
    },
    slugs: {
      L2: 'italia',
      L4: 'campania',
      L6: 'napoli',
      L9: 'napoli',
    },
    geo: {
      center_point: {
        lat: 40.8517746,
        lon: 14.2681244,
      },
    },
    lat: 40.8517746,
    lon: 14.2681244,
    children_uri: '/lh/v2/town/napoli?site=it_casa',
  },  
];

export const provinceShortToLongListHub = {
  VT: 'viterbo',
  LU: 'lucca',
  VR: 'verona',
  LT: 'latina',
  VS: 'medio-campidano',
  LO: 'lodi',
  VI: 'vicenza',
  VE: 'venezia',
  VC: 'vercelli',
  ME: 'messina',
  VB: 'verbano-cusio-ossola',
  VA: 'varese',
  MI: 'milano',
  MC: 'macerata',
  MB: 'monza-e-brianza',
  FE: 'ferrara',
  FC: 'forli-cesena',
  MN: 'mantova',
  MO: 'modena',
  MT: 'matera',
  MS: 'massa-carrara',
  EN: 'enna',
  UD: 'udine',
  NA: 'napoli',
  TV: 'treviso',
  GE: 'genova',
  NU: 'nuoro',
  NO: 'novara',
  FR: 'frosinone',
  OG: 'ogliastra',
  FG: 'foggia',
  FI: 'firenze',
  FM: 'fermo',
  OT: 'olbia-tempio',
  OR: 'oristano',
  PN: 'pordenone',
  PO: 'prato',
  GR: 'grosseto',
  PI: 'pisa',
  GO: 'gorizia',
  PD: 'padova',
  PE: 'pescara',
  VV: 'vibo-valentia',
  PG: 'perugia',
  PA: 'palermo',
  PC: 'piacenza',
  RI: 'rieti',
  RG: 'ragusa',
  RE: 'reggio-emilia',
  RC: 'reggio-di-calabria',
  RO: 'rovigo',
  RN: 'rimini',
  RM: 'roma',
  RA: 'ravenna',
  AT: 'asti',
  AR: 'arezzo',
  AQ: 'l-aquila',
  IM: 'imperia',
  AV: 'avellino',
  IS: 'isernia',
  BA: 'bari',
  PT: 'pistoia',
  PU: 'pesaro-urbino',
  PV: 'pavia',
  AG: 'agrigento',
  PR: 'parma',
  AL: 'alessandria',
  AO: 'aosta',
  AP: 'ascoli-piceno',
  PZ: 'potenza',
  AN: 'ancona',
  TE: 'teramo',
  BS: 'brescia',
  BR: 'brindisi',
  TO: 'torino',
  TN: 'trento',
  CB: 'campobasso',
  CA: 'cagliari',
  TS: 'trieste',
  TR: 'terni',
  BZ: 'bolzano',
  TP: 'trapani',
  SV: 'savona',
  BG: 'bergamo',
  SS: 'sassari',
  BI: 'biella',
  BN: 'benevento',
  BO: 'bologna',
  BL: 'belluno',
  TA: 'taranto',
  CZ: 'catanzaro',
  KR: 'crotone',
  CT: 'catania',
  CS: 'cosenza',
  SI: 'siena',
  SP: 'la-spezia',
  SO: 'sondrio',
  SR: 'siracusa',
  CI: 'carbonia-iglesias',
  LC: 'lecco',
  CH: 'chieti',
  CE: 'caserta',
  LI: 'livorno',
  CR: 'cremona',
  CO: 'como',
  LE: 'lecce',
  CN: 'cuneo',
  SA: 'salerno',
  CL: 'caltanissetta',
  BT: 'barletta-andria-trani',
  SU: 'sud-sardegna',
  60: 'repubblica-di-san-marino',
};
