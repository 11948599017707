export const areLocationsCommunesOfTheSameProvince = (locations = []) => {
  let notACommune = false;
  const parents = [];
  locations.forEach(
    (loc) => {
      if (loc.level === 9) {
        if (parents.indexOf(loc.parent.province.name) === -1) {
          parents.push(loc.parent.province.name);
        }
      } else {
        notACommune = true;
      }
    },
  );
  return parents.length === 1 && !notACommune;
};

export const areLocationsZonesOfTheSameCity = (locations = []) => {
  let notAZone = false;
  const parents = [];
  locations.forEach(
    (loc) => {
      if (loc.level === 10 || loc.level === 11 || loc.level === 118 || loc.level === 120) {
        if (parents.indexOf(loc.parent.town.name) === -1) {
          parents.push(loc.parent.town.name);
        }
      } else {
        notAZone = true;
      }
    },
  );
  return parents.length === 1 && !notAZone;
};
