import * as actions from './actions';

const initialState = {}; // new InitialState;

export default function deactivationAlertsReducer(state = initialState, action) {
  switch (action.type) {

    case actions.FETCH_DEACTIVATION_ALERTS_START: {
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_DEACTIVATION_ALERTS_SUCCESS: {
      return Object.assign({}, action.payload, state, {
        isFetching: false,
        errorMessage: '',
      });
    }

    case actions.FETCH_DEACTIVATION_ALERTS_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload.message,
      });
    }
    
    default: {
      return state;
    }
  }
}
