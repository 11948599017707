import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Overlayer = ({ isOpen, columnsAreCollapsed, children }) => (
  <div className={`csa_mapsearch__bod__over${isOpen ? ' open' : ''}${columnsAreCollapsed ? ' columnsAreCollapsed' : ''}`}>
    <div className="csa_mapsearch__bod__over__layer">
      {children}
    </div>
  </div>
);

export default Overlayer;

Overlayer.propTypes = {
  isOpen: PropTypes.bool,
  columnsAreCollapsed: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]),
};

Overlayer.defaultProps = {
  isOpen: true,
  columnsAreCollapsed: false,
  children: null,
};
