import React from 'react';
import PropTypes from 'prop-types';

import GridItem from 'shared-components/components/GridItem';

import { useCtxGlobal } from '@client/app/Container';
import { queryBuilder } from '@modals/factories';

import decorateMapLocality from '../../../helpers/decorateMapLocality';

const QbBtn = ({
  searchAddress,
  availableResults,
  availablePoi,
  shapeFilter,
  updateFiltersToAddToMapQuery,
  disabled,
  openQbFiltersPanel,
}) => {
  const {
    openModal,
    isMobile,
    isSmallMobile,
    pageType,
    filtersProps,
  } = useCtxGlobal();

  const openSearch = () => {
    if (openQbFiltersPanel) {
      openQbFiltersPanel();
    } else {
      const mapLocality = decorateMapLocality({ searchAddress });
      const adjustedFProps = {
        ...filtersProps,
        availableResults: availableResults.value,
        availablePoi,
        locations: mapLocality,
        mainLocation: mapLocality,
        filters: {
          ...filtersProps.filters,
          ...shapeFilter,
        },
        updateFiltersToAddToMapQuery,
        searchAddress,
      };

      openModal(queryBuilder({
        isMobile,
        isSmallMobile,
        pageType,
        filtersProps: adjustedFProps,
        panelChooseBy: {
          isMobile,
          actionClicked: 'mapShape',
          pageFrom: pageType,
        },
      }));
    }
  };

  const { activeFiltersCount } = filtersProps.getActiveFilters(filtersProps.filters);
  const text = activeFiltersCount ? <>Filtri ({activeFiltersCount})</> : 'Filtri';

  return (
    <GridItem behavior="fixed" className="mr--s">
      <div className={`csa_mapsearch__bod__over__layer__bot__btn pr--m pl--m tp-s--l tp-w--m b--s tp-a--c c-txt--f0 is-clickable${disabled ? ' disabled' : ''}`} onClick={openSearch}>
        {text}
      </div>
    </GridItem>
  );
};

export default QbBtn;

QbBtn.propTypes = {
  searchAddress: PropTypes.instanceOf(Object),
  availableResults: PropTypes.instanceOf(Object),
  shapeFilter: PropTypes.instanceOf(Object),
  updateFiltersToAddToMapQuery: PropTypes.func,
  disabled: PropTypes.bool,
  openQbFiltersPanel: PropTypes.func,
};

QbBtn.defaultProps = {
  searchAddress: null,
  availableResults: null,
  shapeFilter: null,
  updateFiltersToAddToMapQuery: () => {},
  disabled: false,
  openQbFiltersPanel: null,
};
