// @flow
import promiseMiddleware from 'redux-promise-middleware';
import createFetch from './createFetch';

// Like redux-thunk, but with just one argument for dependencies.
const injectMiddleware = deps => ({ dispatch, getState }: { dispatch: Function, getState: Function }) => (next: Function) => (action: Function) => next(
  typeof action === 'function'
    ? action({ ...deps, dispatch, getState })
    : action,
);

const configureMiddleware = (
  initialState: Object,
  platformDeps: Object,
  platformMiddleware: any,
) => {
  // Remember to set SERVER_URL for deploy.
  const serverUrl = process.env.SERVER_URL ||
        // Browser is ok with relative url. Server need absolute.
        (process.env.IS_BROWSER ? '' : `http://localhost:${process.env.PORT || 3000}`);

  const middleware = [
    injectMiddleware({
      ...platformMiddleware,
      fetch: createFetch(serverUrl),
      now: () => Date.now(),
    }),
    promiseMiddleware({
      promiseTypeSuffixes: ['START', 'SUCCESS', 'ERROR'],
    }),
  ];

  return middleware;
};

export default configureMiddleware;
