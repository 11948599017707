export const getUserAgent = () => {
  const userAgent = navigator.userAgent
      || navigator.vendor
      || window.opera
      || null;

  if (!userAgent) {
    throw new Error('Failed to look for user agent information.');
  }

  return userAgent;
};

// check if localStorage is available
export function localStorageAvailable() {
  const mod = 'test';
  try {
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    return true;
  } catch (e) {
    return false;
  }
}

export const getRect = el => el.getBoundingClientRect();

// FLIP tecnique
// https://aerotwist.com/blog/flip-your-animations/#the-general-approach
export const makeFlip = (current, fn, { animClass = 'is-anim' } = {}) => {
  const { style, classList } = current;
  const first = getRect(current);
  classList.remove(animClass);
  fn({ el: current, classList, rect: first });
  const last = getRect(current);
  const deltaX = first.left - last.left;
  const deltaY = first.top - last.top;
  style.transform = `translate(${deltaX}px, ${deltaY}px)`;
  requestAnimationFrame(() => {
    classList.add(animClass);
    style.transform = '';
  });
};

function getIOSVersion(ua) {  
  try {
    const completeVersion = ua.match(/OS [\d_]+/i)[0].substr(3).split('_').map(n => parseInt(n, 10));
    const [version] = completeVersion;
    return version < 13;    
  } catch (error) {
    console.log('Failed to assert IOS version from user agent information.');
  }
  return false;
}

// User device specs detection
export function userDevice() {
  const viewport = {
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  };
  const touchscreen = ('ontouchstart' in window || navigator.msMaxTouchPoints > 0) && viewport.width < 951;
  const userAgent = getUserAgent();

  let isIpadOs = false;
  try {
    if (userAgent.match(/Mac/) && document.createEvent('TouchEvent')) {
      isIpadOs = true;
    }
  } catch {
    isIpadOs = false;
  }

  function isPortrait(angle) {
    if (angle) {
      return angle === 0;
    }
    return window.innerHeight > window.innerWidth;
  }

  const mobileCheck = {
    ios: userAgent.match(/iPhone|iPad|iPod/i) || isIpadOs,
    android: userAgent.match(/Android/i),
    mobile: (typeof window.orientation !== 'undefined') || (userAgent.indexOf('IEMobile') !== -1),
    tablet: userAgent.match(/ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|KFAPWI/i) || isIpadOs,
    orientation: typeof screen?.orientation?.angle !== 'undefined' ? screen?.orientation?.angle : null,
  };

  let device = '';
  let mobileOs = '';
  let orientation = '';
  const iosLth12 = mobileCheck.ios
    ? getIOSVersion(userAgent)
    : false;

  if (typeof mobileCheck.orientation !== 'undefined' && mobileCheck.orientation !== null) {
    if (isPortrait(mobileCheck.orientation)) {
      orientation = 'portrait';
    } else {
      orientation = 'landscape';
    }
  }
  let isShortDesktop = false;
  if (
    (
      (
        mobileCheck.ios
        || mobileCheck.android
      )
      && !mobileCheck.tablet
      && orientation !== ''
    ) || viewport.width < 768
  ) {
    device = 'smartphone';
    if (mobileCheck.orientation === 90) {
      device = 'smartphone landscape';
    }
    if (mobileCheck.ios) {
      mobileOs = 'ios';
    } else if (mobileCheck.android) {
      mobileOs = 'android';
    }
  } else if (
    (
      mobileCheck.tablet
      && orientation !== ''
    ) || isIpadOs // || viewport.width < 1024
  ) {
    device = 'tablet';
    if (mobileCheck.ios) {
      mobileOs = 'ios';
    } else if (mobileCheck.android) {
      mobileOs = 'android';
    }
  } else {
    device = 'desktop';
    if (viewport.height < 651) {
      isShortDesktop = true;
    }
  }
  const isLocalStorageAvailable = localStorageAvailable();

  const isSafari = userAgent.indexOf('Safari/') > -1 && userAgent.indexOf('Chrome/') < 0;

  return {
    viewport,
    device,
    touchscreen,
    isLocalStorageAvailable,
    isShortDesktop,
    mobileOs,
    orientation,
    iosLth12,
    isIpadOs,
    isSafari,
  };
}

export const isIos = () => userDevice().mobileOs === 'ios';


/**
 * 
 * BLOCCO DELLO SCROLL ALLO SWIPE SULLA GALLERY
 * DEGLI ANNUNCI
 * 
 */

const preventDefault = (e) => {
  e = e || window.event;
  if (e.preventDefault) {
    e.preventDefault();
  }
  e.returnValue = false;
};

export const disableScroll = () => {
  if (window.addEventListener) {
    window.addEventListener('scroll', preventDefault, false);
    window.addEventListener('DOMMouseScroll', preventDefault, false);
  }
  window.ontouchmove = preventDefault;
};


export const enableScroll = () => {
  if (window.removeEventListener) {
    window.removeEventListener('scroll', preventDefault, false);
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
  }
  window.onmousewheel = null;
  document.onmousewheel = null;
  window.onwheel = null;
  window.ontouchmove = null;
};

// recursively checks if a DOM element is the child of another
export const isChildOf = (child, parent) => {
  if (child.parentNode === parent) {
    return true;
  } if (child.parentNode === null) {
    return false;
  }
  return isChildOf(child.parentNode, parent);
};

export const loadScript = (path, clb) => new Promise((resolve, reject) => {
  const scr = document.createElement('script');
  scr.src = path;
  scr.onload = () => {
    resolve();
    clb && clb();
  };
  scr.onerror = reject;
  document.head.appendChild(scr);
});

export const loadLink = (path, { rel = 'stylesheet' } = {}) => {
  const link = document.createElement('link');
  link.href = path;
  link.rel = rel;
  document.head.appendChild(link);
};

export const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 3);
  }
};
