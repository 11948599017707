/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
  createContext,
  useContext,
} from 'react';

import PropTypes from 'prop-types';

import { GMAPS_API_KEY } from 'shared-constants/src/gmaps';
import { addGoogleMapsLib } from 'shared-utils/src/googleMapsLib';

export const MapApiContext = createContext();
export const useMapApiContext = () => useContext(MapApiContext);

const MapApiInitializer = ({
  libraries,
  children,
}) => {
  const [ready, setReady] = useState(false);
  const [loadedLibraries, addLoadedLibrary] = useReducer(
    (
      libs,
      action,
    ) => ({ ...libs, [action.name]: action.value }), {},
  );

  const importLibrary = useCallback(async (name) => {
    const res = await window.google.maps.importLibrary(name);
    addLoadedLibrary({ name, value: res });
  }, [loadedLibraries]);

  useEffect(() => {
    (async () => {
      const isLibLoaded = await addGoogleMapsLib({ apiKey: GMAPS_API_KEY, version: 'beta' });
      if (isLibLoaded) {
        for (const name of ['core', 'maps', ...libraries]) {
          await importLibrary(name);
        }
        setReady(true);
      }
    })();
  }, []);

  const mapApiContextValue = useMemo(() => ({ ready, libraries: loadedLibraries }), [ready, loadedLibraries]);

  return (
    <MapApiContext.Provider value={mapApiContextValue}>
      {children}      
    </MapApiContext.Provider>
  );
};

export default MapApiInitializer;

MapApiInitializer.propTypes = {
  libraries: PropTypes.instanceOf(Array),
};

MapApiInitializer.defaultProps = {
  libraries: [],
};
