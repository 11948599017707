import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useCtxGlobal } from 'client/app/Container';

import formatPublisherAddress from 'shared-utils/src/leads/publisherAddress';

import Grid from 'shared-components/components/Grid';

import Buttons from './Buttons';
import Agents from '../Agents';

import './critical.scss';

const Agency = ({
  device,
  data,
  user,
  isLocalStorageAvailable,
  isFromAgentPro,
  hasPlaceholder,
  logoSrc,
}) => {
  const {
    publisherName,
    description,
    hasCrossSubscription,
    publisherAddress: { street, town, province, state, postcode },
    publisherUrl,
    hasAgents,
    agentList: { list = [], total = 0 } = {},
  } = data;

  const descEl = useRef(null);
  const [revealButton, setRevealButton] = useState(false);
  const [descReveal, setDescReveal] = useState(false);

  const expandDesc = () => {
    setRevealButton(false);
    setDescReveal(true);
  };

  useEffect(() => {
    if (descEl.current) {
      if (descEl.current.clientHeight < descEl.current.scrollHeight) {
        setRevealButton(true);
      }
    }
  }, []);

  return (
    <div className="pbInfos__fixed">
      {device !== 'desktop' ? (
        <Grid className="pbInfos__cont c-bg--w is-rel">
          {!hasPlaceholder && (
            <img
              className={`pbInfos__logo pbInfos__logo--mb21${hasCrossSubscription
                ? ' pbInfos__logo--pm'
                : ' pbInfos__logo--csa'
                }`}
              src={logoSrc}
              alt={publisherName}
            />
          )}
          <h1 className="pbInfos__name c-txt--secondary">{publisherName}</h1>
          <div className="pbInfos__addr tp-w--s c-txt--f5">
            {formatPublisherAddress({
              street,
              town,
              province,
              state,
              postcode,
            })}
          </div>
          {hasAgents && !isFromAgentPro && (
            <Agents
              list={list}
              total={total}
              link={`${publisherUrl}agenti/`}
              publisherName={publisherName}
              className="pbInfos__agents"
            />
          )}
          <Buttons
            publisher={data}
            device={device}
            user={user}
            isLocalStorageAvailable={isLocalStorageAvailable}
            logoSrc={logoSrc}
          />
          {description && (
            <p
              ref={descEl}
              className={`pbInfos__desc${descReveal ? ' pbInfos__desc--open' : ''} is-rel tp-s--l tp-w--s c-txt--f5`}
            >
              {description}
              {revealButton && (
                <button
                  type="button"
                  onClick={expandDesc}
                  className="pbInfos__desc__expand tp-s--s c-txt--secondary"
                >
                  <span className="tp--u">espandi</span>
                </button>
              )}
            </p>
          )}
        </Grid>
      ) : (
        <Grid className="pbInfos__cont c-bg--w is-rel">
          <Grid justify="space-between" className="pbInfos__logo-and-name">
            {/* img e title indirizzo */}
            <Grid>
              {!hasPlaceholder && (
                <img
                  className={`pbInfos__logo mr--m ${hasCrossSubscription
                    ? ' pbInfos__logo--pm'
                    : ' pbInfos__logo--csa'
                  }`}
                  src={logoSrc}
                  alt={publisherName}
                />
              )}
              <Grid direction="column">
                <h1 className="pbInfos__name c-txt--secondary">
                  {publisherName}
                </h1>
                <div className="pbInfos__addr tp-w--s c-txt--f5">
                  {formatPublisherAddress({
                    street,
                    town,
                    province,
                    state,
                    postcode,
                  })}
                </div>
              </Grid>
            </Grid>
            {/* link button phone invia email */}

            <div>
              <Buttons
                publisher={data}
                device={device}
                user={user}
                isLocalStorageAvailable={isLocalStorageAvailable}
                logoSrc={logoSrc}
              />
            </div>
          </Grid>
          <Grid
            wrap="nowrap"
            justify="space-between"
            className="pbInfos__descr-agents is-rel"
          >
            {/* description */}

            {description && (
              <p
                ref={descEl}
                className={`pbInfos__desc${descReveal ? ' pbInfos__desc--open' : ''
                  } is-rel tp-s--l tp-w--s c-txt--f5`}
              >
                {description}
              </p>
            )}
            {revealButton && (
              <button
                type="button"
                onClick={expandDesc}
                className="pbInfos__desc__expand tp-s--s c-txt--secondary"
              >
                <span className="tp--u">espandi</span>
              </button>
            )}

            {/* agents */}
            <div>
              {hasAgents && !isFromAgentPro && (
                <Agents
                  list={list}
                  total={total}
                  link={`${publisherUrl}agenti/`}
                  publisherName={publisherName}
                  className="pbInfos__agents"
                />
              )}
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Agency;

Agency.propTypes = {
  device: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  isLocalStorageAvailable: PropTypes.bool,
  isFromAgentPro: PropTypes.bool,
  hasPlaceholder: PropTypes.bool,
  logoSrc: PropTypes.string,
};

Agency.defaultProps = {
  device: '',
  data: {},
  user: {},
  isLocalStorageAvailable: false,
  isFromAgentPro: false,
  hasPlaceholder: false,
  logoSrc: '',
};
