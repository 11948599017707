export default (checkedItems, checked, filterTerm) => {
  const chkMap = {};
  const chk = checkedItems.map(({
    label,
    hkey,
    level,
    pHkey,
    children,
  }) => {
    chkMap[hkey] = 1;
    return {
      label,
      hkey,
      level,
      pHkey,
      children,
    };
  });

  // Remove children if its parent is selected
  let noChild = chk.filter(it => !(it.pHkey && chkMap[it.pHkey]));

  if (filterTerm) {
    const uniq = {};
    noChild = noChild.concat(checked);
    // eslint-disable-next-line no-return-assign
    noChild = noChild.filter(({ hkey }) => !uniq[hkey] && (uniq[hkey] = true));
  }

  return noChild;
};
