// @flow
import { applyMiddleware, compose, createStore } from 'redux';
import configureMiddleware from './configureMiddleware';
import configureReducer from './configureReducer';

const configureStore = (options: Object) => {
  const {
    initialState,
    platformDeps = {},
    platformMiddleware = [],
    platformReducers = {},
    platformStoreEnhancers = [], // eslint-disable-line
  } = options;

  const reducer = configureReducer(platformReducers, initialState);

  const middleware = configureMiddleware(
    initialState,
    platformDeps,
    platformMiddleware,
  );

  const store = createStore(
    reducer,
    initialState,
    compose(
      applyMiddleware(...middleware),
      // ...platformStoreEnhancers,
    ),
  );

  // Enable hot reloading for reducers.
  if (module.hot && typeof module.hot.accept === 'function') {
    // Webpack for some reason needs accept with the explicit path.
    module.hot.accept('./configureReducer', () => {
      const configReducer = require('./configureReducer').default;
      // $FlowIssue: check it
      store.replaceReducer(configReducer(platformReducers, initialState));
    });
  }

  return store;
};

export default configureStore;
