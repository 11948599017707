// 'isChildOf' recursively checks if a DOM element is the child of another
export function isChildOf(child, parent) {
  if (child.parentNode === parent) {
    return true;
  } else if (child.parentNode === null) {
    return false;
  }
  return isChildOf(child.parentNode, parent);
}

// prevent scroll on touch devices
function customPreventDefault(e) {
  const evt = e || window.event;
  if (evt.preventDefault) {
    evt.preventDefault();
  }
  evt.returnValue = false;
}

export function disableScroll() {
  window.ontouchmove = customPreventDefault; // mobile
}

export function enableScroll() {
  window.ontouchmove = null;
}
