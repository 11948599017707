import * as actions from './actions';

const initialState = {}; // new InitialState;

export default function accountReducer(state = initialState, action) {
  switch (action.type) {

    case actions.FETCH_ACCOUNT_START: {
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_ACCOUNT_SUCCESS: {
      return Object.assign({}, action.payload, state, {
        isFetching: false,
        errorMessage: '',
      });
    }

    case actions.FETCH_ACCOUNT_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload.message,
      });
    }

    case actions.CONFIRM_UNREGISTERED_EMAIL_START: {
      return Object.assign({}, state, {
        isFetchingResults: true,
        errorMessage: '',
      });
    }

    case actions.CONFIRM_UNREGISTERED_EMAIL_SUCCESS: {
      return Object.assign({}, state, action.payload, {
        isFetchingResults: false,
        errorMessage: '',
      });
    }

    case actions.CONFIRM_UNREGISTERED_EMAIL_ERROR: {
      return Object.assign({}, state, {
        isFetchingResults: false,
        errorMessage: action.payload.message,
      });
    }
    
    default: {
      return state;
    }
  }
}
