import MAP_LOCALITY from 'shared-constants/src/mapGenericLocality';

export default ({ searchAddress = null }) => {
  const generic = JSON.parse(JSON.stringify(MAP_LOCALITY));
  if (!searchAddress) {
    return [generic];
  }    
  generic.name = `${searchAddress.name}, ${searchAddress.parent}`;
  return [generic];
};
