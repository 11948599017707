/* eslint-disable no-unused-vars */
import React from 'react';

import makeClassName from 'shared-utils/src/makeClassName';
import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';
import ArrowIcon from 'shared-vectors/icons/GalleryArrow';
import { Icon } from 'shared-vectors';

import { useCtxGlobal } from '@client/app/Container';

import './Paginator.scss';

function Paginator() {
  const { model } = useCtxGlobal();

  let paginator;
  let isPublisherHp = false;

  if (model.agentProData) {
    const { paginator: modelPaginator } = model.agentProData;
    paginator = modelPaginator;
  } else {
    const { paginator: modelPaginator, isPublisherHP: modelIsPublisherHp } = model;
    paginator = modelPaginator;
    isPublisherHp = modelIsPublisherHp;
  }

  if (isPublisherHp || paginator.totalPages <= 1) {    
    return null;
  }

  const {
    prevLink,
    nextLink,
  } = paginator;
  const buttons = paginator.btns;
  const curr = paginator.currentPage;
  const total = paginator.totalPages;
  const hrefPrev = prevLink.href;
  const hrefNext = nextLink.href;
  const clsMain = 'paginator';
  const clsPagItem = `${clsMain}__page tp-a--c b-r--100 is-block c-bg--w tp-w--m`;
  const clsPagNav = `${clsPagItem} ${clsMain}__nav`;
  const clsPagLabel = `${clsMain}__label`;
  const clsMainStr = makeClassName([
    clsMain,
    !hrefPrev && `${clsMain}--frst`,
    !hrefNext && `${clsMain}--last`,
    hrefNext && hrefPrev && `${clsMain}--mdl`,
    buttons.some(b => b.hasDots) && `${clsMain}--dots`,
    buttons.some(b => b.text === total) && `${clsMain}--end`,
  ]);
  const labelPrev = 'Precedente';
  const labelNext = 'Successiva';

  const artificialClick = (e, href) => {
    e.preventDefault();
    if (!href) {
      return;
    }
    document.location = href;
  };

  return (
    <div className={clsMainStr}>
      <Grid className="paginator__grid is-full-w" align="center" noWrap>
        {
          hrefPrev &&
          <GridItem behavior="fixed">
            <a className={`${clsPagNav} prev`} href={hrefPrev} aria-label={labelPrev}>
              <Icon name={ArrowIcon} size="m" />
              <span className={`${clsPagLabel} ml--s`}>{labelPrev}</span>
            </a>
          </GridItem>
        }
        {
          buttons.map((button) => {
            const Tag = button.tag || 'a';
            const cls = makeClassName([
              clsPagItem,
              'tp-s--s',
              button.isCurrent && 'paginator__page--c',
              button.hasDots && 'paginator__page--hd',
            ]);
            const dlt = button.text - curr;
            const clsWrap = `paginator__page-w pdlt_${dlt || '0'}`;
            return (
              <React.Fragment key={`pag_${button.text}`}>
                <GridItem behavior="fixed" className={clsWrap}>
                  <Tag
                    className={cls}
                    href={button.href ? button.href : null}
                    onClick={(e) => { artificialClick(e, button.href); }}
                  >
                    {button.text}
                  </Tag>
                </GridItem>
                {
                  !!button.hasDots &&
                  <GridItem behavior="fixed" className="tp-w--m tp-s--m">...</GridItem>
                }
              </React.Fragment>
            );
          })
        }
        {
          hrefNext &&
          <GridItem behavior="fixed">
            <a className={`${clsPagNav} next`} href={hrefNext} aria-label={labelNext}>
              <span className={`${clsPagLabel} mr--s`}>{labelNext}</span>
              <Icon name={ArrowIcon} size="m" className="is-rot-180" />
            </a>
          </GridItem>
        }
      </Grid>
    </div>
  );
}

export default Paginator;
