export default (inputField) => {
  const timing = 10;
  const fakeInput = document.createElement('input');
  fakeInput.setAttribute('type', 'text');
  fakeInput.style.position = 'absolute';
  fakeInput.style.opacity = 0;
  fakeInput.style.height = 0;
  fakeInput.style.fontSize = '16px';
  fakeInput.style.zIndex = 12;
  const tgt = document.body;
  tgt.prepend(fakeInput);
  fakeInput.focus();  
  setTimeout(() => {
    inputField.focus();
    fakeInput.remove();
  }, timing);
};
