import * as actions from './actions';

const initialState = {
  
}; // new InitialState;

export default function cercacasaReducer(state = initialState, action) {
  switch (action.type) {

    case actions.FETCH_CERCACASA_PARAMS_START: {
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_CERCACASA_PARAMS_SUCCESS: {
      return {
        ...state,
        total: {
          initial: action.payload.total,
        },
        createAlertUri: {
          initial: action.payload.createAlertUri,
        },
        locations: action.payload.locations,
        filters: action.payload.filters,
        where: action.payload.where,
        isFetching: false,
        errorMessage: '',
      };
    }

    case actions.FETCH_CERCACASA_PARAMS_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_CERCACASA_CHATPARAMS_START: {
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_CERCACASA_CHATPARAMS_SUCCESS: {
      return {
        ...state,
        total: {
          initial: action.payload.total,
        },
        createAlertUri: {
          initial: action.payload.createAlertUri,
        },
        locations: action.payload.locations,
        filters: action.payload.filters,
        where: action.payload.where,
        redirect: action.payload.redirect || false,
        isFetching: false,
        errorMessage: '',
      };
    }

    case actions.FETCH_CERCACASA_CHATPARAMS_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_CERCACASA_SAVEPARAMS_START: {
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_CERCACASA_SAVEPARAMS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        errorMessage: '',
      };
    }

    case actions.FETCH_CERCACASA_SAVEPARAMS_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_CERCACASA_THANKYOU_START: {
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_CERCACASA_THANKYOU_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        errorMessage: '',
      };
    }

    case actions.FETCH_CERCACASA_THANKYOU_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_CERCACASA_ROOMSANDBATHROOMS_START: {
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_CERCACASA_ROOMSANDBATHROOMS_SUCCESS: {
      return {
        ...state,
        total: {
          ...state.total,
          roomsAndBathrooms: action.payload.total,          
        },
        createAlertUri: {
          ...state.createAlertUri,
          roomsAndBathrooms: action.payload.createAlertUri,
        },        
        isFetching: false,
        errorMessage: '',
      };
    }

    case actions.FETCH_CERCACASA_ROOMSANDBATHROOMS_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_CERCACASA_BUDGET_START: {
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_CERCACASA_BUDGET_SUCCESS: {
      return {
        ...state,
        total: {
          ...state.total,
          budget: action.payload.total,
        },
        createAlertUri: {
          ...state.createAlertUri,
          budget: action.payload.createAlertUri,
        },
        isFetching: false,
        errorMessage: '',
      };
    }

    case actions.FETCH_CERCACASA_BUDGET_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_CERCACASA_WHERE_START: {
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_CERCACASA_WHERE_SUCCESS: {
      return {
        ...state,
        total: {
          ...state.total,
          where: action.payload.total,
        },
        createAlertUri: {
          ...state.createAlertUri,
          where: action.payload.createAlertUri,
        },
        isFetching: false,
        errorMessage: '',
      };
    }

    case actions.FETCH_CERCACASA_WHERE_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload.message,
      });
    }

    case actions.RESET_CERCACASA_WHERE: {
      return {
        ...state,
        total: {
          ...state.total,
          where: null,
        },
        isFetching: false,
        errorMessage: '',
      };
    }

    case actions.FETCH_CERCACASA_FILTERS_START: {
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_CERCACASA_FILTERS_SUCCESS: {
      return {
        ...state,
        total: {
          ...state.total,
          filters: action.payload.total,
        },
        createAlertUri: {
          ...state.createAlertUri,
          filters: action.payload.createAlertUri,
        },
        isFetching: false,
        errorMessage: '',
      };
    }

    case actions.FETCH_CERCACASA_FILTERS_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload.message,
      });
    }

    default: {
      return state;
    }
  }
}
