export default (event) => {
  const { target } = event;
  const itemEl = target && target.closest && target.closest('.list-chk-item');
  const labelEls = itemEl && itemEl.querySelectorAll('.list-chk-item__grid-label');
  const lastChild = labelEls && labelEls[labelEls.length - 1];
  lastChild && lastChild.scrollIntoView &&
    setTimeout(() => {
      /**
       * Workaround to fix firefox windows modal collapse
       */
      document.querySelector('.modal').scrollTop = 0;
      lastChild.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, 200);
};
