import React from 'react';
import PropTypes from 'prop-types';

import Spinner from 'shared-components/components/Spinner';

import './style.scss';

const Button = ({
  action,
  className,
  children,
  loading,
  disabled,
}) => (
  <button 
    type="button"
    onClick={action}
    className={`lndg__btn pr--m pl--m tp-s--l tp-w--l c-txt--w c-bg--primary is-clickable b--0 b-r--m ${className}${loading ? ' loading' : ''}${disabled ? ' disabled' : ''}`}
  >
    {loading && <Spinner className="lndg__btn__spin tp-va--m" inline={false} color="w" />}
    {children}
  </button>
);

export default Button;

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  action: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  action: () => {},
  className: '',
  loading: false,
  disabled: false,
};
