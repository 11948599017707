import React from 'react';

import request from 'superagent';

import PAGES_TYPE from 'shared-constants/src/pagesType';

export const LS_CERCACASA_STARTPAGE = 'csa__cercacasa__entrypoint';

export const getMainLocation = (locations) => {
  if (!locations.length) {
    return {
      name: 'scegli dove',
    };
  }
  const [loc] = locations;
  return {
    name: loc.name,
    type: loc.parent.province.name === loc.name ? ' (comune)' : '',
  };
};

export const createSteps = (configuration) => {
  const steps = {};
  Object.keys(configuration).forEach((step) => {
    const Component = configuration[step].component;
    steps[step] = x => (
      <Component
        key={`step-${step}`}
        stepProps={x}
      />
    );
  });
  return steps;
};

export const smoothScroll = (coords, scroller) => {
  const diff = (coords - scroller.scrollTop) / 4.5;
  if (Math.abs(diff) > 1) {
    scroller.scrollTo(0, (scroller.scrollTop + diff));
    clearTimeout(window.TO);
    window.TO = setTimeout(smoothScroll, 30, coords, scroller);
  } else {
    scroller.scrollTo(0, coords);
  }
};

export const computeAPIReq = async ({
  locations,
  filters,
  apireqParams,
}) => {
  const proxy = await request.post('/portal-srp/api/v1/apireq')
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .withCredentials()
    .send({
      locations,
      filters: {
        ...filters,
        is_auction: false,
      },
      apireqParams,
      pageType: PAGES_TYPE.SRP,
    });
  const { body: { apireq } } = proxy;
  apireq.apireq.size = 0;
  delete apireq.apireq.query.seo;
  apireq.apireq.query[0].modifiers = {
    with_surroundings: false,
    autoexpand_location_polygon: false,
    with_breadcrumb: false,
    with_filters: false,
    with_georeach: false,
    with_poi: false,
  };
  return apireq;
};
