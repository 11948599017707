import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DEFAULT_FILTERS_CONFIGURATION } from 'shared-constants/src/filters';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';

import FilterEditDsk from 'shared-vectors/icons/FilterEditDsk';
import { Icon } from 'shared-vectors';

import DialogQB from '@async-components/DialogQB';

import './style.scss';

const setMax14 = str => str.length > 14
  ? `${str.substring(0, 14)}...`
  : str;

const getType = (filters) => {
  const { propertyTypeGroup, propertyTypes } = filters;

  if (!propertyTypes.length) {
    return setMax14(DEFAULT_FILTERS_CONFIGURATION.category.values.filter(x => x.value === propertyTypeGroup || x.display === propertyTypeGroup)[0]?.plural || 'Case');
  }
  const [pt] = propertyTypes;
  const first = DEFAULT_FILTERS_CONFIGURATION.propertyTypes.values[propertyTypeGroup].filter(x => x.display === pt)[0];
  return `${setMax14(first?.plural || 'Case')}${propertyTypes.length > 1 ? ` +${propertyTypes.length - 1}` : ''}`;
};

const getLocs = (locations, parentLocation) => {
  const hasParent = Object.keys(parentLocation).length > 0;
  const hasLocations = locations.length > 0;

  if (!hasLocations) {
    return null;
  }

  const res = {
    parent: '',
    children: '',
  };

  if (hasParent && !(locations.length === 1 && locations[0].hkey === parentLocation.hkey)) {
    res.parent = <> a <span className="tp-w--l">{setMax14(parentLocation.name)}</span></>;
  }

  const locationsClone = [...locations];
  const first = locationsClone.shift();
  const hasMultiple = locations.length > 1;
  let prep = first.level === 10 || first.level === 11 ? <>in zona </> : <>a </>;
  if (first.level === 118) {
    prep = <>sulla </>;
  }
  if (first.level === 120) {
    prep = <>intorno all{hasMultiple ? 'e' : 'a'} fermat{hasMultiple ? 'e' : 'a'} </>;
  }
  if (first.level === 9) {
    prep = <>ne{hasMultiple ? 'i' : 'l'} comun{hasMultiple ? 'i' : 'e'} </>;
  }
  res.children = <> {prep}<span className="tp-w--l">{setMax14(first.name)}{locations.length > 1 ? ` +${locations.length - 1}` : ''}</span></>;

  return res;
};

const setLocs = (parentLocation, locations) => {
  const hasParent = Object.keys(parentLocation).length > 0;
  const hasLocations = locations.length > 0;

  if (!hasParent && !hasLocations) {
    return null;
  }

  if (
    !hasParent
    || (locations.length === 1 && locations[0].hkey === parentLocation.hkey)
  ) {
    const [loc] = locations;
    const level = loc.level === 6
      ? ' (prov.)'
      : '';
    const val = `${loc.name}${level}`;
    return {
      short: val,
      long: val,
    };
  }

  const locationsClone = [...locations];
  const first = locationsClone.shift();
  const firstNameShort = first.name.length > 14
    ? `${first.name.substring(0, 10)}...`
    : first.name;

  return {
    short: `${parentLocation.name}: ${firstNameShort}${locationsClone.length ? ` +${locationsClone.length}` : ''}`,
    long: `${parentLocation.name}: ${first.name}${locationsClone.length ? ` +${locationsClone.length}` : ''}`,
  };
};

const ProposeFiltersDialog = ({
  locations,
  parentLocation,
  showLocs,
  filters,
  activeFilters,
  isMobile,
  setDialog,
  setDialogIsOpen,
  resetHPLastSearch,
  updateFilters,
  setHasBeenReset,
  iosLth12,
  onPtgs,
}) => {
  const [locs] = useState(setLocs(parentLocation, locations));
  const [hide, setHide] = useState(false);

  const onDialogToggle = (open) => {
    // setHide(open);
    setDialogIsOpen(open); 
  };

  const createDialog = () => {
    const dialog = (
      <DialogQB
        locations={locs && locs.long}
        filters={filters}
        activeFilters={activeFilters}
        onClose={onDialogToggle}
        isMobile={isMobile}
        resetHPLastSearch={resetHPLastSearch}
        updateFilters={updateFilters}
        setHasBeenReset={setHasBeenReset}
        handleLocalities={!showLocs}
      />
    );    
    setDialog(dialog);
    onDialogToggle(true);
  };

  const newLocs = getLocs(locations, parentLocation);

  const text = <><span className="tp-w--l">{getType(filters)}</span> in <span className="tp-w--l">{filters.channel}</span>{newLocs.parent}{newLocs.children} {activeFilters.length ? '| ' : ''}{activeFilters.map((fil, index) => <>{fil.displayValue}{index < activeFilters.length - 1 ? ' | ' : ''}</>)}</>;

  if (hide) {
    return null;
  }

  return (
    <div className={`pfd${iosLth12 ? ' pfd--stck' : ''}`}>
      <Grid className={`pfd__cont${onPtgs ? ' pfd__cont--ptg' : ''} is-clickable`}>
        <GridItem>
          <Grid
            align="center"
            className="pfd__h100"
            onClick={createDialog}
          >
            <p className="pfd__txt tp-s--m tp-w--s c-txt--f0">
              {text}
            </p>
          </Grid>
        </GridItem>
        <GridItem behavior="fixed">
          <Grid
            align="center"
            className="pfd__dialog pfd__h100 c-txt--f0 pl--m"
            onClick={createDialog}
          >
            <Icon name={FilterEditDsk} width="16px" height="16px" />
          </Grid>
        </GridItem>
      </Grid>
    </div>
  );
};

export default ProposeFiltersDialog;

ProposeFiltersDialog.propTypes = {
  locations: PropTypes.instanceOf(Array),
  parentLocation: PropTypes.instanceOf(Object),
  showLocs: PropTypes.bool,
  filters: PropTypes.instanceOf(Object),
  activeFilters: PropTypes.instanceOf(Array),
  isMobile: PropTypes.bool,
  setDialog: PropTypes.func,
  setDialogIsOpen: PropTypes.func,
  resetHPLastSearch: PropTypes.func,
  updateFilters: PropTypes.func,
  setHasBeenReset: PropTypes.func,
  iosLth12: PropTypes.bool,
  onPtgs: PropTypes.bool,
};
ProposeFiltersDialog.defaultProps = {
  locations: [],
  parentLocation: {},
  showLocs: true,
  filters: {},
  activeFilters: [],
  isMobile: true,
  setDialog: () => {},
  setDialogIsOpen: () => {},
  resetHPLastSearch: null,
  updateFilters: () => {},
  setHasBeenReset: null,
  iosLth12: false,
  onPtgs: false,
};
