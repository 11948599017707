import request from 'superagent';

import parseJSON from 'shared-utils/src/parseJSON';
import { computeActiveFilters } from 'shared-utils/src/helpersMyCasa';
import { DEFAULT_FILTERS_CONFIGURATION } from 'shared-constants/src/filters';

import { filterSections } from 'shared-constants/src/filters/groups';
import provinceShortToLongList from 'shared-constants/src/locations/provinceShortToLong';

import createLabelSentence from 'shared-utils/src/createLabelSentence';
import createTXTLabelSentence from 'shared-utils/src/createLabelSentence/justText';
import manageRangeFiltersData from 'shared-utils/src/manageRangeFiltersData';
import isValidString from 'shared-utils/src/checkVarType/isValidString';
import { getPoiCategoryName, getNearByName, getNearByMin } from '@helpers/dataModels/poi';
import { resetFilter } from '@filters/constants/filters';

import {
  replaceAll,
  capitalizeFirstLetter,
  integerWithEuropeThousandSeparator,
} from '../STRINGHelpers';

import {
  categoryFilter,
} from '../../constants/restyling';

export const getFilterPlurals = (values, selected) => {
  try {
    return values.map(
      (type) => {
        if (selected.includes(type.display)) {
          return type.plural;
        }
        return null;
      },
    ).filter(Boolean);    
  } catch (error) {
    console.log(`getPtypesPlurals error: ${error}`);    
  }
  return selected;
};

export const getPtypesPlurals = (group, selected) => {
  const values = DEFAULT_FILTERS_CONFIGURATION.propertyTypes.values[group];
  return getFilterPlurals(values, selected);  
};

export async function srpOnBackButton(uri, domain, locale, esapi, pagetype, isAgencySrp) {
  const proxy = await request.post('/portal-srp/api/v1/apireq/searchParamsForUrl')
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .withCredentials()
    .send({
      uri,
      domain,
      locale,
      esapi,
      pagetype,
      isAgencySrp,
    });
  const { body: { apireq } } = proxy;
  return apireq;
}

export async function computeHowToGoBack({
  domain,
  currentLocale,
  esapi,
  fetchAgencyListingOnFiltersChange = null,
  fetchListingOnFiltersChange,
  goBack,
  pageType,
  backUriCollection,
}) {
  if (backUriCollection.length > 1) {
    const {
      uri,
      filters,
      locations,
      parentLocation,
      isPublisherHP,
      isAgencySrp,
      agencyFreetext,
    } = backUriCollection[backUriCollection.length - 2];
    const apireq = await srpOnBackButton(uri, domain, currentLocale, esapi, pageType, isAgencySrp);
    backUriCollection.pop();
    if (isAgencySrp) {
      fetchAgencyListingOnFiltersChange(apireq);
    } else {
      fetchListingOnFiltersChange(apireq);
    }
    return {
      action: 'smartback',
      newState: {
        filters,
        locations,
        parentLocation,
        collapsePublisherInfos: !isPublisherHP,
        agencyFreetext,
      },
      backUriCollection,
    };
  }
  return {
    action: 'browserback',
    goBack,
  };
}

export const getProvinceShortName = long => Object.keys(provinceShortToLongList).find(key => provinceShortToLongList[key] === long);

export function createTokensFromActiveFilters(filtersList, currentChannel) {
  // channel
  let channel = 'Vendita';
  if (currentChannel) {
    if (currentChannel === 'affitti') {
      channel = 'Affitto';
    } else if (currentChannel === 'affitto-breve') {
      channel = 'Affitto Breve';
    }
  }

  if (!filtersList) {
    return channel;
  }
  // category
  if (
    'category' in filtersList
    && filtersList.category
  ) {

    if (filtersList.category.toLowerCase() === 'commerciale') {
      channel += ' comm.';
    } else if (filtersList.category.toLowerCase() === 'vacanze') {
      channel += ' vacanze';
    }
  }
  // propertyTypes
  let pTypes = '';
  if ('propertyTypes' in filtersList) {
    pTypes = filtersList.propertyTypes.join(', ');
  }
  // price / mqprice / mortgage rate
  let price = '';
  if ('priceMin' in filtersList || 'priceMax' in filtersList) {
    if ('priceMin' in filtersList) {
      price += `da ${String.fromCharCode(8364)} ${integerWithEuropeThousandSeparator(filtersList.priceMin)}`;
      if ('priceMax' in filtersList) {
        price += ` a ${String.fromCharCode(8364)} ${integerWithEuropeThousandSeparator(filtersList.priceMax)}`;
      }
    } else {
      price += `fino a ${String.fromCharCode(8364)} ${integerWithEuropeThousandSeparator(filtersList.priceMax)}`;
    }
  }
  if ('mqpriceMin' in filtersList || 'mqpriceMax' in filtersList) {
    if ('mqpriceMin' in filtersList) {
      let mq = ' al mq.';
      if ('mqpriceMax' in filtersList) {
        mq = '';
      }
      price += `da ${String.fromCharCode(8364)} ${integerWithEuropeThousandSeparator(filtersList.mqpriceMin)}${mq}`;
      if ('mqpriceMax' in filtersList) {
        price += ` a ${String.fromCharCode(8364)} ${integerWithEuropeThousandSeparator(filtersList.mqpriceMax)} al mq.`;
      }
    } else {
      price += `fino a ${String.fromCharCode(8364)} ${integerWithEuropeThousandSeparator(filtersList.mqpriceMax)} al mq.`;
    }
  }
  if ('paymentMin' in filtersList || 'paymentMax' in filtersList) {
    if ('paymentMin' in filtersList) {
      price += `rata da ${String.fromCharCode(8364)} ${integerWithEuropeThousandSeparator(filtersList.paymentMin)}`;
      if ('paymentMax' in filtersList) {
        price += ` a ${String.fromCharCode(8364)} ${integerWithEuropeThousandSeparator(filtersList.paymentMax)}`;
      }
    } else {
      price += `rata fino a ${String.fromCharCode(8364)} ${integerWithEuropeThousandSeparator(filtersList.paymentMax)}`;
    }
  }
  // surface
  let mq = '';
  if ('mqMin' in filtersList || 'mqMax' in filtersList) {
    if ('mqMin' in filtersList) {
      mq += `da ${String.fromCharCode(8364)} ${integerWithEuropeThousandSeparator(filtersList.mqMin)} mq.`;
      if ('mqMax' in filtersList) {
        mq += ` a ${String.fromCharCode(8364)} ${integerWithEuropeThousandSeparator(filtersList.mqMax)} mq.`;
      }
    } else {
      mq += `fino a ${String.fromCharCode(8364)} ${integerWithEuropeThousandSeparator(filtersList.mqMax)} mq.`;
    }
  }
  // rooms number
  let numRooms = '';
  if ('numRoomsMin' in filtersList && 'numRoomsMax' in filtersList) {
    numRooms += `con da ${filtersList.numRoomsMin} a ${filtersList.numRoomsMax} locali `;
  } else if ('numRoomsMin' in filtersList) {
    numRooms += `con almeno ${filtersList.numRoomsMin} local${filtersList.numRoomsMin === '1' ? 'e' : 'i'} `;
  } else if ('numRoomsMax' in filtersList) {
    numRooms += `con massimo ${filtersList.numRoomsMax} local${filtersList.numRoomsMax === '1' ? 'e' : 'i'} `;
  } else if ('numRooms' in filtersList) {
    numRooms += `${filtersList.numRooms} locali`;
  }

  // bathrooms number
  let numBaths = '';
  if ('numBaths' in filtersList) {
    numBaths += `${filtersList.numBaths} bagni`;
  }
  // parkings number
  let numParkingSpaces = '';
  if ('numParkingSpaces' in filtersList) {
    numParkingSpaces += `${filtersList.numParkingSpaces} box`;
  }
  // heating type
  let heatingType = '';
  if ('heatingType' in filtersList) {
    heatingType += `riscaldamento ${filtersList.heatingType}`;
  }
  // garden
  let garden = '';
  if ('garden' in filtersList) {
    // garden += `${filtersList.garden.replace('-', ' ')}`;
    garden += filtersList.garden;
  }
  return {
    channel,
    pTypes,
    price,
    mq,
    numRooms,
    numBaths,
    numParkingSpaces,
    heatingType,
    garden,
  };
}

const formatTokens = (el) => {
  let text = el.name;
  if (el.levels) {
    switch (el.level) {
      case 6:
        text += ' (provincia)';
        break;
      case 9:
        text += ` (${getProvinceShortName(el.levels.L6)})`;
        break;
      case 10: // area
        text += ` - ${el.levels.L9} (${getProvinceShortName(el.levels.L6)})`;
        break;
      default:
        text = el.name;
    }
  }
  return text;
};

export const formatMobileHeaderFiltersString = (filtersList, currentChannel, alwaysIncludePTypes, excludeChannel = false) => {
  let count = 0;
  const limit = 3;
  const {
    channel,
    pTypes,
    price,
    mq,
    numRooms,
    numBaths,
    numParkingSpaces,
    heatingType,
    garden,
  } = createTokensFromActiveFilters(filtersList, currentChannel);
  const string = [];
  if (!excludeChannel) {
    string.push(channel);
  }
  if (price !== '') {
    count += 1;
    string.push(price);
  }
  if (alwaysIncludePTypes && pTypes !== '') {
    string.push(pTypes);
  }
  if (price === '' && mq !== '') {
    count += 1;
    string.push(mq);
  }
  if (numRooms !== '') {
    count += 1;
    string.push(numRooms);
  }
  if (numBaths !== '' && count < limit) {
    count += 1;
    string.push(numBaths);
  }
  if (numParkingSpaces !== '' && count < limit) {
    count += 1;
    string.push(numParkingSpaces);
  }
  if (heatingType !== '' && count < limit) {
    count += 1;
    string.push(heatingType.replace(',', ' o '));
  }
  if (garden !== '' && count < limit) {
    count += 1;
    string.push(`giardino ${garden.replace(',', ' o ')}`);
  }
  if (string.length === 1 && pTypes !== '' && !alwaysIncludePTypes) {
    string.push(pTypes);
  }
  return string.join(' | ');
};

export const extractTokensAndFilters = (tokens, filters, alwaysIncludePTypes) => {
  const localitiesArray = tokens && tokens.length > 0 ? tokens.map(el => formatTokens(el)) : ['Ovunque (tutte le località)'];
  const filtersString = formatMobileHeaderFiltersString(computeActiveFilters(filters).activeFiltersList, filters.channel, alwaysIncludePTypes);
  return { localitiesArray, filtersString };
};

export const saveRecentSearchesEntry = (tokens, filters, uri, localStorageKey) => {
  const { localitiesArray, filtersString } = extractTokensAndFilters(tokens, filters, false);
  const recentSearchEntry = {
    localities: localitiesArray.join(', '),
    filters: filtersString,
    uri,
  };
  const recentSearches = window.localStorage.getItem(localStorageKey) !== '' && window.localStorage.getItem(localStorageKey) !== null ? parseJSON(window.localStorage.getItem(localStorageKey)) : [];
  const isAlreadySaved = recentSearches.filter(rs => rs.uri === uri);
  if (isAlreadySaved.length > 0) {
    let alreadySavedIndex = null;
    recentSearches.forEach((rs, index) => { if (rs.uri === uri) alreadySavedIndex = index; });
    recentSearches.splice(alreadySavedIndex, 1);
  }
  recentSearches.unshift(recentSearchEntry);
  if (recentSearches.length > 5) {
    recentSearches.pop();
  }
  window.localStorage.setItem(localStorageKey, JSON.stringify(recentSearches));
};

export const createLabel = (value) => {
  const usableValue = value === 'case-vacanza' ? 'case' : value;

  let categoryConf = categoryFilter.filter(item => item.slug === usableValue)[0];
  if (typeof categoryConf === 'undefined') {
    const filterOptionWithChildren = categoryFilter.filter(item => 'children' in item)[0];
    if (filterOptionWithChildren) {
      const { children } = filterOptionWithChildren;
      // eslint-disable-next-line prefer-destructuring
      categoryConf = (children || []).filter(item => item.slug === usableValue)[0];
    }
  }
  /**
   * https://sentry.io/organizations/casait/issues/3426466194/?project=5222733&query=is%3Aunresolved
   * 
   * se non riesco a recuperare la label dal file di conf tanto peggio, lascio vuoto ma evito l'errore
   * potrebbe essere risolta dall'utilizzo del propertyTypeGroup quando la category non è definita
   * 
   */
  if (categoryConf) {
    return categoryConf.label === 'Immobili e attività commerciali' ? 'Imm. e att. comm.' : categoryConf.ctaLabel;
  }
  return '';
};

const addRoomRange = (list) => {
  const ret = { ...list };
  if ('numRoomsMin' in ret && 'numRoomsMax' in ret) {
    ret.rooms = {
      min: ret.numRoomsMin,
      max: ret.numRoomsMax,
    };
    delete ret.numRoomsMin;
    delete ret.numRoomsMax;
  } else if (
    'numRoomsMin' in ret
    && !('numRoomsMax' in ret)
  ) {
    ret.rooms = { min: ret.numRoomsMin };
    delete ret.numRoomsMin;
  } else if (
    !('numRoomsMin' in ret)
    && 'numRoomsMax' in ret
  ) {
    ret.rooms = { max: ret.numRoomsMax };
    delete ret.numRoomsMax;
  }
  return ret;
};

export const getHumanFilters = (list, justText = true) => {
  const humanFilters = [];

  const filtersOrder = {};
  Object.keys(filterSections).forEach((x, index) => {
    filtersOrder[x === 'baths' ? 'numBaths' : x] = Object.keys(filterSections).length - index;
  });
  const listWRooms = addRoomRange(list);
  const filtersList = manageRangeFiltersData(JSON.parse(JSON.stringify(listWRooms)));
  Object.keys(filtersList).map((fil) => {
    const displayValue = justText ? createTXTLabelSentence(fil, list) : createLabelSentence(fil, list);
    if (displayValue) {
      humanFilters.push({
        order: filtersOrder[fil],
        displayValue,
        name: fil === 'rooms' ? 'numRooms' : fil,
      });
    }
  });

  const ret = humanFilters
    .filter(i => !!i)
    .sort((x, y) => x.order > y.order ? -1 : 1);

  if (list.poi) {
    return [
      {
        displayValue: `vicino a ${getPoiCategoryName(Object.keys(list.poi)[0])}`,
        name: 'poi',
      },
      ...ret,
    ];    
  }
  return ret;
};

const noUndefProvince = (province) => {
  let res = '';
  if (!province) {
    return res;
  }
  if (province.short) {
    res = province.short;
  }
  if (province.name) {
    res = getProvinceShortName(province.name);
  }  
  if (province.parent?.country?.name === 'France') {
    res = 'Francia';
  }
  if (province.parent?.region?.name === 'San Marino') {
    res = 'Rep. San Marino';
  }
  return isValidString(res)
    ? `(${res})`
    : '';
};

export const createFiltersString = (filters) => {
  const filtersList = getHumanFilters(computeActiveFilters(filters).activeFiltersList).map(el => el.displayValue).filter(Boolean);

  /**
   * https://sentry.io/organizations/casait/issues/3426466194/?project=5222733&query=is%3Aunresolved
   *
   * è possibile venga risolto semplicemente dall'aggiunta del propertyTypeGroup qui:
   * createLabel(filters.category || filters.propertyTypeGroup)
   *  
   */

  const first = filters.propertyTypes && filters.propertyTypes.length && filters.propertyTypes[0] && filters.propertyTypes[0] !== 'all'
    ? `${getPtypesPlurals(filters.propertyTypeGroup, capitalizeFirstLetter(filters.propertyTypes[0]))}${filters.propertyTypes.length > 1 ? ` +${filters.propertyTypes.length - 1}` : ''}`
    : createLabel(filters.category || filters.propertyTypeGroup);

  let purpose = filters.channel === 'affitti' ? 'affitto' : filters.channel;

  if (filters.category === 'case-vacanza') {
    purpose = 'affitto breve';
  }

  return `${first} in ${capitalizeFirstLetter(purpose)}${filtersList.length > 0 ? ` ${filtersList.join(' | ')}` : ''}`;
};

const extractTokensAndFiltersHP = (localities, parentLocation, filters) => {
  let localitiesString = '';
  if (localities.length === 1) {
    switch (localities[0].level) {
      case 10:
        localitiesString += `${localities[0].name} - ${parentLocation.name} ${noUndefProvince(parentLocation.parent.province)}`;
        break;
      case 9: 
        localitiesString += `${localities[0].name} ${noUndefProvince(Object.keys(parentLocation).length ? parentLocation : localities[0].parent.province)}`;
        break;
      case 6:
        localitiesString += `${localities[0].name} (provincia)`;
        break;
      default:
        localitiesString += localities[0].name;
    }
  } else if (localities.length > 1) {
    switch (localities[0].level) {
      case 10:
        localitiesString += `${localities.map(loc => replaceAll(loc.name, [',', '/'], [' ·', ' ·'])).join(', ')} - ${parentLocation.name} ${noUndefProvince(parentLocation?.parent?.province)}`;
        break;
      case 9:
        localitiesString += `${localities.map(loc => `${loc.name} ${noUndefProvince(parentLocation)}`).join(', ')}`;
        break;
      case 6:
        localitiesString += `${localities.map(loc => `${loc.name} (provincia)`).join(', ')}`;
        break;
      default:
        localitiesString += localities[0].name;
    }
  } else if (filters.nearby) {
    if (getNearByMin(filters.nearby) && getNearByName(filters.nearby)) {
      localitiesString = `A ${getNearByMin(filters.nearby)} min da ${getNearByName(filters.nearby)}`;
    }
  }

  const filtersString = createFiltersString(filters);

  return { localitiesString, filtersString };
};


export const saveRecentSearchesEntryHP = ({
  localities,
  parentLocation,
  filters,
  uri,
  localStorageKey,
  deduplicate = false,
}) => {
  const { localitiesString, filtersString } = extractTokensAndFiltersHP(localities, parentLocation, filters);

  const recentSearchEntry = {
    localities: localitiesString,
    filters: filtersString,
    uri,
  };

  const recentSearches = window.localStorage.getItem(localStorageKey) !== '' && window.localStorage.getItem(localStorageKey) !== null
    ? parseJSON(window.localStorage.getItem(localStorageKey))
    : [];

  const isAlreadySaved = recentSearches.filter(rs => rs.uri === uri);

  if (isAlreadySaved.length > 0) {
    let alreadySavedIndex = null;
    recentSearches.forEach((rs, index) => { if (rs.uri === uri) alreadySavedIndex = index; });
    recentSearches.splice(alreadySavedIndex, 1);
  }

  // deduplica su desktop località/canale/categoria/ptype su ultimo record 
  if (deduplicate && recentSearches.length) {
    const getCategoryChannel = (str) => {
      /**
       * https://sentry.io/organizations/casait/issues/3426459769/?project=5222733&query=is%3Aunresolved
       * 
       * aggiungo un controllo sul valore delle varie stringhe manipolate in modo da evitare split su 'undefined'
       * ritorno un flag assieme all'eventuale valore per rendere più sicuro anche il confronto
       * tra la ricerca corrente e l'ultima salvata
       * 
       */
      if (!isValidString(str)) {
        return {
          val: null,
          isValid: false,
        };
      }

      let val = null;
      let isValid = true;

      const category = str.split(' in ')[0];
      const channel = str.split(' in ')[1];

      const hasCategory = isValidString(category);
      const hasChannel = isValidString(channel);

      if (hasCategory && hasChannel) {
        val = `${category} in ${channel.split(' ')[0]}`;
      } else if (hasCategory) {
        val = category;
      } else if (hasChannel) {
        val = channel;
      } else {
        isValid = false;
      }

      return {
        val,
        isValid,
      };
    };

    const [lastSaved] = recentSearches;

    if (localitiesString === lastSaved.localities) {
      const currentSearchType = getCategoryChannel(filtersString);
      const lastSearchType = getCategoryChannel(lastSaved.filters);

      if (
        currentSearchType.isValid
        && lastSearchType.isValid
        && currentSearchType.val === lastSearchType.val
      ) {
        recentSearches.splice(0, 1);
      }
    }
  }

  recentSearches.unshift(recentSearchEntry);

  if (recentSearches.length > 20) {
    recentSearches.pop();
  }

  window.localStorage.setItem(localStorageKey, JSON.stringify(recentSearches));
};

const timestampIsExpired = ({ tsToCheck = new Date().getTime(), timestampToAdd = 0 }) => {
  const today = new Date().getTime();
  if (today > (tsToCheck + timestampToAdd)) {
    return true;
  }
  return false;
};

const isADayPassed = ({ tsToCheck = new Date().getTime() }) => {
  const today = new Date().getTime();
  return tsToCheck < today;
};

/**
 * reset the filters to their default values
 * @param {filters} filters
 * @param {function} updateFilters
 * @param {function} forceMobileUpdate 
 */
export const resetSearchFilters = ({ filters, updateFilters, forceMobileUpdate }) => {
  const defaultFilters = { ...filters };
  Object.keys(DEFAULT_FILTERS_CONFIGURATION).forEach(name => resetFilter(name, defaultFilters));

  updateFilters({ ...defaultFilters, poi: null }, forceMobileUpdate);
};


export const isBannerStickyLargeShowable = () => {
  const tmpLSAppDownload = localStorage.getItem('_downloadAppBannerStickyLarge');
  const lsAppDownload = parseJSON(tmpLSAppDownload);
  if (lsAppDownload) {
    const isFirstClosure = lsAppDownload.type === 'firstClosure';
    if (isFirstClosure) {
      return true;
    }
    return false;
  }
  return true;
};

export const normalizeSRPUrl = (computedUrl, publisherRegion = false) => {
  let uri = computedUrl.replace(new RegExp('[àáâãäå]', 'g'), 'a');
  uri = uri.replace(new RegExp('[èéêë]', 'g'), 'e');
  uri = uri.replace(new RegExp('[òóôõö]', 'g'), 'o');
  uri = uri.replace(new RegExp('[ìíîï]', 'g'), 'i');
  uri = uri.replace(new RegExp('[ùúûü]', 'g'), 'u');
  uri = uri.replace(/'/g, ' ');
  uri = uri.replace(/,/g, '%2C');
  if (publisherRegion) {
    uri = uri.replace(/ /g, '%20');
  } else {
    uri = uri.replace(/ /g, '+');
  }
  uri = uri.replace(/\+%2C/gi, '%2C');
  return uri;
};

export const searchPublisherParentName = (pub) => {
  if (pub?.isAgentPro && pub?.publisherParent) {
    return pub?.publisherParent?.name || null;
  }
  return null;
};

export const isMapSearch = locations => locations.length && locations[0].level === 999;
export const isDrawonMapSearch = (locations, filters) => isMapSearch(locations) && (filters.geopolygon || filters.geocircle);

export const showLicenceTypeGroupsButton = (filters = {}) => {
  const cat = filters.category || '';
  return cat === 'commerciale';
};


// export const showLicenceContractTypeGroupsButton = (filters = {}) => {
//   const channel = filters.channel || '';
//   const cat = filters.category || '';
//   const ptypes = filters.propertyTypes || [];
//   if (channel === 'vendita' && cat === 'commerciale') {
//     return !ptypes.length || (ptypes.length === 1 && ptypes[0] !== 'Negozio/Locale commerciale') || ptypes.length > 1;
//   }

//   return false;
// };
