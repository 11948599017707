import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { smoothScroll } from '../../helpers';

const ListScroller = forwardRef(({ children }, ref) => {
  const scroller = useRef(null);
  const list = useRef(null);

  useImperativeHandle(ref, () => ({
    getScroller() {
      return scroller.current;
    },
    getList() {
      return list.current;
    },
  }));

  useEffect(() => {
    const scrollerNode = scroller.current;
    const listNode = list.current;
    const config = { childList: true };
    const callback = (mutationsList) => {
      if (mutationsList.length && mutationsList[0].type === 'childList') {
        smoothScroll(listNode.scrollHeight - scrollerNode.clientHeight, scrollerNode);
      }
    };
    const observer = new MutationObserver(callback);
    observer.observe(listNode, config);

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <div ref={scroller} className="lndg__body is-over--a">
      <div ref={list} className="lndg__list">
        {children}
      </div>
    </div>
  );
});

export default ListScroller;
