import { getItem } from 'shared-utils/src/localStorage';

import { googleMapsPath } from '@common/lib/constants';
import { loadScript } from './DOMHelpers';

export const isString = str => typeof str === 'string';

export const distinct = arr => arr.filter((value, index, self) => self.indexOf(value) === index);

export const randomStr = (len = 7) => Math.random().toString(36).substring(2, len + 2);

/**
 * Load ADS asynchronously and based on device
 * @param {String} device
 */

export const loadAds = (device) => {
  if (!device || window.__ads) return; // eslint-disable-line
  window.__ads = 1; // eslint-disable-line

  const load = () => {
    loadScript('https://www.casa.it/external-resources/adasta/lib/advmanager_19052020.min.js');
    window.removeEventListener('scroll', load);
  };
  
  // TODO: to test if loading ADS later helps without hurt
  if (device !== 'desktop') {
    window.addEventListener('scroll', load);
  } else {
    load();
  }
};

/**
 * Load google maps library
 * @param {Array|String} toLoad
 */
export const loadGoogleMaps = async (toLoad) => {
  const { google, __gmapsLoading } = window;
  const toLoadArr = Array.isArray(toLoad) ? toLoad : toLoad.split(',');
  let libExists = google && google.maps;

  if (libExists) {
    if (toLoadArr.indexOf('places') >= 0) {
      libExists = google.maps.places.AutocompleteService;
    }
  }

  if (!__gmapsLoading && !libExists) {
    window.__gmapsLoading = 1; // eslint-disable-line
    const src = googleMapsPath(toLoadArr.join(','));
    await loadScript(src);
    return 1;
  } if (__gmapsLoading && libExists) {
    return 1;
  }
};

export const makeClassName = cls => cls.filter(i => i).join(' ');

export const isFunction = fn => typeof fn === 'function';

export const trackEvents = (
  category,
  action,
  label = undefined,
  value = undefined,
  nonInteraction = false,
) => {
  window.dataLayer && window.dataLayer.push({
    event: 'GAEvent',
    eventInfo: {
      category,
      action,
      label,
      value,
      nonInteraction,
    },
  });
};

export const formatPublisherAddress = ({
  street,
  city,
  province,
  region,
  postal_code,
}) => {
  let address = '';
  if (street) {
    address += `${street}, `;
  }
  if (city) {
    address += `${city}${province ? ` (${province})` : ''}`;
  }
  if (region) {
    address += `, ${region}`;
  }
  if (postal_code) {
    address += `, ${postal_code}`;
  }
  return address;
};

export const getUserFormData = () => {
  let lsFormData = {};
  const formDataOld = getItem('formData');
  const signinData = getItem('signinData');
  if (formDataOld) {
    lsFormData = formDataOld;
    if (signinData && signinData.formData && Object.keys(signinData.formData).length) {
      localStorage.removeItem('formData');
    }
  } else if (signinData) {
    const { formData } = signinData;
    lsFormData = formData;
  }
  return lsFormData;
};
