// WARNING:
// DON'T IMPORT STUFF IN THIS FILE

export const googleMapsPath = (libraries = 'drawing,places,geometry') => `https://maps.googleapis.com/maps/api/js?key=AIzaSyCeM6OuOYuHL1rHahppuiQlWN0Z6nGCM78&v=3&language=it&libraries=${libraries}`;

export const appWithNRooms = {
  1: 'Monolocale',
  2: 'Bilocale',
  3: 'Trilocale',
  4: 'Quadrilocale',
};

export const provinceMainTownExceptions = {
  'Ascoli-Piceno': ['Ascoli Piceno'],
  'Monza e Brianza': ['Monza'],
  'Sud Sardegna': ['Carbonia'],
  'Forlì-Cesena': ['Forlì'],
  'Massa-Carrara': ['Massa'],
  'Verbano-Cusio-Ossola': ['Verbania'],
  'Pesaro-Urbino': ['Pesaro', 'Urbino'],
  'Barletta-Andria-Trani': ['Barletta', 'Andria', 'Trani'],
};

export const isProvinceMainTown = (loc) => {
  let res = loc.name === loc.levels.L6;
  if (Object.keys(provinceMainTownExceptions).indexOf(loc.levels.L6) > -1) {
    const mainTowns = provinceMainTownExceptions[loc.levels.L6];
    if (mainTowns.length === 1) {
      res = mainTowns[0] === loc.name;
    } else {
      res = mainTowns.filter(town => town === loc.name).length === 1;
    }
  }
  return res;
};

export const channelTranslations = {
  vendita: 'buy',
  affitto: 'rent',
  affitti: 'rent',
  'affitto-breve': 'rent',
};
