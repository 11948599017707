import React from 'react';
import loadable from '@loadable/component';
import QRCodeFallbackContainer from 'shared-components/components/QRCodeFallbackContainer';
import FakeQRCode from 'shared-components/components/QRCodeFallbacks/FakeQRCode';

export const sharedQRCodeGenerator = ({ 
  componentName,
  url,
  isVisibleTooltip,
}) => loadable(() => import(/* webpackChunkName: "async-[request]" */`shared-components/components/DynamicQRCodes/${componentName}`), {
// }) => loadable(() => import(/* webpackChunkName: "async-[request]" */'shared-components/components/QRCodeFallbackContainer'), {
  fallback: <QRCodeFallbackContainer isVisibleTooltip={isVisibleTooltip} url={url} />,
  ssr: false,
});

export const createQRCodeOnly = ({ 
  componentName,
  size=100,
}) => loadable(() => import(/* webpackChunkName: "async-[request]" */`shared-components/components/DynamicQRCodes/${componentName}`), {
// }) => loadable(() => import(/* webpackChunkName: "async-[request]" */'shared-components/components/QRCodeFallbacks/FakeQRCode'), {
  fallback: <FakeQRCode size={size} />,
  ssr: false,
});
