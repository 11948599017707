import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';

import Button from '../Button';

import Count from './Count';

import './style.scss';

const Footer = forwardRef(({
  count,
  action,
  className,
  isFetching,
  isBtnDisabled,
  hideCount,
  setDialog,
}, ref) => {
  const [hideBtn, setHideBtn] = useState(false);

  const innerHideBtn = () => setHideBtn(true);
  const innerRevealBtn = () => hideBtn && setHideBtn(false);

  useImperativeHandle(ref, () => ({
    revealBtn() {
      innerRevealBtn();
    },
    hideFooterBtn() {
      innerHideBtn();
    },
  }));

  return (
    <Grid 
      className={`lndg__foot pt--s ${className}`}
      align="center"
    >
      <Count
        total={count}
        isFetching={isFetching}
        setDialog={setDialog}
        hideBtn={hideBtn}
        className={hideCount ? 'hidden' : ''}
      />
      <GridItem behavior="fixed" push="left">
        <Button
          action={action}
          className={hideBtn ? 'noVis' : ''}
          disabled={isBtnDisabled || (!hideBtn && isFetching)}
        >
          Avanti
        </Button>
      </GridItem>
    </Grid>
  );
});

export default Footer;

Footer.propTypes = {
  count: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  action: PropTypes.func,
  isFetching: PropTypes.bool,
  isBtnDisabled: PropTypes.bool,
  hideCount: PropTypes.bool,
  className: PropTypes.string,
  setDialog: PropTypes.func,
};

Footer.defaultProps = {
  count: null,
  action: () => {},
  isFetching: false,
  isBtnDisabled: false,
  hideCount: false,
  className: '',
  setDialog: () => {},
};
