import React from 'react';
import isEqual from '@lodash/isEqual';
import PropTypes from 'prop-types';

// helpers
import {
  capitalizeFirstLetter,
} from '../helpers/stringHelpers';

import {
  isChildOf,
} from '../helpers/DOMHelpers';

import './Select.scss';

const SelectPropTypes = {
  scope: PropTypes.string,
  type: PropTypes.string,
  containerClassName: PropTypes.string,
  value: PropTypes.any,
  display: PropTypes.any,
  defaultDisplay: PropTypes.any,
  defaultValue: PropTypes.any,
  humanDefaultValue: PropTypes.string,
  humanValueMeasureUnit: PropTypes.string,
  labelOnly: PropTypes.string,
  values: PropTypes.array,
  updateFilters: PropTypes.func,
  resetFilter: PropTypes.func,
  isListingFetching: PropTypes.bool,
  optionsStatic: PropTypes.bool,
  changeValue: PropTypes.func,
  resetFilterEl: PropTypes.node,
  itemTemplate: PropTypes.func,
  openEvent: PropTypes.func,
};

const SelectDefaultProps = {
  scope: null,
  type: null,
  containerClassName: null,
  defaultDisplay: null,
  defaultValue: null,
  values: [],
  isListingFetching: false,
  optionsStatic: false,
  resetFilterEl: null,
  itemTemplate: null,
  value: [],
  humanDefaultValue: 'Seleziona',
  humanValueMeasureUnit: '',
  labelOnly: '',
  display: '',
  updateFilters: () => { },
  changeValue: () => { },
  resetFilter: () => { },
  openEvent: null,
};

class Select extends React.Component {

  constructor(props) {
    super(props);
    const getDisplay = props.values.filter(v => v.value === props.value)[0];
    const display = getDisplay ? getDisplay.display : props.display;
    this.state = {
      value: props.value,
      display,
      values: props.values,
      open: false,
    };
    this.lastValue = props.value;
    this.outerClick = this.outerClick.bind(this);
    this.updateTimeout = null;
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.doc = document.body;
  }

  componentWillReceiveProps(nextProps) {
    const {
      type,
    } = this.props;
    const {
      value,
      values,
    } = this.state;
    const valueChange = type === 'single'
      ? nextProps.value !== value
      : !isEqual(nextProps.value, value);
    const valuesSetChange = !isEqual(nextProps.values, values);
    if (valueChange) {
      this.setState(
        {
          value: nextProps.value,
          display: nextProps.display,
        },
        () => { this.lastValue = nextProps.value; },
      );
    }
    if (valuesSetChange) {
      this.setState({ values: nextProps.values });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      type,
      isListingFetching,
    } = this.props;
    const {
      value,
      values,
      open,
    } = this.state;
    const valueChange = type === 'single'
      ? nextState.value !== value
      : !isEqual(nextState.value, value);
    const valuesSetChange = !isEqual(nextState.values, values);
    const isOpen = nextState.open !== open;
    const isListingFetchingNow = nextProps.isListingFetching !== isListingFetching;
    if (valueChange || isOpen || valuesSetChange || isListingFetchingNow) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    this.doc.removeEventListener('click', this.outerClick);
  }

  /**
    * @desc Set new filter value on user update if needed + handles layout
    *
    * @fires setState - fires Component update
    * @fires props.updateFilters - fires parent component update
  */
  toggle() {
    this.optionList.scrollTop = 0;
    const {
      open,
      value,
    } = this.state;
    if (open) {
      if (!isEqual(value, this.lastValue)) {
        // const choice = { [this.props.scope]: this.state.value };
        // this.props.updateFilters(choice);
        this.lastValue = JSON.parse(JSON.stringify(value || []));
      }
      this.doc.removeEventListener('click', this.outerClick);
    } else {
      const { openEvent } = this.props;
      openEvent && openEvent();
      this.doc.addEventListener('click', this.outerClick);
    }
    this.setState({ open: !open });
  }

  doUpdate = (choice) => {
    if (this.updateTimeout !== null) {
      clearTimeout(this.updateTimeout);
      this.updateTimeout = null;
    }
    const {
      updateFilters,
      changeValue,
      type,
    } = this.props;

    const select = (usschoice) => {
      updateFilters(usschoice);
      const value = usschoice[Object.keys(usschoice)[0]];
      changeValue(Array.isArray(value) ? value : [value]);
    };

    if (type === 'single') {
      select(choice);
    } else {
      this.updateTimeout = setTimeout(
        () => select(choice),
        500,
      );
    }
  }

  /**
    * @desc Checks if user click on document.body should fire a layout change
    *
    * @fires isChildOf - helper
    * @fires toggle
  */
  outerClick(e) {
    if (
      e.target !== this.handle &&
      !isChildOf(e.target, this.optionList) &&
      e.target !== this.confirm
    ) {
      this.toggle();
    }
  }

  /**
    * @desc Checks if user click on document.body should fire a layout change
    *
    * @param {object} item - clicked item data or text
    *
    * @fires setState - fires Component update
    * @fires toggle
  */
  handleClick(item) {
    const {
      defaultValue,
      humanDefaultValue,
      defaultDisplay,
      type,
      scope,
    } = this.props;
    const {
      value: valueFromState,
      display: displayfromState,
    } = this.state;
    const stateValue = JSON.parse(JSON.stringify(valueFromState || []));
    const stateDisplay = JSON.parse(JSON.stringify(displayfromState || []));

    const newValue = item.value;
    const { display } = item;
    // single-choice select

    if (type === 'single') {
      let value = newValue;
      // user clicked on default
      if (newValue === humanDefaultValue.toLowerCase()) {
        value = defaultValue;
      }

      // user is removing one option
      if (
        value === valueFromState &&
        value !== defaultValue
      ) {
        value = defaultValue;

        // user is adding one option
      }
      const choice = { [scope]: value };
      this.doUpdate(choice);
      this.setState(
        { value, display },
        () => { this.toggle(); },
      );

      // multiple-choice select

      // user clicked on default
    } else if (display.toLowerCase() === humanDefaultValue.toLowerCase()) {
      const choice = { [scope]: defaultValue };
      this.doUpdate(choice);
      this.setState({ value: defaultValue, display: defaultDisplay });

      // user is removing one option
    } else if (stateValue.indexOf(newValue) > -1) {
      stateValue.splice(stateValue.indexOf(newValue), 1);
      stateDisplay && stateDisplay.splice(stateDisplay.indexOf(newValue), 1);
      // removed option was the only one
      if (valueFromState.length === 1) {
        const choice = { [scope]: defaultValue };
        this.doUpdate(choice);
        this.setState({ value: defaultValue, display: defaultValue });
      } else {
        const choice = { [scope]: stateValue };
        this.doUpdate(choice);
        this.setState({ value: stateValue, display: stateDisplay });
      }

      // user is adding one option
    } else {
      // select was set to its default value
      if (isEqual(stateValue, defaultValue)) {
        stateValue.splice(0, 1);
        stateDisplay.splice(0, 1);
      }
      stateValue.push(newValue);
      stateDisplay && stateDisplay.push(display);
      const choice = { [scope]: stateValue };
      this.doUpdate(choice);
      this.setState({ value: stateValue, display: stateDisplay });
    }
  }

  /**
    * @desc Compute the Select handle text
    *
    * @fires capitalizeFirstLetter - helper
    *
    * @returns {string} Select handle text
  */
  computeHandleText() {
    const {
      defaultValue,
      humanDefaultValue,
      humanValueMeasureUnit,
      defaultDisplay,
      values,
      scope,
    } = this.props;
    const { value, display } = this.state;
    const isPublicationDate = scope === 'publication_date';
    let handle = '';

    /** TECH-5874 
     * Old select component, it need to be refactored
     * Condition ad hoc on publication_date until it is refactored
    */
    if (isPublicationDate) {
      const selectedValue = values.filter(item => item.value === value)[0];
      handle = selectedValue ? selectedValue.display : 'qualsiasi';

    } else if (typeof value === 'string') {
      if (value === defaultValue || value === '0') {
        handle = humanDefaultValue === 'Nessuna scelta' ? defaultDisplay : humanDefaultValue;
      } else {
        if (scope === 'commercial_building_location') {
          console.log(this.props.display);
        }
        handle = `${display}${humanValueMeasureUnit}`;
      }
    } else {
      handle = humanDefaultValue;
      if (value) {
        if (value.length > 1) {
          handle = `${value.length} selezionate`;
        } else if (value.length === 1 || isPublicationDate) {
          handle = isEqual(value, defaultValue) ? humanDefaultValue : (display[0] || '');
          if (!handle) {
            const selectedValue = values.filter(item => item.value === value[0])[0];
            handle = selectedValue ? selectedValue.display : handle;
          }
        }
      }
    }
    return capitalizeFirstLetter(handle);
  }

  /**
    * @desc Create Select options
    *
    * @event setValue - Select option element onClick
    *
    * @fires capitalizeFirstLetter - helper
    *
    * @returns {array}  Select options list
  */
  computeOptions() {
    const {
      scope, type, defaultValue, humanDefaultValue, humanValueMeasureUnit, itemTemplate,
    } = this.props;
    const { value, values } = this.state;
    const options = [];
    values.forEach(
      (item, index) => {
        const ind = index;
        let txt = `${item.display}${humanValueMeasureUnit}`;
        let check = item.value;
        if (item === humanDefaultValue.toLowerCase()) {
          txt = item.display;
          check = 'all';
        }
        let cssClass = value && Array.isArray(value) && value.indexOf(check) > -1 ? 'selected' : '';
        if (type === 'multiple' && ind === 0) {
          cssClass = isEqual(value, defaultValue) ? 'default selected' : 'default';
        }
        /*
        if (item.count === 0 || this.props.isListingFetching) {
          cssClass = this.state.value.indexOf(check) > -1 ? 'disabled selected' : 'disabled';
        }
        */
        // const countValue = !this.props.isListingFetching && 'count' in item ? item.count : '--';
        const count = null; // 'count' in item && ind > 0 ? ` (${countValue}) ` : '';
        const distance = item.distance && item.distance !== '-' ? <span>{item.distance}</span> : null;
        let option;
        if (itemTemplate) {
          option = (
            <li key={`${scope}-${ind}`}>
              {itemTemplate(
                item,
                `${cssClass} withPOIelements is-clickable c-txt--secondary tp-s--m`,
                this.handleClick,
                capitalizeFirstLetter(txt),
                distance,
              )}
            </li>
          );
        } else {
          option = (
            <li key={`${scope}-${ind}`}>
              <button
                type="button"
                aria-label={capitalizeFirstLetter(txt)}
                className={cssClass}
                onClick={(e) => { e.preventDefault(); this.handleClick(item); }}
              >
                <span>{capitalizeFirstLetter(txt)}</span>
                {' '}
                {count}
              </button>
            </li>
          );
        }
        options.push(option);
      },
    );
    return options;
  }

  /**
   * @public fired by parent component
   *
   * @desc Change Select set of values
   *
   * @param {array} newValues - new Select set of values
   *
   * @fires setState - fires Component update
 */
  updateValues = (newValues) => {
    const {
      defaultValue,
      defaultDisplay,
    } = this.props;
    this.setState({
      value: defaultValue,
      display: defaultDisplay,
      values: newValues,
    });
  }

  computeResetButton = () => {
    const {
      scope,
      type,
      defaultValue,
      resetFilter,
    } = this.props;
    const { value } = this.state;
    const htmlCode =
      (
        <button
          type="button"
          aria-label="Cancella la tua scelta"
          className="resetButton"
          onClick={(e) => { e.preventDefault(); resetFilter(scope); }}
        >
          Cancella
        </button>
      );
    if (type === 'multiple') {
      return isEqual(value, defaultValue) ? null : htmlCode;
    }
    return value === defaultValue ? null : htmlCode;
  }

  render() {
    const { props } = this;
    const { values } = props;
    const {
      resetFilterEl,
      optionsStatic,
      defaultValue,
      scope,
      type,
      containerClassName = '',
    } = this.props;
    const { open, value } = this.state;
    const classNameAdd = optionsStatic ? 'is-stat' : '';
    if (values && values.length > 1) {
      const handleText = this.computeHandleText();
      const options = this.computeOptions();
      const resetButton = !open || !isEqual(value, defaultValue) ? this.computeResetButton() : null;
      return (
        <div
          id={`filter-${scope}`}
          className={`selectCont ${containerClassName}${open ? ' withshadow' : ''}`}
        >
          <button
            ref={(handle) => { this.handle = handle; }}
            type="button"
            aria-label={props.labelOnly === 'Stato al rogito' ? 'Scegli lo stato al rogito' : `Scegli la ${props.labelOnly === 'Tipologia' ? 'tipologia di immobile' : props.labelOnly.toLowerCase()}`}
            className={open ? 'handle active' : 'handle'}
            onClick={(e) => { e.preventDefault(); this.toggle(); }}
          >
            {handleText}
          </button>
          {
            type === 'single' ?
              <ul
                ref={(optionList) => { this.optionList = optionList; }}
                className={`optionList ${classNameAdd} ${open ? 'open' : ''}`}
              >
                {options}
              </ul>
              :
              <div className={`buttonContainer ${classNameAdd} ${open ? 'open' : ''}`}>
                <ul
                  ref={(optionList) => { this.optionList = optionList; }}
                  className="optionList"
                >
                  {options}
                </ul>
                <button
                  ref={(confirm) => { this.confirm = confirm; }}
                  type="button"
                  aria-label="Conferma la tua scelta e chiudi"
                  className="confirm"
                  onClick={(e) => { e.preventDefault(); this.toggle(); }}
                >
                  Chiudi
                </button>
              </div>
          }
          {resetButton && resetFilterEl ? resetFilterEl : resetButton}
        </div>
      );
    }
    return null;
  }
}

Select.propTypes = SelectPropTypes;
Select.defaultProps = SelectDefaultProps;
export default Select;
