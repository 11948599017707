import { isValidString } from 'shared-utils/src/checkVarType';

import SEOPagesPtypes, { hubLicenseTypeGroupsAnchors } from 'shared-constants/src/seoPagesData';

import {
  SEOPageLinkPType,
  SEOPagesNumrooms,
  SEOPageTitlePtype,
} from '../../helpers/SEOPagesHelpers';
import { licenseTypeGroupPlurals } from 'shared-utils/src/helpersMyCasa/constants';

export const hubSEOData = (filters, pathname) => {
  const {
    channel,
    category,
    propertyType,
    numRooms,
    resolvedLocation,
    buildingCondition,
    uri,
    isAgencyHub,
    onlyAuction,
    shortRent,
  } = filters;
  const correctChannel = channel === 'affitti' ? 'affitto' : channel;
  const locationName = resolvedLocation.name.replace(/-/g, ' ');
  const hasPtype = isValidString(propertyType);

  let ptype;
  if (hasPtype) {
    ptype = propertyType;
  } else if (shortRent) {
    ptype = 'case';
  } else {
    ptype = isValidString(buildingCondition) ? 'nuove-costruzioni' : category;
  }
  const listingTitle = `${SEOPageTitlePtype(ptype, numRooms)} in ${correctChannel}${shortRent ? ' breve' : ''} nella provincia di ${locationName} - Casa.it`;
  const listingH1 = `${SEOPageTitlePtype(ptype, numRooms)} in ${correctChannel}${shortRent ? ' breve' : ''} nella provincia di ${locationName}`;
  const listingDescription = !isValidString(ptype)
    ? `Trova le case in ${correctChannel} nella provincia di ${locationName}. La lista dei comuni, con il numero di annunci di case disponibili in ${correctChannel}, per farti trovare l'immobile giusto nell'area di ${locationName}`
    : `Trova ${SEOPageTitlePtype(ptype, numRooms, true).toLowerCase()} in ${correctChannel}${shortRent ? ' breve' : ''} nella provincia di ${locationName}. La lista dei comuni, con il numero di annunci di ${SEOPageTitlePtype(ptype, numRooms).toLowerCase()} disponibili in ${correctChannel}, per farti trovare l'immobile giusto nell'area di ${locationName}`;

  const agencyTitle = `Comuni con agenzie immobiliari nella provincia di ${locationName}`;
  const agencyH1 = `Agenzie immobiliari nella provincia di ${locationName}`;
  const agencyDescription = `Trova le agenzie immobiliari nella provincia di ${locationName}. Casa.it mette a tua disposizione un elenco sempre aggiornato di agenzie immobiliari nell’area di ${locationName}`;

  const auctionTitle = `Aste Giudiziarie in provincia di ${locationName}`;
  const auctionH1 = ` Aste giudiziarie nella provincia di ${locationName}`;
  const auctionDescription = `Trova le aste giudiziarie nella provincia di ${locationName}. Gli annunci di asta immobiliare sono suddivise per tutti i comuni della provincia di ${locationName}, così da facilitare la ricerca`;

  let title = listingTitle;
  let h1 = listingH1;
  let description = listingDescription;
  if (isAgencyHub) {
    title = agencyTitle;
    h1 = agencyH1;
    description = agencyDescription;
  }
  if (onlyAuction) {
    title = auctionTitle;
    h1 = auctionH1;
    description = auctionDescription;
  }

  const canonical = uri;
  return {
    seo: {
      title,
      h1,
      meta: {
        description: {
          content: description,
        },
      },
    },
    urls: {
      canonical,
      hreflang: {
        it: pathname,
      },
    },
    robots_index: true,
  };
};

export const calculateKeyAndContextBasedOnAnchor = ({
  hash,
  filters: {
    category,
  },
}) => {
  const anchor = hash.replace('#', '');
  let context = 'default';
  let key = 'all';
  let ptype = '';
  let nRooms = '';
  if (category === 'residenziale') {
    if (SEOPagesNumrooms[anchor]) {
      context = 'nRooms';
      key = anchor;
      nRooms = anchor;
      return {
        key,
        context,
        nRooms,
        ptype,
      };
    }
  }

  if (category === 'commerciale' && !SEOPagesPtypes[anchor]) {
    const commPtypes = Object.keys(hubLicenseTypeGroupsAnchors);
    let ltg = '';
    let ltPtype = '';
    commPtypes.map((pt) => {
      if (hubLicenseTypeGroupsAnchors[pt].includes(anchor)) {
        ltPtype = pt;
        ltg = anchor;
      }
    });
    if (ltg) {
      return {
        key: anchor,
        context: 'licenseTypeGroups',
        nRooms: '',
        ptype: ltPtype,
        licenseTypeGroup: ltg,
      };
    }
  }

  if (SEOPagesPtypes[anchor]) {
    context = 'ptypes';
    key = anchor;
    ptype = anchor;
  }

  return {
    key,
    context,
    ptype,
    nRooms,
  };

};

export const extractSRPUrls = ({ hubPage: { generalInfo = {} } = {} }) => {
  const urls = Object.keys(generalInfo).reduce((arr, ctx) => {
    if (generalInfo[ctx]) {
      Object.keys(generalInfo[ctx]).map((key) => {
        const lastNode = generalInfo[ctx][key];
        let ptypesForLinks = '';
        if (lastNode && lastNode.totalCount) {
          switch (ctx) {
            case 'licenseTypeGroups':
              ptypesForLinks = SEOPageLinkPType({
                ptype: true,
                ltGroup: key,
              });              
              break;
            case 'nRooms':
              ptypesForLinks = SEOPageLinkPType({
                ptype: true,
                numRooms: key,
              });
              break;
            case 'ptypes':
              ptypesForLinks = SEOPageLinkPType({
                ptype: key,
                numRooms: '',
              });
              break;
            default:
              ptypesForLinks = SEOPageLinkPType({
                ptype: '',
                numRooms: '',
              });
              break;
          }

          arr.push({ ...lastNode, typeForLink: ptypesForLinks });
        }
      });
    }
    return arr;
  }, []);
  return urls;
};
