/* eslint-disable react/prop-types */
import React from 'react';
import './style.scss';

export default function Spinner({
  className = '',
  color = 'primary',
  size = 'm',
  weight = 'm',
  inline = false,
  valignCenter = false,
}) {

  const clsWrp = [
    className,
    'spinner__wrp tp-a--c',
    inline ? 'spinner__wrp--inl' : '',
    valignCenter ? 'spinner__wrp--vac' : '',
  ].filter(i => i).join(' ');

  return (
    <div className={clsWrp}>
      <div className={`spinner c-br--${color} b--${weight} size--${size} b-r--100`} />
    </div>
  );
}
