import React from 'react';

export default function fetch(...actions) {
  return Wrapped => class Fetch extends React.PureComponent {

    // static contextTypes = {
    //   store: PropTypes.object, // Redux store.
    // };

    // Passed via react-router.
    // static propTypes = {
    //   location: PropTypes.object,
    //   params: PropTypes.object,
    // };

    // For server side fetching. Check frontend/render.js
    static fetchActions = actions;

    // For client side fetching.
    componentDidMount() {
      // const { location, store: { dispatch }, intl: { currentLocale } } = this.props;

      // actions.forEach(action => dispatch(action({ location, currentLocale })));
    }

    render() {
      return <Wrapped {...this.props} />;
    }

  };
}
