import { setItem, getItem } from 'shared-utils/src/localStorage';

import { LS_RECENTADDRESSES, RECENTADDRESSES_LS_LIMIT } from '../constants';

export default (newAddress) => {
  const recentArr = getItem(LS_RECENTADDRESSES) || [];
  const excludeDuplicates = recentArr.filter(addr => addr.name !== newAddress.name || (addr.name === newAddress.name && addr.parent !== newAddress.parent));  
  const updatedRecent = [newAddress, ...excludeDuplicates];

  if (updatedRecent.length > RECENTADDRESSES_LS_LIMIT) {
    updatedRecent.pop();
  }

  setItem(LS_RECENTADDRESSES, updatedRecent);
  
  return updatedRecent;
};
