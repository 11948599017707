/* eslint-disable no-console */
// @flow
export const FETCH_SEARCH = 'FETCH_SEARCH';
export const FETCH_SEARCH_START = 'FETCH_SEARCH_START';
export const FETCH_SEARCH_SUCCESS = 'FETCH_SEARCH_SUCCESS';
export const FETCH_SEARCH_ERROR = 'FETCH_SEARCH_ERROR';

export const FETCH_SEARCH_SCROLL = 'FETCH_SEARCH_SCROLL';
export const FETCH_SEARCH_SCROLL_START = 'FETCH_SEARCH_SCROLL_START';
export const FETCH_SEARCH_SCROLL_SUCCESS = 'FETCH_SEARCH_SCROLL_SUCCESS';
export const FETCH_SEARCH_SCROLL_ERROR = 'FETCH_SEARCH_SCROLL_ERROR';

export const FETCH_SEARCH_FILTERS = 'FETCH_SEARCH_FILTERS';
export const FETCH_SEARCH_FILTERS_START = 'FETCH_SEARCH_FILTERS_START';
export const FETCH_SEARCH_FILTERS_SUCCESS = 'FETCH_SEARCH_FILTERS_SUCCESS';
export const FETCH_SEARCH_FILTERS_ERROR = 'FETCH_SEARCH_FILTERS_ERROR';

export const FETCH_SEARCH_AVAILABLE_RESULTS = 'FETCH_SEARCH_AVAILABLE_RESULTS';
export const FETCH_SEARCH_AVAILABLE_RESULTS_START = 'FETCH_SEARCH_AVAILABLE_RESULTS_START';
export const FETCH_SEARCH_AVAILABLE_RESULTS_SUCCESS = 'FETCH_SEARCH_AVAILABLE_RESULTS_SUCCESS';
export const FETCH_SEARCH_AVAILABLE_RESULTS_ERROR = 'FETCH_SEARCH_AVAILABLE_RESULTS_ERROR';

export const FETCH_SEARCH_GEO = 'FETCH_SEARCH_GEO';
export const FETCH_SEARCH_GEO_START = 'FETCH_SEARCH_GEO_START';
export const FETCH_SEARCH_GEO_SUCCESS = 'FETCH_SEARCH_GEO_SUCCESS';
export const FETCH_SEARCH_GEO_ERROR = 'FETCH_SEARCH_GEO_ERROR';

export const FETCH_SEARCH_GEO_BOUNDS = 'FETCH_SEARCH_GEO_BOUNDS';
export const FETCH_SEARCH_GEO_BOUNDS_START = 'FETCH_SEARCH_GEO_BOUNDS_START';
export const FETCH_SEARCH_GEO_BOUNDS_SUCCESS = 'FETCH_SEARCH_GEO_BOUNDS_SUCCESS';
export const FETCH_SEARCH_GEO_BOUNDS_ERROR = 'FETCH_SEARCH_GEO_BOUNDS_ERROR';

export const FETCH_AGENCY_SEARCH = 'FETCH_AGENCY_SEARCH';
export const FETCH_AGENCY_SEARCH_START = 'FETCH_AGENCY_SEARCH_START';
export const FETCH_AGENCY_SEARCH_SUCCESS = 'FETCH_AGENCY_SEARCH_SUCCESS';
export const FETCH_AGENCY_SEARCH_ERROR = 'FETCH_AGENCY_SEARCH_ERROR';

export const FETCH_AGENCY_SEARCH_SCROLL = 'FETCH_AGENCY_SEARCH_SCROLL';
export const FETCH_AGENCY_SEARCH_SCROLL_START = 'FETCH_AGENCY_SEARCH_SCROLL_START';
export const FETCH_AGENCY_SEARCH_SCROLL_SUCCESS = 'FETCH_AGENCY_SEARCH_SCROLL_SUCCESS';
export const FETCH_AGENCY_SEARCH_SCROLL_ERROR = 'FETCH_AGENCY_SEARCH_SCROLL_ERROR';

export const FETCH_AGENCY_SEARCH_FILTERS = 'FETCH_AGENCY_SEARCH_FILTERS';
export const FETCH_AGENCY_SEARCH_FILTERS_START = 'FETCH_AGENCY_SEARCH_FILTERS_START';
export const FETCH_AGENCY_SEARCH_FILTERS_SUCCESS = 'FETCH_AGENCY_SEARCH_FILTERS_SUCCESS';
export const FETCH_AGENCY_SEARCH_FILTERS_ERROR = 'FETCH_AGENCY_SEARCH_FILTERS_ERROR';

export const FETCH_AGENCY_SEARCH_AVAILABLE_RESULTS = 'FETCH_AGENCY_SEARCH_AVAILABLE_RESULTS';
export const FETCH_AGENCY_SEARCH_AVAILABLE_RESULTS_START = 'FETCH_AGENCY_SEARCH_AVAILABLE_RESULTS_START';
export const FETCH_AGENCY_SEARCH_AVAILABLE_RESULTS_SUCCESS = 'FETCH_AGENCY_SEARCH_AVAILABLE_RESULTS_SUCCESS';
export const FETCH_AGENCY_SEARCH_AVAILABLE_RESULTS_ERROR = 'FETCH_AGENCY_SEARCH_AVAILABLE_RESULTS_ERROR';

export const UPDATE_SEARCH_PAGINATOR = 'UPDATE_SEARCH_PAGINATOR';
export const UPDATE_SEARCH_PAGINATOR_START = 'UPDATE_SEARCH_PAGINATOR_START';
export const UPDATE_SEARCH_PAGINATOR_SUCCESS = 'UPDATE_SEARCH_PAGINATOR_SUCCESS';
export const UPDATE_SEARCH_PAGINATOR_ERROR = 'UPDATE_SEARCH_PAGINATOR_ERROR';

const completeParams = (params, action) => {
  const { location } = typeof window !== 'undefined' ? window : {};

  if (!('uri' in params) && location) {
    const { pathname = '', search = '' } = location;
    params.uri = `${pathname}${search}`;
    console.log('uri not found in action: ', action, 'added:', params.uri);
  }

  return params;
};

const fetchFn = (fetch: Function, verb: string, params: Object, action: string) => fetch('/portal-srp/api/v1/search', {
  method: verb,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Connection: 'keep-alive',
  },
  body: JSON.stringify(completeParams(params, action)),
  credentials: 'same-origin',
});

const fetchAgencyFn = (fetch: Function, verb: string, params: Object, action: string) => fetch('/portal-srp/api/v1/agencySearch', {
  method: verb,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Connection: 'keep-alive',
  },
  body: JSON.stringify(completeParams(params, action)),
  credentials: 'same-origin',
});

const fetchSearchFiltersFn = (fetch: Function, verb: string, params: Object, action: string) => fetch('/portal-srp/api/v1/search', {
  method: verb,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Connection: 'keep-alive',
  },
  body: JSON.stringify(completeParams(params, action)),
  credentials: 'same-origin',
});

const fetchAgencySearchFiltersFn = (fetch: Function, verb: string, params: Object, action: string) => fetch('/portal-srp/api/v1/agencySearch', {
  method: verb,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Connection: 'keep-alive',
  },
  body: JSON.stringify(completeParams(params, action)),
  credentials: 'same-origin',
});

const fetchSRPAvailableResultsFn = (fetch: Function, verb: string, params: Object, action: string) => fetch('/portal-srp/api/v1/search/availableresults', {
  method: verb,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Connection: 'keep-alive',
  },
  body: JSON.stringify(completeParams(params, action)),
  credentials: 'same-origin',
});

const fetchSRPAgencyAvailableResultsFn = (fetch: Function, verb: string, params: Object, action: string) => fetch('/portal-srp/api/v1/agencySearch/availableresults', {
  method: verb,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Connection: 'keep-alive',
  },
  body: JSON.stringify(completeParams(params, action)),
  credentials: 'same-origin',
});

const fetchPaginatorData = (fetch, verb, params) => fetch('/portal-srp/api/v1/apireq/getPaginatorData', {
  method: verb,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Connection: 'keep-alive',
  },
  body: JSON.stringify(params),
  credentials: 'same-origin',
});

export function fetchAgencySearch(params: Object) {
  return ({ fetch }: { fetch: Function }) => ({
    type: FETCH_AGENCY_SEARCH,
    payload: {
      search: 'something',
      promise: fetchAgencyFn(fetch, 'POST', params, FETCH_AGENCY_SEARCH)
        .then(response => response.json())
        .catch(err => console.error('error', err)),
    },
  });
}

export function fetchSearch(params: Object, customParams: Object) {
  const { searchType = null } = customParams || {};
  switch (searchType) {
    case 'agency':
      return fetchAgencySearch(params);
    default:
      return ({ fetch }: { fetch: Function }) => ({
        type: FETCH_SEARCH,
        payload: {
          search: 'something',
          promise: fetchFn(fetch, 'POST', params, FETCH_SEARCH)
            .then(response => response.json())
            .catch(err => console.error('error', err)),
        },
      });
  }
}

export function fetchListingOnScroll(params: Object) {
  return ({ fetch }: { fetch: Function }) => ({
    type: FETCH_SEARCH_SCROLL,
    payload: {
      search: 'something',
      promise: fetchFn(fetch, 'POST', params, FETCH_SEARCH_SCROLL)
        .then(response => response.json())
        .catch(err => console.log('error', err)),
    },
  });
}

export function fetchAgencyListingOnScroll(params: Object) {
  return ({ fetch }: { fetch: Function }) => ({
    type: FETCH_AGENCY_SEARCH_SCROLL,
    payload: {
      search: 'something',
      promise: fetchAgencyFn(fetch, 'POST', params, FETCH_AGENCY_SEARCH_SCROLL)
        .then(response => response.json())
        .catch(err => console.log('error', err)),
    },
  });
}

export function fetchListingOnGeoShape(params: Object) {
  // console.log("fetchListingOnGeoShape params");
  // PIO TODO MOVE SIZE console.log(params);
  params.apireq.size = 150;
  return ({ fetch }: { fetch: Function }) => ({
    type: FETCH_SEARCH_GEO,
    payload: {
      search: 'something',
      promise: fetchFn(fetch, 'POST', params, FETCH_SEARCH_GEO)
        .then(response => response.json())
        .catch(err => console.log('error', err)),
    },
  });
}

export function fetchListingOnGeoBounds(params: Object) {

  delete params.queryFilters.price_max;
  delete params.queryFilters.price_min;
  delete params.queryFilters.srf_max;
  delete params.queryFilters.srf_min;

  const precision = `maps@${params.apireq.precision}.100`;
  params.apireq.size = 1;

  params.apireq.aggregate = [precision];
  params.apireq.precision;

  return ({ fetch }: { fetch: Function }) => ({
    type: FETCH_SEARCH_GEO_BOUNDS,
    payload: {
      search: 'something',
      promise: fetchFn(fetch, 'POST', params, FETCH_SEARCH_GEO_BOUNDS)
        .then(response => response.json())
        .catch(err => console.log('error', err)),
    },
  });
}

export function fetchListingOnFiltersChange(params: Object) {
  return ({ fetch }: { fetch: Function }) => ({
    type: FETCH_SEARCH_FILTERS,
    payload: {
      search: 'something',
      promise: fetchSearchFiltersFn(fetch, 'POST', params, FETCH_SEARCH_FILTERS)
        .then(response => response.json())
        .catch(err => console.log('error', err)),
    },
  });
}

export function fetchSRPAvailableResults(params: Object) {
  return ({ fetch }: { fetch: Function }) => ({
    type: FETCH_SEARCH_AVAILABLE_RESULTS,
    payload: {
      search: 'something',
      promise: fetchSRPAvailableResultsFn(fetch, 'POST', params, FETCH_SEARCH_AVAILABLE_RESULTS)
        .then(response => response.json())
        .catch(err => console.log('error', err)),
    },
  });
}

export function fetchSRPAgencyAvailableResults(params: Object) {
  return ({ fetch }: { fetch: Function }) => ({
    type: FETCH_SEARCH_AVAILABLE_RESULTS,
    payload: {
      search: 'something',
      promise: fetchSRPAgencyAvailableResultsFn(fetch, 'POST', params, FETCH_AGENCY_SEARCH_AVAILABLE_RESULTS)
        .then(response => response.json())
        .catch(err => console.log('error', err)),
    },
  });
}


export function fetchAgencyListingOnFiltersChange(params: Object) {
  return ({ fetch }: { fetch: Function }) => ({
    type: FETCH_AGENCY_SEARCH_FILTERS,
    payload: {
      search: 'something',
      promise: fetchAgencySearchFiltersFn(fetch, 'POST', params, FETCH_AGENCY_SEARCH_FILTERS)
        .then(response => response.json())
        .catch(err => console.log('error', err)),
    },
  });
}

export function updateSearchPaginator(params) {
  return ({ fetch }) => ({
    type: UPDATE_SEARCH_PAGINATOR,
    payload: {
      promise: fetchPaginatorData(fetch, 'POST', params, UPDATE_SEARCH_PAGINATOR)
        .then((response) => {
          const formattedResponse = response.json();
          return formattedResponse;
        })
        .catch(err => console.log('error', err)),
    },
  });
}
