// @flow
import React from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import GoogleOneTapComponent from 'shared-components/components/GoogleOneTapComponent';

import Container from './Container';

const App = ({
  children,
  device,
  currentLocale,
  store,
  config,
  modal,
  modalType,
  modalData,
  user,
  userLogIn,
  userLogOut,
  pageData,
  pageType,
  globalState,
  updateGlobalState,
  appReducer,
  globalReducer,
  globalSelector,
  trackEvents,
  viewport,
  mobileOs,
  notFoundPage,
  orientation,
  iosLth12,
  isAuthenticated,
  userIsChecked,
  waitingCMP,
  showConsentModal,
  browserBackButton,
}) => {
  let allMetatags = [];

  const metatags = [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1, shrink-to-fit=no' },
    { 'http-equiv': 'x-ua-compatible', content: 'ie=edge' },
  ];
  if (notFoundPage) {
    metatags.push({ name: 'robots', content: 'All' });
  }
  if (pageData && 'ogData' in pageData && pageData.ogData) {
    allMetatags = metatags.concat(pageData.ogData);
  } else {
    allMetatags = metatags;
  }
  const showOneTapLogin = ['homepage', 'srp'].includes(pageType);
  const {
    api: {
      account,
    },
    googleOneTap,
  } = config;

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: currentLocale }}
        meta={allMetatags}
      />
      {!waitingCMP && showOneTapLogin && (
        <GoogleOneTapComponent
          dataClienId={googleOneTap.id}
          dataLoginUri={`${account}/client-account/api/v1/social`}
          loginMethod={userLogIn}
          display={userIsChecked && !isAuthenticated}
          isHp={pageType === 'homepage'}
        />
      )}      
      <Container
        store={store}
        config={config}
        modal={modal}
        device={device}
        modalType={modalType}
        modalData={modalData}
        user={user}
        userLogIn={userLogIn}
        userLogOut={userLogOut}
        pageData={pageData}
        pageType={pageType}
        globalState={globalState}
        updateGlobalState={updateGlobalState}
        globalReducer={globalReducer}
        globalSelector={globalSelector}
        appReducer={appReducer}
        trackEvents={trackEvents}
        viewportWidth={viewport.width}
        viewportHeight={viewport.height}
        mobileOs={mobileOs}
        orientation={orientation}
        iosLth12={iosLth12}
        browserBackButton={browserBackButton}
        showConsentModal={showConsentModal}
      >
        {children}
      </Container>
    </>
  );
};

export default connect((state, store) => ({
  currentLocale: state.intl.currentLocale,
  messages: state.intl.messages,
  initialNow: state.intl.initialNow,
  favicon: state.config.favicon,
  store,
}))(App);
