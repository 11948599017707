import getAddress from './getAddress';

export default ({ core, geocoding, action }) => {
  const { LatLng } = core || {};
  const { Geocoder } = geocoding || {};

  const success = async (position) => {
    const { coords: { latitude, longitude } } = position;
    if (LatLng && Geocoder) {
      const service = new Geocoder();
      const userLocation = new LatLng({ lat: latitude, lng: longitude });
      const data = await service.geocode({ location: new LatLng(userLocation) });
      const { results } = data;
      const [place] = results;
      const { address_components, geometry: { location, viewport } } = place;
      const address = getAddress(address_components);
      action({
        address,
        location,
        viewport,
      });
    } else {
      action({ latitude, longitude });
    }
  };

  const error = e => action({ error: e });

  if (!navigator.geolocation) {
    console.log('no hay localization');
  } else {
    navigator.geolocation.getCurrentPosition(success, error);
  }
};
