/* eslint-disable disable-line */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
import request from 'superagent';

import parseJSON from 'shared-utils/src/parseJSON';
import getShouldForceSynch from 'shared-utils/src/helpersMyCasa/shouldForceSynch';

import BOOKMARKED_SEARCHES_LS from 'shared-constants/src/bookmarksLSRecord';
import BOOKMARKED_ADS_LS from 'shared-constants/src/savedAdsLSRecord';
import SYNCH_WITH_MYCASA from 'shared-constants/src/synchWithMycasa';
import LAST_SYNCH_TS from 'shared-constants/src/lastSynchTimestamp';

import { getItem, removeItem, setItem } from './UserHelpers';

export const SEARCH_HISTRORY = 'history';
export const SEARCH_BOOKMARK = 'bookmark';

export const defaultBookmarkData = {
  searchId: null,
  name: null,
  alerts: {
    mail: null,
    push: null,
  },
};

const castingBoolean = ({ val }) => {
  let p = val;
  try {
    p = !!JSON.parse(val);
  } catch (error) {
    // miss
  }
  return p;
};

export const computeSaveSearch = async (
  user,
  domain,
  searchType,
  mapUrl,
  filters,
  rLocalitions,
  pLocalitions,
  opts = {},
) => {
  // console.log('*** user', user);
  // console.log('*** searchType', searchType);
  // console.log('');
  // console.log('*** props');
  // console.log({
  //   user,
  //   domain,
  //   searchType,
  //   mapUrl,
  //   filters,
  //   rLocalitions,
  //   pLocalitions,
  //   opts,
  // });
  // console.log('');

  const { domainForExternal = '', activeNearby = null } = opts;

  const channelDecode = {
    vendita: 'buy',
    affitti: 'rent',
    affitto: 'rent',
    buy: 'buy',
    rent: 'rent',
  };

  const domainDecode = {
    'casa.it': 'casa.it',
    'attico.it': 'attico.it',
  };

  // TODO: only for backward compatibility with srp client.portal and app
  const boundingBoxSearchDecode = () => {
    if (!filters.geobounds) {
      return null;
    }
    const { bbox } = typeof filters.geobounds === 'string' ? JSON.parse(filters.geobounds) : filters.geobounds;
    return { boundingBoxSearch: [bbox[0][0], bbox[0][1], bbox[1][0], bbox[1][1]] };
  };

  // TODO: only for backward compatibility with srp client.portal and app
  const radialSearchDecode = () => {
    if (!filters.geocircle) {
      return null;
    }
    const circle = (typeof filters.geocircle === 'string' ? JSON.parse(filters.geocircle) : filters.geocircle).circle[0];
    return { radialSearch: { center: circle.center } };
  };

  // TODO: only for backward compatibility with srp client.portal and app
  const polygonSearchDecode = () => {
    if (!filters.geopolygon) {
      return null;
    }
    const { polygon } = typeof filters.geopolygon === 'string' ? JSON.parse(filters.geopolygon) : filters.geopolygon;
    const geopolygon = [];
    polygon.map(p => geopolygon.push([p[1], p[0]]));
    return { polygonSearch: geopolygon };
  };

  const getGeoCircle = () => {
    try {
      if ('geocircle' in filters) {
        if (typeof filters.geocircle === 'string') {
          filters.geocircle = JSON.parse(filters.geocircle);
        }
        return { geocircle: { circle: [{ center: filters.geocircle.circle[0].center, distance: filters.geocircle.circle[0].distance }] } };
      }
      return null;
    } catch (err) {
      return null;
    }
  };

  // TODO: only for backward compatibility with srp client.portal and app
  const surroundingDecode = () => {
    if ('includeSurrounding' in filters) { return { includeSurrounding: filters.includeSurrounding }; } if ('surrounding' in filters) { return { includeSurrounding: filters.surrounding }; }
    return null;
  };

  const computeName = () => {
    const defaultTitle = 'Ovunque (tutte le località)';
    if (activeNearby) {
      return activeNearby.name;
    }
    const isMapResearch = (boundingBoxSearchDecode() || radialSearchDecode() || polygonSearchDecode() || getGeoCircle()) && true;
    if (isMapResearch) {
      return 'Ricerca su Mappa';
    }

    const formatLocalition = (el) => {
      let text = el.name;
      if (el.levels) {
        switch (el.level) {
          case 6:
            text += ' (provincia)';
            break;
          case 9:
            text += ` (${el.parent.province.short})`;
            break;
          case 10: // area
            text += ` - ${el.levels.L9} (${el.parent.province.short})`;
            break;
          default:
            text = el.name;
        }
      }
      return text;
    };

    return rLocalitions.length > 0 ? rLocalitions.map(el => formatLocalition(el)).join(', ') : defaultTitle;
  };

  let { garden = [] } = filters;
  const { balconyAndTerrace = [] } = filters;
  // convertApiParamForEncode(params, activeFilters, DEFAULT_FILTERS_VALUES, pageType)
  if (garden && !Array.isArray(garden)) { garden = garden.split(','); }
  if (filters.heatingType && !Array.isArray(filters.heatingType)) { filters.heatingType = filters.heatingType.split(','); }
  if (filters.sellerType && !Array.isArray(filters.sellerType)) { filters.sellerType = filters.sellerType.split(','); }
  if (filters.buildingCondition && !Array.isArray(filters.buildingCondition)) { filters.buildingCondition = filters.buildingCondition.split(','); }

  let filtersToEncode = { ...filters };

  const proxy = await request.post(`${domainForExternal}/portal-srp/api/v1/apireq/convertApiParamForEncode`)
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .withCredentials()
    .send({ filtersToEncode, locations: rLocalitions });
  const { body: { convertedFilters } } = proxy;

  filtersToEncode = convertedFilters;

  const getNearby = () => {
    if (filters.nearby) {
      if (filters.nearby.apiData) {
        return { nearby: { ...filters.nearby.apiData } };
      }
      return { nearby: filters.nearby };
    }
  };

  const pLocalitionsForMyCasaApp = [];
  if (
    pLocalitions
    && 'localities' in pLocalitions
    && rLocalitions.length > 0
  ) {
    pLocalitions.localities.forEach(
      (el, index) => {
        pLocalitionsForMyCasaApp[index] = el;
        pLocalitionsForMyCasaApp[index].type = rLocalitions[index].type;
        pLocalitionsForMyCasaApp[index].has_children = rLocalitions[index].has_children;
      },
    );
  }

  const isMapResearch = ((boundingBoxSearchDecode() && !filters.q) || radialSearchDecode() || polygonSearchDecode() || getGeoCircle()) && true;
  const isNearbyResearch = getNearby() && true;
  const maxPrice = filters.priceMax !== 'all' ? filters.priceMax : null;
  const minPrice = filters.priceMin !== 'all' ? filters.priceMin : null;
  const maxHouseSize = filters.mqMax !== 'all' ? filters.mqMax : null;
  const minHouseSize = filters.mqMin !== 'all' ? filters.mqMin : null;
  const numRooms = filters.numRoomsMin !== 'all' ? filters.numRoomsMin : null;
  const exclude_auction = filtersToEncode.exclude_auction
    ? filtersToEncode.exclude_auction
    : null;

  const isCBLValid = (cbl) => {
    delete filters.commercial_building_location;
    if (!cbl) {
      return false;
    }
    if (Array.isArray(cbl) && (cbl[0] === 'all' || !cbl[0])) {
      return false;
    }
    if (typeof cbl === 'string' && cbl === 'all') {
      return false;
    }
    return true;
  };

  const commercialBuildingLocation = isCBLValid(filtersToEncode.commercial_building_location);

  const only_auction = filtersToEncode.only_auction
    ? filtersToEncode.only_auction
    : null;
  // TODO: only for backward compatibility with srp client.portal and app

  let searchUrl = typeof mapUrl === 'string'
    ? mapUrl.replace(/\/(lista-\d)/g, '/lista-1').replace(/(page=\d&) ?|(\?page=\d$) ?|(&page=\d)/g, '')
    : '/';

  // test floorplan as first media 
  searchUrl = searchUrl.replace(/(firstMedia=floorplan&) ?|(firstMedia=floorplan&) ?|(\?firstMedia=floorplan$) ?|(&firstMedia=floorplan)/g, '');
  searchUrl = searchUrl.replace(/(fillin=open&) ?|(\?fillin=open$) ?|(&fillin=open)/g, '');

  const urlParts = searchUrl.split('?');
  const [base, qs] = urlParts;

  const hasGbounds = filters.geobounds || (qs || '').indexOf('geobounds') !== -1;

  if (qs) {
    if (hasGbounds && (
      filters.geopolygon
      || filters.geocircle
      || filters.nearby
      || filters.q
    )) {
      const qsParts = qs.split('&');
      const validParts = qsParts.filter(p => p.indexOf('geobounds') < 0);
      searchUrl = `${base}?${validParts.join('&')}`;
    }
  }

  try {
    searchUrl = decodeURIComponent(searchUrl);
  } catch (error) {
    console.log('Error decode uri component :', mapUrl);
  }

  const mapFiltersToRemoveIfNull = [
    'geocircle',
    'geobounds',
    'geopolygon',
    'nearby',
  ];
  Object.keys(filters).forEach(
    (fil) => {
      if (mapFiltersToRemoveIfNull.includes(fil) && filters[fil] === null) {
        delete filters[fil];
      }
    },
  );

  const ptypes = filtersToEncode.propertyTypes.map((item) => {
    if (item) {
      const ptlc = item
        .toLowerCase()
        .replace(/[àáâãäå]/g, 'a')
        .replace(/\//g, '+')
        .replace(/ /g, '+')
        // temp fix for serialize
        .replace('(con+o+senza+mura)', 'commerciale');
      return ptlc;
    }
    return item;
  });


  const reqBody = {
    user,
    searchData: {
      name: computeName(),
      searchType,
      encodedProperties: Object.assign(
        {},
        filters,
        { buildingCondition: filtersToEncode.buildingCondition },
        { agentCategory: filters.sellerType },
        { propertyTypeGroup: filtersToEncode.propertyTypeGroup },
        { channel: channelDecode[filtersToEncode.tr] },
        { propertyType: ptypes.filter(Boolean) },
        pLocalitions && 'resolvedLocations' in pLocalitions && !isMapResearch && !isNearbyResearch ? { resolvedLocations: pLocalitions.resolvedLocations } : null,
        pLocalitions && 'resolvedLocationCodes' in pLocalitions && !isMapResearch && !isNearbyResearch ? { resolvedLocationCodes: pLocalitions.resolvedLocationCodes } : null,
        pLocalitions && 'localities' in pLocalitions && !isMapResearch && !isNearbyResearch ? { localities: pLocalitionsForMyCasaApp } : null,
        { searchUrl },
        { domain: domainDecode[filters.domain] || 'casa.it' },
        { onlyWithPhoto: castingBoolean({ val: filters.photo }) },
        commercialBuildingLocation
          ? { commercial_building_location: !Array.isArray(filtersToEncode.commercial_building_location) ? (filtersToEncode.commercial_building_location || '').split(',') : filtersToEncode.commercial_building_location }
          : null,
        exclude_auction ? { exclude_auction: true } : null,
        only_auction ? { only_auction: true } : null,
        // { searchView: 'map' },
        maxPrice ? { maxPrice } : null,
        minPrice ? { minPrice } : null,
        maxHouseSize ? { maxHouseSize } : null,
        minHouseSize ? { minHouseSize } : null,
        numRooms ? { numRooms } : null,
        filtersToEncode.license_type_groups ? { license_type_groups: filtersToEncode.license_type_groups.map(item => item ? item.toLowerCase().replace(/[àáâãäå]/g, 'a').replace(/\//g, '-').replace(/ /g, '-') : '').filter(Boolean) } : null,
        filters.sortType
          ? { activeSort: filters.sortType.replace(/_/g, '-') }
          : null,
        {
          exteriorFeatures: [
            balconyAndTerrace.indexOf('balcone') > -1 ? 'balcone' : null,
            balconyAndTerrace.indexOf('terrazzo') > -1 ? 'terrazzo' : null,
            garden.length > 0 && garden[0] !== 'all' ? `giardino-${garden[0]}` : null,
            garden.length > 1 ? `giardino-${garden[1]}` : null,
          ].filter(Boolean),
        },
        {
          interiorFeatures: [
            'heatingType' in filters && filters.heatingType[0] !== 'all' ? `riscaldamento-${filters.heatingType[0]}` : null,
            'heatingType' in filters && filters.heatingType[1] ? `riscaldamento-${filters.heatingType[1]}` : null,
            filters.lift !== 'all' && filters.lift !== 'false' ? 'ascensore' : null,
          ].filter(Boolean),
        },
        surroundingDecode(),
        getGeoCircle(),
        getNearby(),
      ),
    },
  };
  const ep = reqBody.searchData.encodedProperties;

  /* FILTERS BOOLEAN TO INCLUDE IF THEY ARE TRUE OR DELETE THEM  */
  const booleanToEvaluate = [
    'exclude_auction',
    'is_auction',
    'exclude_under_construction',
    'has_swimming_pool',
    'has_reception',
    'exclude_private_negotiation',
    'only_private_negotiation',
    'has_virtual_tour',
    'air_conditioned',
    'is_lux',
  ];
  booleanToEvaluate.map((k) => { !ep[k] ? delete reqBody.searchData.encodedProperties[k] : reqBody.searchData.encodedProperties[k] = true; });

  /* FILTERS ARRAY TO INCLUDE IF EXISTS SOME VALUE OR DELETE THEM  */
  const filtersArrayToReomveIfAll = ['furniture'];
  filtersArrayToReomveIfAll.map((k) => {
    (!ep[k] || (ep[k] && ep[k].length === 1 && ep[k].includes('all'))) ?
      delete reqBody.searchData.encodedProperties[k] :
      ep[k] && !Array.isArray(ep[k]) ? reqBody.searchData.encodedProperties[k] = ep[k].split(',') : null;
  });

  const filtersStringToRemoveIfAll = ['publication_date'];
  filtersStringToRemoveIfAll.map(k => (!ep[k] || ep[k] === 'all') && delete reqBody.searchData.encodedProperties[k]);

  /* DELETE EMAIL ALERT QS */
  delete reqBody.searchData.encodedProperties.utm_campaign;
  delete reqBody.searchData.encodedProperties.utm_medium;
  delete reqBody.searchData.encodedProperties.utm_source;
  delete reqBody.searchData.encodedProperties.utm_content;
  delete reqBody.searchData.encodedProperties.utm_term;

  /* NEW FILTERS TO DELETE */
  delete reqBody.searchData.encodedProperties.tr;
  delete reqBody.searchData.encodedProperties.page;
  delete reqBody.searchData.encodedProperties.q;
  delete reqBody.searchData.encodedProperties.precision;

  /* DELETE FILTERS */
  delete reqBody.searchData.encodedProperties.category;
  delete reqBody.searchData.encodedProperties.sellerType;
  delete reqBody.searchData.encodedProperties.zones;
  delete reqBody.searchData.encodedProperties.propertyTypes;
  delete reqBody.searchData.encodedProperties.photo;
  delete reqBody.searchData.encodedProperties.priceMax;
  delete reqBody.searchData.encodedProperties.priceMin;
  delete reqBody.searchData.encodedProperties.mqMax;
  delete reqBody.searchData.encodedProperties.mqMin;
  delete reqBody.searchData.encodedProperties.numRoomsMin;
  delete reqBody.searchData.encodedProperties.balcony;
  delete reqBody.searchData.encodedProperties.terrace;
  delete reqBody.searchData.encodedProperties.balconyAndTerrace;
  delete reqBody.searchData.encodedProperties.garden;
  // delete reqBody.searchData.encodedProperties.lift;
  delete reqBody.searchData.encodedProperties.heatingType;
  delete reqBody.searchData.encodedProperties.surrounding;
  delete reqBody.searchData.encodedProperties.zone;
  delete reqBody.searchData.encodedProperties.sortType;
  delete reqBody.searchData.encodedProperties.newBuilding;
  delete reqBody.searchData.encodedProperties.firstMedia;
  delete reqBody.searchData.encodedProperties.is_auction;

  if (filters.priceType === 'price') {
    delete reqBody.searchData.encodedProperties.priceType;
  }

  if (reqBody.searchData.encodedProperties.geobounds && (
    reqBody.searchData.encodedProperties.geopolygon
    || reqBody.searchData.encodedProperties.geocircle
    || reqBody.searchData.encodedProperties.nearby
    || filters.q
  )) {
    delete reqBody.searchData.encodedProperties.geobounds;
  }

  // console.log(JSON.stringify(reqBody.searchData.encodedProperties));
  
  return reqBody;
};

export const createSearchAlert = (alertType = 'mail', alertFrequency = 'never') => {
  const alerts = [];
  if (alertFrequency === 'never') {
    return alerts;
  }
  alerts.push({
    searchAlertId: 0,
    alertType,
    alertFrequency,
  });

  return alerts;
};

export const getSearchAlert = (enabled, frequency, type) => enabled ? createSearchAlert(type, frequency) : [];

export const callSaveSearch = (body, next = null) => {
  const uri = '/portal-srp/api/v1/mycasa/searches/upsert';
  request
    .post(uri)
    .set('Accept', 'application/json')
    .send(body)
    .withCredentials()
    .end((err, resApi) => {
      try {
        const res = JSON.parse(resApi.text);
        if (err || res.statusCode !== 200) {
          if (next) { return next({ error: true }); }
          return { error: true };
        }
        if (next) { return next({ error: false, searchId: res.data }); }
        return { error: false, searchId: res.data };
      } catch (ex) {
        if (next) { return next({ error: true }); }
        return { error: true };
      }
    });
};

export const callCreateSerialize = (body, next = null) => {
  const uri = '/portal-srp/api/v1/mycasa/searches/serialize';
  return new Promise((resolve) => {
    request
      .post(uri)
      .set('Accept', 'application/json')
      .send(body)
      .withCredentials()
      .end((err, resApi) => {
        try {
          const res = JSON.parse(resApi.text);
          if (err || res.statusCode !== 200) {
            if (next) { resolve(next({ error: true })); }
            resolve({ error: true });
          }
          const resp = JSON.parse(res.data);
          if (next) { resolve(next({ error: false, serialize: resp.data.serialize })); }
          resolve({ error: false, serialize: resp.data.serialize });
        } catch (ex) {
          if (next) { resolve(next({ error: true })); }
          resolve({ error: true });
        }
      });
  });
};

export const getSerializedData = async (data) => {
  const res = await callCreateSerialize(data);
  const s = res.serialize || {};
  return {
    str: s.str,
    hash: s.hash,
  };

};

export const getSearches = async (body) => {
  const uri = '/portal-srp/api/v1/mycasa/searches/get';
  const searches = await request
    .post(uri)
    .set('Accept', 'application/json')
    .send(body)
    .withCredentials();

  if (searches.error) {
    return {
      error: true,
    };
  }
  return parseJSON(searches.text);
};

export const callDeleteSearch = (body, deleteCallback) => {
  const uri = '/portal-srp/api/v1/mycasa/searches/deleteBoomark';
  request
    .post(uri)
    .set('Accept', 'application/json')
    .send(body)
    .withCredentials()
    .end((err, resApi) => {
      const data = {};
      if (err) {
        data.error = true;
      } else {
        const res = JSON.parse(resApi.text);
        if (res.response.error) {
          data.error = true;
        } else {
          data.error = false;
        }
      }
      return deleteCallback(data);
    });
};

export const callUpdateSearch = (body, updateCallBack) => {
  const uri = '/portal-srp/api/v1/mycasa/searches/updateBoomark';
  request
    .post(uri)
    .set('Accept', 'application/json')
    .send(body)
    .withCredentials()
    .end((err, resApi) => {
      const data = {};
      if (err) {
        data.error = true;
      } else {
        const res = JSON.parse(resApi.text);
        if (res.response.error) {
          data.error = true;
        } else {
          data.error = false;
          data.response = res.response.data;
        }
      }
      return updateCallBack(data);
    });
};

export const getBookmarkAlertData = (body, bodySearchBKM, searchHash, bookmarks, next) => {
  const uri = '/portal-srp/api/v1/mycasa/searches/getBookmarkData';
  request
    .post(uri)
    .set('Accept', 'application/json')
    .send(body)
    .withCredentials()
    .end((err, resApi) => {
      const bookmarkData = JSON.parse(JSON.stringify(defaultBookmarkData));
      try {
        const res = JSON.parse(resApi.text);
        if (err || res.statusCode !== 200) {
          return next(true, bookmarkData, bodySearchBKM, searchHash, bookmarks);
        }
        const {
          searchId,
          name,
          searchAlerts,
        } = res.data.searches[0];
        bookmarkData.searchId = searchId;
        bookmarkData.name = name;
        if (searchAlerts.length > 0) {
          const hasMail = searchAlerts.filter(x => x.alertType === 'mail')[0] || null;
          const hasPush = searchAlerts.filter(x => x.alertType === 'push')[0] || null;
          bookmarkData.alerts = [hasMail, hasPush];

          const dataLs = parseJSON(getItem(BOOKMARKED_SEARCHES_LS)) || {};
          const { savedsearchesData } = dataLs;
          if (savedsearchesData) {
            const current = savedsearchesData.filter(bkm => bkm.id === searchId)[0];
            current.summary.alerts = bookmarkData.alerts;
            setItem(BOOKMARKED_SEARCHES_LS, JSON.stringify(dataLs));
          }
        }
        return next(true, bookmarkData, bodySearchBKM, searchHash, bookmarks);
      } catch (ex) {
        return next(true, bookmarkData, bodySearchBKM, searchHash, bookmarks);
      }
    });
};

export const proposeBkmProvinces = () => {
  const dataLs = parseJSON(getItem(BOOKMARKED_SEARCHES_LS)) || {};
  return {
    provinces: dataLs.provinces || [],
  };
};

export const shouldProposeSaveSearchAfterLead = async (body, next) => {
  const should = await request
    .post('/portal-srp/api/v1/mycasa/proposeSaveSearchAfterLead')
    .set('Accept', 'application/json')
    .send(body)
    .withCredentials();

  return next(should.body);
};

const createBookmarkData = (bookmark) => {
  const {
    id,
    summary: {
      name,
      alerts,
    },
  } = bookmark;

  return {
    searchId: id,
    name,
    alerts,
  };
};

export const isBookmarkSearch = async (
  data,
  user,
  refreshBookmarks,
  next,
) => {
  let isBoomarked = false;
  let bookmarkData = defaultBookmarkData;

  const bodySearchBKM = JSON.parse(JSON.stringify(data));
  bodySearchBKM.searchData.searchType = SEARCH_BOOKMARK;
  bodySearchBKM.searchData.encodedProperties.userId = user.id;
  bodySearchBKM.searchData.encodedProperties.searchType = SEARCH_BOOKMARK;
  const ser = await getSerializedData(bodySearchBKM);

  if (refreshBookmarks) { // se mycasa ci dice che dobbiamo aggiornare i dati
    removeItem(BOOKMARKED_ADS_LS);
    removeItem(BOOKMARKED_SEARCHES_LS);
    removeItem(SYNCH_WITH_MYCASA);
  }

  const dataLs = parseJSON(getItem(BOOKMARKED_SEARCHES_LS)) || {};
  const savedsearchesDataLs = dataLs.savedsearchesData || null;
  let savedsearchesProvinces = dataLs.provinces || [];


  if (!savedsearchesDataLs) { // se non abbiamo il nuovo record 
    const synchSearchesRes = await getSearches({ user });
    if (synchSearchesRes.error) {
      // se la chiamata a mycasa fallisce, facciamo finta di niente
    } else {
      setItem(LAST_SYNCH_TS, Date.now());
      const { savedsearchesData, provinces } = synchSearchesRes.data;
      if (savedsearchesData.length > 0) {
        setItem(BOOKMARKED_SEARCHES_LS, JSON.stringify(synchSearchesRes.data));
      }
      const bookmarksHashList = savedsearchesData.map(bookmark => bookmark.hash);
      const currSearchIndex = bookmarksHashList.indexOf(ser.hash);
      if (currSearchIndex > -1) {
        isBoomarked = true;
        bookmarkData = createBookmarkData(savedsearchesData[currSearchIndex]);
      }
      savedsearchesProvinces = provinces || [];
    }
  } else {
    const bookmarksHashList = savedsearchesDataLs.map(bookmark => bookmark.hash);
    const currSearchIndex = bookmarksHashList.indexOf(ser.hash);
    if (currSearchIndex > -1) {
      isBoomarked = true;
      bookmarkData = createBookmarkData(savedsearchesDataLs[currSearchIndex]);
    }
  }
  return next(
    isBoomarked,
    bookmarkData,
    bodySearchBKM,
    ser.hash,
    { provinces: savedsearchesProvinces },
  );
};

export const checkSavedSearch = async (user, data, filters, isLocalStorageAvailable, saveHistory, activeNearby, next, finishCall = () => { }) => {
  if (user.isAuthenticated) {
    const bodySearchHST = await computeSaveSearch(
      user,
      data.domain,
      SEARCH_HISTRORY,
      data.uri,
      filters,
      data.resolvedLocations,
      data.parsedLocations,
      {
        isLocalStorageAvailable,
        pageType: 'checkSavedSearch',
        activeNearby,
      },
    );
    if (isLocalStorageAvailable) {
      if (saveHistory) {
        callSaveSearch(bodySearchHST);
      }
      /**
       * Sync with MyCasa
       * If a user deletes a saved search in MyCasa we set a cookie.
       * We read here the value of the cookie to know if we need to force
       * an API call to get the updated saved serches records
       * 
       * We also check if we need to called based on a timestamp set in LS on the last synch
       * 
       */
      const refreshBookmarks = getShouldForceSynch();
      isBookmarkSearch(
        bodySearchHST,
        user,
        refreshBookmarks,
        (searchIsSaved, bookmarkData, bodySearchBKM, searchHash, bookmarks) => next(searchIsSaved, bookmarkData, bodySearchBKM, searchHash, bookmarks, refreshBookmarks),
      );
    }
  } else {
    finishCall();
  }
};

export const sendGenericLead = (body, responseCallback) => {
  const uri = '/portal-srp/api/v1/mycasa/searchGeneric';
  request
    .post(uri)
    .set('Accept', 'application/json')
    .send(body)
    .withCredentials()
    .end(
      (err, apiRes) => {
        responseCallback(apiRes.statusCode);
      },
    );
};

export const sendSpecificLead = (body, responseCallback) => {
  const { leadData } = body;
  const uri = '/portal-srp/api/v1/mycasa/searchSpecific';
  request
    .post(uri)
    .set('Accept', 'application/json')
    .send(body)
    .withCredentials()
    .end(
      (err, apiRes) => {
        responseCallback({
          statusCode: apiRes.statusCode,
          user: {
            name: leadData.firstName,
            email: leadData.email,
            telephone: leadData.telephone,
            consent: leadData.consent,
            message: leadData.message,
          },
        });
      },
    );
};

export const sendAgencyLead = (body, responseCallback) => {
  const req = {};
  req.body = body;
  const uri = '/portal-srp/api/v1/mycasa/searchAgency';
  request
    .post(uri)
    .set('Accept', 'application/json')
    .send(req.body)
    .withCredentials()
    .end(
      (err, apiRes) => {
        responseCallback({
          statusCode: apiRes.statusCode,
          choiches: {
            message: body.message,
          },
        });
      },
    );
};

export const sendAgentProLead = (body, responseCallback) => {
  const req = {};
  req.body = body;
  const uri = '/portal-srp/api/v1/mycasa/searchAgency';
  request
    .post(uri)
    .set('Accept', 'application/json')
    .send(req.body)
    .withCredentials()
    .end(
      (err, apiRes) => {
        responseCallback(apiRes.statusCode);
      },
    );
};

export const saveListing = (listingType, listingId, user, responseCallback) => {
  const req = {};
  req.body = {
    listingType,
    listingId,
    user,
  };
  const uri = '/portal-srp/api/v1/mycasa/listing/upsert';
  request
    .post(uri)
    .set('Accept', 'application/json')
    .send(req.body)
    .withCredentials()
    .end((err, apiRes) => {
      responseCallback(apiRes.statusCode);
    });
};

export const callGetFavorites = async (user, refreshBookmarks, next = null) => {
  const lsFavorites = parseJSON(getItem(BOOKMARKED_ADS_LS));

  if (!lsFavorites || refreshBookmarks) {
    const uri = '/portal-srp/api/v1/mycasa/listings/get/basic';
    try {
      const res = await request
        .post(uri)
        .set('Accept', 'application/json')
        .send({ user })
        .withCredentials();

      if (res.error || res.statusCode !== 200) {
        if (next) {
          return next([]);
        }
        return [];
      }

      const resData = JSON.parse(res.text).data;
      const favorites = resData.map(el => el.listingId);
      window.localStorage.setItem(BOOKMARKED_ADS_LS, JSON.stringify({ favorites }));
      if (next) {
        return next(favorites);
      }
      return favorites;
    } catch (error) {
      console.log(`callGetFavorites error: ${JSON.stringify(error)}`);
      if (next) {
        return next([]);
      }
      return [];
    }
  } else if (next) {
    return next(lsFavorites.favorites);
  }
  return lsFavorites.favorites;
};

export const getUserSearchHistory = (user, next = null) => {
  const uri = '/portal-srp/api/v1/mycasa/history/searches';
  request
    .post(uri)
    .set('Accept', 'application/json')
    .send({ user })
    .withCredentials()
    .end((err, resApi) => {
      try {
        const res = JSON.parse(resApi.text);

        if (err || res.statusCode !== 200) {
          if (next) { return next([]); }
          return [];
        }

        if (next) { return next(res.data); }

        return res.data;
      } catch (ex) {
        console.log(`Error at getUserHiddenAds: ${JSON.stringify(ex)}`);
        if (next) { return next([]); }
        return [];
      }
    });
};

export const getUserHiddenAds = (user, next = null) => {
  const uri = '/portal-srp/api/v1/mycasa/hidden';
  request
    .post(uri)
    .set('Accept', 'application/json')
    .send({ user })
    .withCredentials()
    .end((err, resApi) => {
      try {
        const res = JSON.parse(resApi.text);
        if (err || res.statusCode !== 200) {
          if (next) { return next([]); }
          return [];
        }
        const discarded = res.data.data.map(hidden => hidden.listingId);
        if (next) {
          return next(discarded);
        }
        return discarded;
      } catch (ex) {
        console.log(`Error at getUserHiddenAds: ${JSON.stringify(ex)}`);
        if (next) { return next([]); }
        return [];
      }
    });
};

export const getSearchHiddenAds = (userDiscardedAds, apireq, locations, next) => {
  // if the user has no, discarded ads, just go away
  if (userDiscardedAds.length === 0) {
    if (next) {
      return next(0);
    }
    return 0;
  }

  // check if LS record exits
  const lsClosedList = getItem('__hidddenAdsFeedbackClosed');
  const closedList = parseJSON(lsClosedList) || {};

  const payload = {
    userDiscardedAds,
    apireq,
    locations,
    closedList,
  };

  const uri = '/portal-srp/api/v1/mycasa/hiddentotal';
  request
    .post(uri)
    .set('Accept', 'application/json')
    .send(payload)
    .withCredentials()
    .end((err, resApi) => {
      try {
        if (err) {
          if (next) {
            return next(0);
          }
          return 0;
        }
        const res = JSON.parse(resApi.text);
        const {
          hiddenTotal,
          newClosedList,
        } = res;
        if (Object.keys(newClosedList).length > 0) {
          setItem('__hidddenAdsFeedbackClosed', JSON.stringify(newClosedList));
        } else {
          removeItem('__hidddenAdsFeedbackClosed');
        }
        if (next) {
          return next(hiddenTotal);
        }
        return hiddenTotal;
      } catch (error) {
        console.log(`Error at getSearchHiddenAds: ${JSON.stringify(error)}`);
        if (next) {
          return next(0);
        }
        return 0;
      }
    });
};
