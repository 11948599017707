import { canUseDOM } from '../../components';

export const FETCH_QRCODE_PARAMS_CLIENT = 'FETCH_QRCODE_PARAMS_CLIENT';
export const FETCH_QRCODE_PARAMS = 'FETCH_QRCODE_PARAMS';
export const FETCH_QRCODE_PARAMS_START = 'FETCH_QRCODE_PARAMS_START';
export const FETCH_QRCODE_PARAMS_SUCCESS = 'FETCH_QRCODE_PARAMS_SUCCESS';
export const FETCH_QRCODE_PARAMS_ERROR = 'FETCH_QRCODE_PARAMS_ERROR';

const fetchFn = (fetch, url, verb, params) => fetch(url, {
  method: verb,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Connection: 'keep-alive',
  },
  body: JSON.stringify(params),
  credentials: 'same-origin',
});

export function fetchQrcodeParams(params) {
  if (canUseDOM) {
    return () => ({
      type: FETCH_QRCODE_PARAMS_CLIENT,
    });
  }
  const url = '/portal-srp/api/v1/qrcode/getparams';
  const verb = 'POST';
  const body = {
    site: params.apireq.site, 
    domain: params.domain, 
    locale: params.locale, 
    apireq: params.apireq, 
    baseUri: params.uri,
  };
  return ({ fetch }) => ({
    type: FETCH_QRCODE_PARAMS,
    payload: {
      promise: fetchFn(fetch, url, verb, body)
        .then(response => response.json())
        .catch(err => console.error('Error get qrcode params', err)),
    },
  });
}
