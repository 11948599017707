export const categoryFilter = [
  {
    label: 'Casa',
    ctaLabel: 'Case',
    ctaNoResultLabel: 'Nessuna casa',
    slug: 'case',
  },
  {
    label: 'Nuova costruzione',
    ctaLabel: 'Nuove costruzioni',
    ctaNoResultLabel: 'Nessuna nuova costruzione',
    slug: 'nuove-costruzioni',
  },
  {
    label: 'Stanza',
    ctaLabel: 'Stanza',
    ctaNoResultLabel: 'Nessuna stana',
    slug: 'stanze',
  },
  {
    label: 'Garage o posto auto',
    ctaLabel: 'Garage e posti auto',
    ctaNoResultLabel: 'Nessun garage o posto auto',
    slug: 'garage-posti-auto',
  },
  {
    label: 'Cantina o solaio',
    ctaLabel: 'Cantine e solai',
    ctaNoResultLabel: 'Nessuna cantina o solaio',
    slug: 'cantine-solai',
  },
  {
    label: 'Edificio/palazzo',
    ctaLabel: 'Edifici e palazzi',
    ctaNoResultLabel: 'Nessun edificio o palazzo',
    slug: 'edifici-palazzi',
  },
  {
    label: 'Ufficio',
    ctaLabel: 'Uffici',
    ctaNoResultLabel: 'Nessun ufficio',
    slug: 'uffici',
  },  
  {
    label: 'Immobile o Attività  commerciale',
    ctaLabel: 'Immobili e Attività commerciali',
    ctaNoResultLabel: 'Nessun immobile o attività commerciale',
    slug: 'commerciale',
  },
  {
    label: 'Capannone',
    ctaLabel: 'Capannoni',
    ctaNoResultLabel: 'Nessun capannone',
    slug: 'capannoni',
  },
  {
    label: 'Terreno',
    ctaLabel: 'Terreni',
    ctaNoResultLabel: 'Nessun terreno',
    slug: 'terreni',
  },
];

export const channelFilter = [
  {
    label: 'Vendita',
    slug: 'vendita',
  },
  {
    label: 'Affitto',
    slug: 'affitto',
  },
  {
    label: 'Affitto breve',
    slug: 'affitto-breve',
  },
];

export const agzChannelFilter = [
  {
    label: 'Vendere',
    slug: 'trova',
  },
  {
    label: 'Comprare',
    slug: 'vendita',
  },
  {
    label: 'Affittare',
    slug: 'affitto',
  },
];

export const POIFilter = {
  underground: 'Fermata della Metro',
  public_transportation: 'Fermate Bus / Tram / Treni',
  nurseries: 'Asilo nido',
  schools: 'Scuole e Università',
  supermarkets: 'Supermercati',
  parks: 'Parchi pubblici',
  gyms: 'Palestre',
};

export const casaForYouContentList = [
  {
    id: 'valuta-immobile',
    svgname: '/portal-srp/assets/img/home/foryou_valuta_not_new.svg',
    imgclassname: 'figure valuta',
    title: 'Valuta la tua casa',
    desc: 'Vuoi sapere quanto vale la tua casa? Casa.it ti fornisce gratuitamente una stima del valore del tuo immobile.',
    url: '/valutazione-immobile/',
    cta: 'Scoprilo subito',
  },
  {
    id: 'trova-agenzia',
    svgname: 'https://www.casa.it/external-resources/landingImgs/ill_vendicasa.svg',
    imgclassname: 'figure pubblica',
    title: 'Vendi tramite agenzia',
    desc: 'Trova le migliori agenzie per vendere casa. Risparmia tempo e vendi al meglio il tuo immobile',
    url: '/trova-agenzia/',
    cta: 'Trova agenzia',
  },
  // {
  //   id: 'pubblica-annunci',
  //   svgname: '/portal-srp/assets/img/home/foryou_pubblica.svg',
  //   imgclassname: 'figure pubblica',
  //   title: 'Pubblica il tuo annuncio',
  //   desc: 'Con oltre un milione di annunci online, Casa.it è il posto migliore dove pubblicare l\'annuncio del tuo immobile.',
  //   cta: 'Pubblica annuncio',
  // },
  {
    id: 'spot',
    svgname: '/portal-srp/assets/img/home/spot-2024.svg',
    imgclassname: 'figure spot',
    title: 'La casa che cerchi',
    desc: 'Il nuovo spot TV mostra una famiglia alla ricerca della casa giusta in cui tutti hanno una richiesta, anche il gatto.',
    url: 'https://blog.casa.it/2024/05/19/la-casa-che-cerchi/',
    cta: 'Guarda lo spot',
  },
  // {
  //   id: 'foryou-prima-vista',
  //   svgname: '/portal-srp/assets/img/home/prima_visita_190@3x.png',
  //   imgclassname: 'figure prima-vista',
  //   title: 'Visita Guidata da Casa',
  //   desc: 'Puoi visitare molti immobili comodamente da casa tua con',
  //   descImg: '/portal-srp/assets/img/logo-prima-visita.svg',
  //   descImgTitle: 'Prima vista',
  //   url: 'https://www.casa.it/casait/primavisita/',
  //   cta: 'Scopri come',
  // },
  // {
  //   id: 'foryou-survey',
  //   svgname: '/portal-srp/assets/img/home/HP-Survey-mar2022.svg',
  //   imgclassname: 'figure',
  //   title: 'Come vorresti Casa.it?',
  //   desc: 'Raccontaci la tua esperienza e i tuoi desideri. La tua opinione è preziosa per noi. Rispondi al questionario.',
  //   url: 'https://it.surveymonkey.com/r/6JTSHY8',
  //   cta: 'Rispondi ora',
  // },
];

export const casaPresentationContentList = [
  {
    title: 'Casa.it',
    content: 'Il portale immobiliare per trovare case in vendita e in affitto. Cerca l\'appartamento ideale tra milioni di annunci immobiliari.',
  },
  {
    title: 'Cerco casa',
    content: 'Cerca casa selezionando la citt&agrave; o la zona in cui vorresti la nuova abitazione. Abbiamo immobili che si adattano a varie tipologie di richieste: gli <a href="/vendita/appartamenti/" title="Appartamenti in vendita">appartamenti per le famiglie</a>, i <a href="/affitto/residenziale/monolocali/" title="Monolocali in affitto">monolocali per studenti</a> oppure i <a href="/affitto/residenziale/bilocali/" title="Bilocali in affitto">bilocali per giovani coppie</a>.',
  },
  {
    title: 'Tipologie',
    content: 'Puoi inoltre decidere quanti ambienti preferisci avere per il tuo appartamento; ad esempio, <a href="/vendita/residenziale/bilocali/" title="Bilocali in vendita">bilocali</a>, <a href="/vendita/residenziale/trilocali/" title="Trilocali in vendita">trilocali</a>, <a href="/vendita/residenziale/quadrilocali/" title="Quadrilocali in vendita">quadrilocali</a> e <a href="/vendita/loft/" title="Vendita loft">loft</a>. Su Casa.it trovi anche immobili commerciali in vendita, uffici, negozi e seconde case per le vacanze.',
  },
  {
    title: 'Vendita e Affitto',
    content: 'Per trovare la propriet&agrave; dei tuoi sogni ti mettiamo a disposizione <strong>immobili in vendita</strong> e <strong>immobili in affitto</strong>. Tutte le strutture da <strong>agenzie immobiliari</strong>, da <a href="/vendita/residenziale/con-da-privati/" title="Case in vendita da privati">privati</a>. Vuoi fare un investimento? Hai mai pensato a una <a href="/vendita/residenziale/da-ristrutturare/" title="Casa da ristrutturare in vendita">casa da ristrutturare</a> o a una <a href="/vendita/residenziale/in-nuove-costruzioni/" title="Nuove costruzioni in vendita">nuova costruzione</a>?',
  },
  {
    title: 'Filtra',
    content: 'Per <strong>Comprare casa</strong> filtra i risultati di ricerca per numero di locali, numero di bagni, tipo di riscaldamento, la presenza di balconi, giardino e ascensore. Vuoi una casa indipendente? Abbiamo le <a href="/vendita/ville/" title="Ville in vendita">ville</a> e le <a href="/vendita/villette-a-schiera/" title="Villetta a schiera in vendita">villette a schiera</a>.',
  },
  {
    title: 'Vendo casa',
    content: 'Hai un immobile da vendere? Con Casa.it hai la garanzia di raggiungere centinaia di migliaia di persone che cercano casa tua. Non sai a quanto vendere o affittare? <a href="/valutazione-immobile/" title="Valuta il tuo immobile">Valuta casa</a> con il nostro sito, potrai poi decidere se affidarti a un\'agenzia oppure vendere da privato.',
  },
];
