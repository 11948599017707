/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Localization from 'shared-vectors/icons/MapLocalization';
import LocalizationIos from 'shared-vectors/icons/MapLocalizationIos';
import CloseCirc from 'shared-vectors/icons/CloseCirc';
import Chevron from 'shared-vectors/icons/DsChevron24';
import { Icon } from 'shared-vectors';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';
import Spinner from 'shared-components/components/Spinner';

import { setItem, getItem } from 'shared-utils/src/localStorage';
import debounce from 'shared-utils/src/debounce';
import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';
import sleep from 'shared-utils/src/sleep';

import { isChildOf } from 'client/helpers/DOMHelpers';

import ConfirmDialog from '@async-components/ConfirmDialog';

import { LS_RECENTADDRESSES } from '../../../constants';
import saveRecentAddress from '../../../helpers/saveRecentAddress';
import inputFocusOnBtnClick from '../../../helpers/inputFocusOnBtnClick';
import getAddress from '../../../helpers/getAddress';
import getUserPosition from '../../../helpers/getUserPosition';


import Pill from '../../layout/Pill';

import Suggestions from './suggestions';

import './style.scss';

const GoogleSearch = ({
  map,
  mobileOs,
  device,
  places,
  core,
  geocoding,
  recentAddresses,
  className,
  searchTrigger,
  isSearching,
  setIsSearching,
  action,
  geolocationTrackPosition,
  setEraseAddressesDialog,
  isQBuilder,
}) => {
  const searchRef = useRef(null);
  const inputRef = useRef(null);
  const scrollRef = useRef(null);
  const removeRecentsRef = useRef(null);
  const autocompleteRef = useRef(null);
  const placesServiceRef = useRef(null);
  const sessionTokenRef = useRef(null);
  
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState(null);
  const [recent, setRecent] = useState(getItem(LS_RECENTADDRESSES) || []);
  const [gsError, setGsError] = useState('');
  const [gsLoading, setGsLoading] = useState(false);
  const [isInpInFocus, setIsInpInFocus] = useState(false);

  useEffect(() => {
    if (map && places && !autocompleteRef.current) {
      const { AutocompleteService, AutocompleteSessionToken, PlacesService } = places;
      autocompleteRef.current = new AutocompleteService();
      placesServiceRef.current = new PlacesService(map);      
      sessionTokenRef.current = new AutocompleteSessionToken();
    }
  }, [places, map]);

  useEffect(() => recentAddresses.length && setRecent(recentAddresses), [recentAddresses]);

  const onClickout = (e) => {
    const clicked = e.target;

    let isNotCNFDialog = true;
    if (clicked.classList && typeof clicked.classList.includes === 'function') {
      isNotCNFDialog = !clicked.classList.includes('cnfDialog__conf__btn') && !clicked.classList.includes('cnfDialog__ovrl');
    }
    if (
      !isChildOf(clicked, searchRef?.current)
      && clicked !== removeRecentsRef?.current
      && clicked !== searchTrigger
      && isNotCNFDialog
    ) {
      (scrollRef?.current || {}).scrollTop = 0;
      setIsSearching(false);
    }
  };

  useEffect(() => {
    if (isSearching) {
      inputFocusOnBtnClick(inputRef?.current);
      if (device !== 'smartphone') {
        window.addEventListener('click', onClickout);
      }
    } else if (device !== 'smartphone') { 
      window.removeEventListener('click', onClickout);
    }
    return () => window.removeEventListener('click', onClickout);
  }, [isSearching, device]);

  const toggleSearch = () => {   
    (scrollRef?.current || {}).scrollTop = 0;
    const newState = !isSearching;
    setIsSearching(newState);
    if (newState) {      
      inputFocusOnBtnClick(inputRef?.current);
    }
  };

  const goToRecent = ({
    location,
    viewport,
    name,
    parent,
  }) => {
    toggleSearch();

    action({
      name,
      parent,
      location,
      viewport,
    });    
  };

  const removeRecents = () => {
    setRecent([]);
    setItem(LS_RECENTADDRESSES, []);
  };

  const askConfirmationForRemove = () => {
    setEraseAddressesDialog(
      <ConfirmDialog
        msg={{
          title: 'Elimina cronologia',
          text: 'Eliminare tutti gli indirizzi recenti?',
        }}
        onClose={() => setEraseAddressesDialog(null)}
        action={removeRecents}
        actionText="Elimina tutti"
        isQBuilder={isQBuilder}
      />,
    );
  };

  const goToLocation = ({
    address,
    location,
    viewport,
    error,
  }) => {
    if (error) {
      setGsLoading(false);
      setGsError(<>Non è possibile trovare la tua posizione al momento.<br />Riprova o usa la barra di ricerca.</>);
    } else {
      const { num, addr, loc } = address;

      const resultObj = {
        name: `${addr}${num ? ` ${num}` : ''}`,
        parent: loc,
        location: JSON.parse(JSON.stringify(location)),
        viewport: JSON.parse(JSON.stringify(viewport)),
      };
  
      const updatedRecent = saveRecentAddress(resultObj);
      setRecent(updatedRecent);
      toggleSearch();
      setGsLoading(false);
      setQuery('');
      setSuggestions(null);
      action(resultObj);
    }
  };

  const getPlaceData = (place) => {    
    const { 
      address_components,
      geometry: {
        viewport = null,
        location = null,
      } = {},
    } = place;

    const address = getAddress(address_components);

    goToLocation({
      address,
      location,
      viewport,
    });
  };

  const selectSuggestion = async (placeId) => {
    await placesServiceRef?.current?.getDetails({
      placeId,
      sessionToken: sessionTokenRef?.current,
    }, getPlaceData);
  };

  const getSuggestions = async (q) => {
    if ((q || '').length > 2) {
      const opts = {
        input: q,
        componentRestrictions: { country: ['it', 'smr', 'mc'] },
        sessionToken: sessionTokenRef?.current,
        types: ['geocode'],
      };
      const pre = await autocompleteRef?.current?.getPlacePredictions(opts, x => x);
      setSuggestions(pre?.predictions || []);
    } else {
      setSuggestions(null);
    }
  };    

  const getSuggestionsDB = debounce(getSuggestions, 100);

  const handleInputFocus = () => {
    setIsInpInFocus(true);
    gsError && setGsError('');
  };

  const handleInputBlur = () => setIsInpInFocus(false);

  const handleQueryChange = (ev) => {
    const q = ev.target.value;
    gsError && setGsError('');
    setQuery(q);
    getSuggestionsDB(q);
  };

  const eraseQuery = () => {
    setQuery('');
    setSuggestions(null);
    inputFocusOnBtnClick(inputRef?.current);
  };
  
  const doGetUserPosition = () => {
    setGsLoading(true);
    getUserPosition({
      core,
      geocoding,
      action: goToLocation,
    });
    if (geolocationTrackPosition) {
      sleep(300).then(() => {
        trackGTMEvents({
          category: 'Interaction',
          action: 'FindMyLocation',
          position: geolocationTrackPosition,
        }, {}, true);
      });
    }
  };

  const hasRecent = recent.length > 0;

  if (map) {
    return (
      <div ref={searchRef} className={`csa_mapsearch__bod__map__search${isSearching ? ' searching' : ''}${className ? ` ${className}` : ''}`}>
        <div className="csa_mapsearch__bod__map__search__comp">
          <Grid className="csa_mapsearch__bod__map__search__top">
            <GridItem className={`csa_mapsearch__bod__map__search__inp${isInpInFocus ? ' inFocus' : ''} p--s c-bg--w is-rel`}>
              <input
                ref={inputRef}
                type="text"
                placeholder="Cerca indirizzo"
                className="tp-s--l tp-w--s c-txt--f5 c-bg--w b--0"
                onChange={handleQueryChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                value={query}
              />              
              <div className={`csa_mapsearch__bod__map__search__inp__erase c-txt--f5${!query.length ? ' is-hidden' : ''}`} onClick={eraseQuery}>
                <Icon name={CloseCirc} />
              </div>
            </GridItem>
            <GridItem behavior="fixed" className="csa_mapsearch__bod__map__search__close pl--m tp-s--s tp-w--m c-txt--f0 is-clickable" onClick={toggleSearch}>
              Annulla
            </GridItem>
          </Grid>
          <div ref={scrollRef} className={`csa_mapsearch__bod__map__search__scroll${suggestions ? ' is-hidden' : ''}`}>
            <div className={`csa_mapsearch__bod__map__search__geo${gsLoading ? ' loading' : ''}`}>
              <Pill
                className="is-rel"
                action={doGetUserPosition}
                leftIcon={<Icon name={mobileOs === 'ios' ? LocalizationIos : Localization} className="mr--xs" width="24px" fill />}
                hasBorder
              >
                <span>Cerca intorno a te</span>
                <Spinner
                  className="csa_mapsearch__bod__map__search__geo__spinner"
                  color="primary"
                  size="m"
                  weight="m"
                  inline
                />
              </Pill>
              {gsError ? <p className="mt--s pr--ms tp-s--xs tp-w--m c-txt--err">{gsError}</p> : null}
            </div>
            <div className={`csa_mapsearch__bod__map__search__rec${hasRecent ? '' : ' is-hidden'}`}>
              <Grid className="csa_mapsearch__bod__map__search__list--tit pr--ms pl--ms">
                <GridItem className="tp-s--xs tp-w--m c-txt--f0">RECENTI</GridItem>
                <div
                  ref={removeRecentsRef}
                  className="tp-s--s tp-w--m c-txt--f5 csa_mapsearch__bod__map__search__txtbtn is-rel is-clickable"
                  onClick={askConfirmationForRemove}
                >
                  Elimina tutti
                  <Icon name={Chevron} width="20px" fill className="rotate--270" />
                </div>
              </Grid>
              <ul className="csa_mapsearch__bod__map__search__list mt--s bt--s">
                {hasRecent ? recent.map((sugg, index) => (
                  <li
                    key={`${sugg.name}-${sugg.parent}`}
                    className={`csa_mapsearch__bod__map__search__list--item${(!mobileOs && index === 0) ? ' first' : ''}${(!mobileOs && index === recent.length - 1) ? ' last' : ''} pt--xs pr--ms pb--xs pl--ms tp-s--s c-txt--secondary c-bg--w bb--s tp--ell is-rel is-clickable`}                          
                    onClick={() => goToRecent(sugg)}
                  >
                    <p className="tp-w--m">{sugg.name}</p>
                    <p>{sugg.parent}</p>
                  </li>
                )) : null}
              </ul>
            </div>
          </div>
          <Suggestions
            data={suggestions}
            query={query}
            mobileOs={mobileOs}
            selectSuggestion={selectSuggestion}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default GoogleSearch;

GoogleSearch.propTypes = {
  map: PropTypes.instanceOf(Object),
  mobileOs: PropTypes.string,
  device: PropTypes.string,
  places: PropTypes.instanceOf(Object),
  core: PropTypes.instanceOf(Object),
  geocoding: PropTypes.instanceOf(Object),
  recentAddresses: PropTypes.instanceOf(Array),
  className: PropTypes.string,
  searchTrigger: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]),
  isSearching: PropTypes.bool,
  setIsSearching: PropTypes.func,
  action: PropTypes.func,
  geolocationTrackPosition: PropTypes.string,
  setEraseAddressesDialog: PropTypes.func,
  isQBuilder: PropTypes.bool,
};

GoogleSearch.defaultProps = {
  map: null,
  mobileOs: '',
  device: '',
  places: null,
  core: null,
  geocoding: null,
  recentAddresses: [],
  className: '',
  searchTrigger: null,
  isSearching: false,
  setIsSearching: null,
  action: null,
  geolocationTrackPosition: null,
  setEraseAddressesDialog: () => {},
  isQBuilder: false,
};
