// @flow
import isomorphicFetch from 'isomorphic-fetch';

const isUrlAbs = str => /(^\/\/)|(:\/\/)/.test(str);

function ensureServerUrl(serverUrl: string, input: any) {
  if (typeof input !== 'string') return input;
  if (isUrlAbs(input)) return input;
  return `${serverUrl}${input}`;
}

// Simple wrapper making isomorphic-fetch universal.
export default function createFetch(serverUrl: string) {
  return (input: any, init: any) => {
    input = ensureServerUrl(serverUrl, input);
    return isomorphicFetch(input, init);
  };
}
