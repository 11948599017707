import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Localization from 'shared-vectors/icons/MapLocalization';
import LocalizationIos from 'shared-vectors/icons/MapLocalizationIos';
import Visualization from 'shared-vectors/icons/MapVisualization';
import Minus from 'shared-vectors/icons/MapMinus';
import Plus from 'shared-vectors/icons/MapPlus';
import { Icon } from 'shared-vectors';

import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';
import sleep from 'shared-utils/src/sleep';

import SelectList from 'shared-components/components/SelectList';

import { isChildOf } from '@helpers/DOMHelpers';

import { DRAWING_ACTIONS_MAP } from '../../../constants';
import getUserPosition from '../../../helpers/getUserPosition';

import WithTooltip from '../../layout/WithTooltip';

import './critical.scss';

const visualizationValuesMap = {
  roadmap: 'Mappa',
  hybrid: 'Satellite',
  satellite: 'Satellite (senza nomi)',
};

const CustomUI = ({
  map,
  mobileOs,
  searchMode,
  core,
  geocoding,
  setPinpoint,
  isSearchAroundYou,
  device,
}) => {
  const sortBtn = useRef(null);

  const [visualizationType, setVisualizationType] = useState(Object.keys(visualizationValuesMap)[0]);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const goToLocation = ({
    address,
    location,
    viewport,
    error,
  }) => {
    if (error) {
      // setGsLoading(false);
      // setGsError(<>Non è possibile trovare la tua posizione al momento.<br />Riprova o usa la barra di ricerca.</>);
    } else {
      const { LatLng, LatLngBounds } = core;
      const { num, addr, loc } = address;

      const name = `${addr}${num ? ` ${num}` : ''}`;
      const parent = loc;

      const usableViewport = JSON.parse(JSON.stringify(viewport));

      const {
        north,
        east,
        south,
        west,
      } = usableViewport;

      setPinpoint({
        name,
        parent,
        uKey: `pin_${location.lat()},${location.lng()}`,
        isGeoPin: true,
        lat: location.lat(),
        lon: location.lng(),
      });
  
      const sboxbounds = new LatLngBounds();
      sboxbounds.extend(new LatLng({ lat: north, lng: east }));
      sboxbounds.extend(new LatLng({ lat: south, lng: west }));
  
      map.fitBounds(sboxbounds);   

      // const updatedRecent = saveRecentAddress(resultObj);
      // setRecent(updatedRecent);
      // toggleSearch();
      // setGsLoading(false);
      // setQuery('');
      // setSuggestions(null);
      // action(resultObj);
    }
  };

  const geoFindMe = () => {
    // const success = (position) => {
    //   const { coords: { latitude, longitude } } = position;
    //   const userLocation = new google.maps.LatLng(latitude, longitude);
    //   map.setZoom(15);
    //   map.setCenter(userLocation);
    // };

    // const error = e => console.log('ops: ', e);

    // if (!navigator.geolocation) {
    //   console.log('no hay localization');
    // } else {
    //   navigator.geolocation.getCurrentPosition(success, error);
    // }
    getUserPosition({
      core,
      geocoding,
      action: goToLocation,
    });

    sleep(300).then(() => {
      trackGTMEvents({
        category: 'Interaction',
        action: 'FindMyLocation',
        position: 'MapButton',
      }, {}, true);
    });
  };

  const toggleSelect = () => setIsSelectOpen(!isSelectOpen);

  useEffect(() => {
    const onSelectClickOut = (e) => {
      const { target } = e || {};
      if (target && !isChildOf(target, sortBtn.current)) {
        toggleSelect();
      }
    };
    if (isSelectOpen) {
      window.addEventListener('click', onSelectClickOut);
    } else {
      window.removeEventListener('click', onSelectClickOut);
    }
    return () => window.removeEventListener('click', onSelectClickOut);
  }, [isSelectOpen]);

  const visualizationChange = (type) => {
    map.setMapTypeId(type);
    setVisualizationType(type);
    const trackValue = type === 'roadmap' ? 'standard' : type;
    sleep(300).then(() => {
      trackGTMEvents({
        category: 'Map',
        action: 'ChangeType',
        label: trackValue,
      });
    });
  };

  const zoomIn = () => map.setZoom(map.getZoom() + 1);
  const zoomOut = () => map.setZoom(map.getZoom() - 1);

  if (!map) {
    return null;
  }

  let showLocalizationBtn = !isSearchAroundYou;
  if (mobileOs && !isSearchAroundYou) {
    showLocalizationBtn = ![DRAWING_ACTIONS_MAP.circle, DRAWING_ACTIONS_MAP.nearby].includes(searchMode);
  }

  return (
    <div className="csa_mapsearch__bod__map__ui">
      {showLocalizationBtn ? (
        <WithTooltip
          tooltipText="Cerca intorno a te"
          tooltipCls="mb--m"
          baloonPosition="left"
          baloonCls="tooltip"
          device={device}
        >
          <div className="btn over p--s c-bg--w c-txt--f0 b-r--pill is-clickable" onClick={geoFindMe}>
            <Icon name={mobileOs === 'ios' ? LocalizationIos : Localization} width="24px" fill />
          </div>
        </WithTooltip>
      ) : null}
      <WithTooltip
        tooltipText="Opzioni mappa"
        tooltipCls="mb--m"
        baloonPosition="left"
        baloonCls={`tooltip${isSelectOpen ? ' is-hidden' : ''}`}
        device={device}
      >
        <div ref={sortBtn} className="btn over p--s c-bg--w c-txt--f0  b-r--pill is-clickable is-rel" onClick={toggleSelect}>
          <Icon name={Visualization} width="24px" fill />
          <SelectList
            valuesItems={visualizationValuesMap}
            ariaLabelPrefix="Ordina i risultati secondo il parametro: "
            sortChange={visualizationChange}
            sortValue={visualizationType}
            isOpen={isSelectOpen}
            customCls="select"
          />
        </div>
      </WithTooltip>
      <div className="zoom c-bg--w">
        <WithTooltip
          tooltipText="Aumenta zoom"
          tooltipCls=""
          baloonPosition="left"
          baloonCls="tooltip"
          device={device}
        >
          <div className="zbtn p--s c-txt--f0 is-clickable over" onClick={zoomIn}>
            <Icon name={Plus} width="24px" fill />
          </div>
        </WithTooltip>
        <WithTooltip
          tooltipText="Riduci zoom"
          tooltipCls=""
          baloonPosition="left"
          baloonCls="tooltip"
          device={device}
        >
          <div className="zbtn p--s c-txt--f0 is-clickable over" onClick={zoomOut}>
            <Icon name={Minus} width="24px" fill />
          </div>
        </WithTooltip>
      </div>
    </div>
  );
};

export default CustomUI;

CustomUI.propTypes = {
  map: PropTypes.instanceOf(Object),
  mobileOs: PropTypes.string,
  searchMode: PropTypes.string,
  core: PropTypes.instanceOf(Object),
  geocoding: PropTypes.instanceOf(Object),
  setPinpoint: PropTypes.func,
  isSearchAroundYou: PropTypes.bool,
  device: PropTypes.string,
};

CustomUI.defaultProps = {
  map: null,
  mobileOs: null,
  searchMode: null,
  core: null,
  geocoding: null,
  setPinpoint: null,
  isSearchAroundYou: false,
  device: '',
};
