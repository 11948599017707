import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'shared-components/components/Tooltip';

const WithTooltip = ({
  tooltipText,
  tooltipCls,
  baloonPosition,
  baloonCls,
  device,
  children,
}) => {
  if (device === 'desktop') {
    return (
      <Tooltip
        text={tooltipText}
        className={`${tooltipCls} is-rel`}
        position={baloonPosition}
        baloonCls={`${baloonCls} p--s tp-s--s tp-w--l c-txt--w`}
      >
        {children}
      </Tooltip>
    );
  }
  return <div className={tooltipCls}>{children}</div>;
};

export default WithTooltip;

WithTooltip.propTypes = {
  tooltipText: PropTypes.string,
  tooltipCls: PropTypes.string,
  baloonPosition: PropTypes.string,
  baloonCls: PropTypes.string,
  device: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};

WithTooltip.defaultProps = {
  tooltipText: '',
  tooltipCls: '',
  baloonPosition: '',
  baloonCls: '',
  device: '',
};
