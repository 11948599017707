// @flow
import * as actions from './actions';

const initialState = {};

export default function searchReducer(state: Object = initialState, action: Object) {
  switch (action.type) {
    case actions.FETCH_SEARCH_AROUND_YOU_START: {
      return Object.assign({}, state, {
        isFetching: true,
        isFetchingScroll: false,
        errorMessage: '',
      });
    }
    case actions.FETCH_SEARCH_AROUND_YOU_SUCCESS: {
      return Object.assign({}, action.payload, state, {
        isFetching: false,
        errorMessage: '',
      });
    }
    case actions.FETCH_SEARCH_AROUND_YOU_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload.message,
      });
    }
    default: {
      return state;
    }    
  }
}
