

const agencyHomeSEOData = (filters, pathname) => {
  const title = 'Agenzie Immobiliari, cerca l\'agenzia più vicina a te su Casa.it';
  const h1 = 'Agenzie immobiliari su Casa.it';
  const description = 'Casa.it mette a tua disposizione un elenco sempre aggiornato di agenzie immobiliari. Entra e trova l’agenzia più vicina a te';
  const canonical = pathname;

  return {
    seo: {
      title,
      h1,
      meta: {
        description: {
          content: description,
        },
      },
    },
    urls: {
      canonical,
      hreflang: {
        it: pathname,
      },
    },
    robots_index: true,
  };
};
export default agencyHomeSEOData;
