// @flow
import * as actions from './actions';

const initialState = {};

export default function agentProReducer(state: Object = initialState, action: Object) {
  switch (action.type) {
    case actions.FETCH_AGENT_PRO_START: {
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_AGENT_PRO_SUCCESS: {
      return Object.assign({}, action.payload, state, {
        isFetching: false,
        errorMessage: '',
      });
    }

    case actions.FETCH_AGENT_PRO_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_SEARCH_AGENT_PRO_START: {
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCH_AGENT_PRO_SUCCESS: {
      return Object.assign({}, action.payload, state, {
        isFetching: false,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCH_AGENT_PRO_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload.message,
      });
    }

    default: {
      return state;
    }
    
  }
}
