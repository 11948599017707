import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'shared-components/components/Grid';

import { getActiveFilters } from '@filters/helpers';

import { getTitle } from './helpers';

const BaloonMessageNoResults = ({
  isAgencySrp,
  filters,
  className,
  isMap,
}) => {
  const mainCls = `nrHeader${isMap ? 'map' : ''}`;
  const { activeFiltersCount } = getActiveFilters(filters);
  return (
    <Grid className={`${mainCls} p--m ${className}`.trim()} direction="column" align={!isMap ? 'center' : ''} justify="center">
      <p className={`${mainCls}_title tp-s--l tp-w--l mb--${!isMap ? 'x' : ''}s c-txt--f0`}>
        {getTitle({ isAgencySrp })}
      </p>
      {activeFiltersCount > 0 && (
        <p className={`${mainCls}_sub-title tp-s--m tp-w--${isMap ? 'm' : 's'} c-txt--f0`}>
          Prova a modificare o eliminare qualche filtro
        </p>
      )}
    </Grid>
  );
};

BaloonMessageNoResults.propTypes = {
  isAgencySrp: PropTypes.bool,
  isMap: PropTypes.bool,
  filters: PropTypes.instanceOf(Object),
  className: PropTypes.string,
};

BaloonMessageNoResults.defaultProps = {
  isAgencySrp: false,
  isMap: false,
  filters: {},
  className: '',
};

export default BaloonMessageNoResults;
