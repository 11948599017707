/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Icon from '@components/common/Icon';
import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';
import { distinct } from '@helpers';
import './style.scss';

export default function SelectableList({
  className,
  children,
  titleItems = '',
  items = [],
  itemsSelected = '',
  onUpdate = () => {},
  preSelect = () => {},
  multiple = false,
  separator = false,
  textEmpty = 'Nessuna scelta',
  itemEmpty = {},
  itemProps = {},
  tipIcon = 'check',
  noTip = false,
  gapItems = 'm',
  tip = null,
  unselectable = false,
}) {
  const [selected, setSelected] = useState(itemsSelected || []);
  useEffect(() => setSelected(itemsSelected), [itemsSelected]);
  const TipIcon = slc => (slc && tip) || <Icon name={slc ? tipIcon : 'empty'} color="primary" />;

  const updateSelected = (result) => {
    setSelected(result);
    onUpdate(result);
  };

  const deselectAll = () => updateSelected([]);
  const isSelected = item => item.selected || selected.indexOf(item.id) >= 0;

  const toggleSelected = (item) => {
    const { id } = item;
    const isSel = isSelected(item);
    let result = selected;

    const preSelectRes = preSelect && preSelect({ item, selected, isSelected: isSel });
    if (preSelectRes === false) return;

    if (multiple) {
      if (isSel) {
        result = selected.filter(it => it !== id);
      } else {
        result = distinct(selected.concat(item.id), id);
      }
    } else if (unselectable && isSel) {
      result = [];
    } else {
      result = [id];
    }

    updateSelected(result);
  };

  const itemClass = (item) => {
    let firstCls = '';
    if (item.id === 'dove-vivo') {
      if (!item.nearById) {
        firstCls = 'selectable-list__item-fstbsp';
      } else {
        firstCls = 'selectable-list__item-fstssp';
      }
    }
    return [
      'selectable-list__item is-clickable tp-s--l tp-w--m c-txt--secondary b-r--m',
      firstCls,
      gapItems ? `mb--${gapItems}` : '',
      isSelected(item) ? 'selectable-list__item--s c-bg--w is-boxed--s' : '',
    ].filter(i => i).join(' ');
  };

  const Item = (item, content = '') => (
    <div
      key={item.id}
      className={itemClass(item)}
      onClick={() => item.action ? item.action() : toggleSelected(item)}
    >
      <Grid className={`${!content ? 'selectable-list__item-grid ' : ''}pr--m pl--m`} align="center" {...itemProps}>
        <GridItem className="tp--ell">
          { !!item.icon && !content && <Icon name={item.icon} className="selectable-list__icon mr--s" size="m" /> }
          { content || item.name }
        </GridItem>
        {
          !noTip &&
          <GridItem behavior="fixed" push="left">
            { TipIcon(isSelected(item)) }
          </GridItem>
        }
      </Grid>
    </div>
  );
  const emptyProps = {
    name: textEmpty,
    selected: !selected.length,
    action: deselectAll,
    icon: 'line',
    ...itemEmpty,
  };


  return (
    <div className={`selectable-list ${className}`}>
      {
        itemEmpty &&
        <div className="selectable-list__item-n">
          { Item(emptyProps, children ? children(emptyProps) : null) }
        </div>
      }
      { separator && <div className="selectable-list__hr" /> }
      { !!titleItems && <div className="selectable-list__items-title sizec-h--m pl--m mt--m tp-w--m tp--up c-txt--f2">{ titleItems }</div>}
      <div className="selectable-list__items">
        {items.map(item => Item(item, children ? children(item, { isSelected: isSelected(item) }) : null))}
      </div>
    </div>
  );
}
