import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import initStore from '@common/store';

const appElement = document.getElementById('app');
const initialState = window.__INITIAL_STATE__ || {}; // eslint-disable-line no-underscore-dangle
const store = initStore(initialState);

// Global GTM vars
window.dataLayer = window.dataLayer || [];
window.dataLayer.push({
  buildTs: process.env.BUILD_TS,
  loggerId: 'fe-portal18',
});


export default (App, clbHot) => {
  const onRehydrate = () => {
    const ssStyles = document.getElementById('server-side-styles');
    ssStyles && ssStyles.parentNode.removeChild(ssStyles);

    if (!module.hot || typeof module.hot.accept !== 'function') return;
    clbHot && clbHot();
    /*
    // For how weird `module.hot.accept` works HMR doesn't work if called
    // not in the same file of the App component
    if (!module.hot || typeof module.hot.accept !== 'function') return;
    module.hot.accept(hotAccept, () => {
      ReactDOM.render(
        <BrowserRouter>
          <App store={store} />
        </BrowserRouter>,
        appElement,
      );
    });
    */
  };

  ReactDOM.hydrate(
    <BrowserRouter>
      <App store={store} />
    </BrowserRouter>,
    appElement,
    onRehydrate,
  );
};
