import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const Container = ({ stepProps, children }) => {
  const el = useRef(null);

  useEffect(() => {
    // reveal step elements one by one
    const { current: container } = el;

    const elements = container.childNodes[0].childNodes;
    const limit = elements.length - 1;
    let current = 0;
    const int = setInterval(() => {
      elements[current].classList.remove('hid');
      current += 1;
      if (current > limit) {
        clearInterval(int);
      }
    }, 100);
  }, []);

  return (
    <div
      ref={el}
      className={`lndg__step${stepProps.isHidden ? ' noVis' : ''}`}
    >
      {children}
    </div>
  );
};

export default Container;

Container.propTypes = {
  stepProps: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};

Container.defaultProps = {
  stepProps: {},
};
