//  Cookie storage helpers

export const getCookieDomain = () => {
  const hostParts = window.location.hostname.split('.');

  if (hostParts.indexOf('localhost') > -1) { // local
    return '';
  } if (hostParts.indexOf('dev') > -1) { // dev environment
    return `;domain=.${hostParts.slice(hostParts.indexOf('dev')).join('.')}`;
  } if (hostParts.length > 2) { // prod environment
    return `;domain=.${hostParts.slice(hostParts.length - 2).join('.')}`;
  }

  return '';
};

export const setCookie = (name, value, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = exdays === 0 ? 'expires=0' : `expires=${d.toUTCString()}`;
  const domain = getCookieDomain();

  document.cookie = `${name}=${value}; ${expires}; path=/ ${domain}`;
};

export const getCookie = (id) => {
  const name = `${id}=`;
  const ca = document.cookie.split(';');

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
};

export const deleteCookie = (name) => {
  const d = new Date();
  d.setTime(d.getTime() + (-365 * 10 * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  const domain = getCookieDomain();

  document.cookie = `${name}=; ${expires}; path=/ ${domain}`;
};


// Main Storage helpers

export const setItem = (name, value, { provider } = {}) => {
  switch (provider) {
    case 'session':
      sessionStorage.setItem(name, value);
      break;
    case 'cookie':
      setCookie(name, value, 200);
      break;
    default:
      localStorage.setItem(name, value);
  }
};

export const getItem = (name, { provider } = {}) => {
  switch (provider) {
    case 'session':
      return sessionStorage.getItem(name);
    case 'cookie':
      return getCookie(name);
    default:
      return localStorage.getItem(name);
  }
};

export const removeItem = (name, { provider } = {}) => {
  switch (provider) {
    case 'session':
      sessionStorage.removeItem(name);
      break;
    case 'cookie':
      deleteCookie(name);
      break;
    default:
      localStorage.removeItem(name);
  }
};

export default {
  setItem,
  getItem,
  removeItem,
};
