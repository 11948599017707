import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { createConsentRequestModal, createConsentMandatoryModal } from '../components/modals/factories';

const propTypes = {
  showConsentModal: PropTypes.bool,
  isMobile: PropTypes.bool,
  user: PropTypes.object,
  accountDomain: PropTypes.string,
  openModal: PropTypes.func,
  hasToShowModalConsent: PropTypes.bool,
};

const defaultProps = {
  showConsentModal: false,
  isMobile: false,
  user: {},
  accountDomain: '',
  openModal: () => {},
  hasToShowModalConsent: false,
};

const ConsentModal = ({
  showConsentModal,
  isMobile,
  user,
  accountDomain,
  openModal,
  hasToShowModalConsent,
}) => {
  const [canShow, setCanShow] = useState(true);

  /**
   * There are two kind of consent modal, this first one appears
   * for all the users that didnt have setted some consents
   * the second one is the marketing consent
   */
  useEffect(() => {
    if (hasToShowModalConsent && canShow) {
      openModal(createConsentMandatoryModal({
        type: showConsentModal,
        isMobile,
        user: user.sess,
        accountDomain,
        from: '',
      }));
      setCanShow(false);
    }
  }, [hasToShowModalConsent]);

  useEffect(() => {
    if (showConsentModal && canShow) {
      openModal(createConsentRequestModal({
        type: showConsentModal,
        isMobile,
        user: user.sess,
        accountDomain,
        from: '',
        medium: 'GoogleOneTap',
      }));
      setCanShow(false);
    }
  }, [showConsentModal, canShow]);

  return null;
};


ConsentModal.propTypes = propTypes;
ConsentModal.defaultProps = defaultProps;
export default ConsentModal;
