import superagent from 'superagent';

export const apiBase = '/portal-srp/api/v1/mycasa/';

export const request = async (options = {}) => {
  const {
    onStart,
    onEnd,
    onError,
    method,
    proxy,
    returnsData = 1,
  } = options;
  let { url } = options;
  // const isWithBase = /^https?:\/\/|^\/\//.test(url);
  const mth = (method || 'get').toLowerCase();
  const isGet = mth === 'get';
  const opts = {
    ...options,
    headers: {
      Accept: 'application/json',
      ...options.headers,
    },
  };

  if (proxy) {
    url = `${apiBase}${url}`;
  }

  onStart && onStart();

  try {
    const mthType = isGet ? 'query' : 'send';
    const toSend = isGet ? options.params : options.data;
    const req = superagent[mth](url).set(opts.headers);

    if (options.abort) {
      options.abort.current = req.abort;
    }

    const res = await req[mthType](toSend);
    const { body } = res;
    const result = body && body.data && returnsData ? body.data : (body || res);
    onEnd && onEnd({
      res,
      body,
      result,
      status: res.status,
    });
    return result;
  } catch (err) {
    const { status } = err;
    onError && onError({ error, status });
    onEnd && onEnd({
      res: err.response,
      error: err,
      message: err.message,
      status,
    });
    throw err;
  }
};


/**
 * Perform a GET request
 * @param {String} url
 * @param {Object} options
 * @example
 * get('some/url', {
 *  params: { id: 1 },
 * });
 */
export const get = (url, options) => request({
  url,
  ...options,
  method: 'get',
});

/**
 * Perform a PUT request
 * @param {String} url
 * @param {Object} options
 * @example
 * put('some/url', {
 *  data: { id: 1 },
 * });
 */
export const put = (url, options) => request({
  url,
  ...options,
  method: 'put',
});

/**
 * Perform a DELETE request
 * @param {String} url
 * @param {Object} options
 * @example
 * del('some/url', {
 *  data: { id: 1 },
 * });
 */
export const del = (url, options) => request({
  url,
  ...options,
  method: 'delete',
});

/**
 * Perform a POST request
 * @param {String} url
 * @param {Object} options
 * @example
 * post('some/url', {
 *  data: { id: 1 },
 * });
 */
export const post = (url, options) => request({
  url,
  ...options,
  method: 'post',
});

/**
 * Perform a PATCH request
 * @param {String} url
 * @param {Object} options
 * @example
 * patch('some/url', {
 *  data: { id: 1 },
 * });
 */
export const patch = (url, options) => request({
  url,
  ...options,
  method: 'patch',
});
