export const getIds = items => items.map(item => item.id);

export const compareIdsList = (newList, oldList) => [...new Set([...newList, ...oldList])];

export const createList = (items, isNoResults) => {
  if (isNoResults) {
    if (!items.length) {
      return {
        top: null,
        trigger: null,
        items: [],
        ids: [],
        length: 0,
      };
    }
    if (items.length > 3) {
      const [first, second, third, ...rest] = items;
      return {
        top: [first, second],
        trigger: third,
        items: rest,
        ids: getIds(items),
        length: items.length,
      };
    }
    if (items.length === 3) {
      const [first, second, third] = items;
      return {
        top: [first, second, third],
        trigger: null,
        items: [],
        ids: getIds(items),
        length: items.length,
      };
    }
    const [first, second] = items;
    return {
      top: second ? [first, second] : [first],
      trigger: null,
      items: [],
      ids: getIds(items),
      length: items.length,
    };
  }
  return {
    items,
    ids: getIds(items),
    length: items.length,
  };
};

export const computePEvents = ({
  fillInExpanded,
  isNoResults,
}) => {
  if (fillInExpanded) {
    return 'all';
  }
  if (!isNoResults) {
    return 'none';
  }
};
