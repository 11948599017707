import { getFromApiResult } from '@helpers/dataModels/poi';

export default (state, type) => {
  const { availablePoi } = state;
  const poiResult = availablePoi || [];

  switch (type) {
    case 'availablePoi':
      return getFromApiResult(poiResult);
    case 'allPoi':
      return state?.config?.remote?.POI?.list || {};
    case 'nearby':
      return state.nearby || {};
    case 'config':
      return state.config || {};
    case 'bookmarkData': {
      const { searchId } = state?.bookmarkData;
      return searchId ? state.bookmarkData : null;
    }
    case 'searchIsSaved':
      return state.searchIsSaved || false;
    case 'firstCallForSaveSearchFinished':
      return state.firstCallForSaveSearchFinished || false;
    case 'fpIconClicked':
      return state.fpIconClicked || 0;
    case 'availableResults':
      return state.availableResults || 0;
    case 'ptypesSelectFromModal':
      return state.ptypesSelectFromModal || false;
    case 'activeNearby':
      return state.activeNearby || '';
    case 'proposeBkmAfterLead':
      return state.proposeBkmAfterLead || false;
    case 'proposeBkmBaloon':
      return state.proposeBkmBaloon || null;
    case 'poiNamesList': {
      const { list } = state?.config?.remote?.POI;
      const poiNamesList = {};
      try {
        Object.keys(list).forEach(poi => poiNamesList[poi] = { name: list[poi].pluralNameFull });  
      } catch (error) {
        console.log(`error generating poiNamesList: ${error}`);
      }
      return poiNamesList;
    }
  }
};
