/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from 'shared-components/components/Grid';
import PillButton from 'shared-components/components/PillButton';

import integerWithThousandSeparator from 'shared-utils/src/integerWithThousandSeparator';
import Breadcrumb from '../Breadcrumb';
import BaloonMessageNoResults from '../NoResultsBanner/baloonMessageNoResults';

const propTypes = {
  isPublisherHP: PropTypes.bool,
  isAgentProPage: PropTypes.bool,
  publisherId: PropTypes.number,
  propertyTypes: PropTypes.instanceOf(Array),
  numResults: PropTypes.number,
  srptitle: PropTypes.string,
  h2: PropTypes.string,
  breadcrumbData: PropTypes.instanceOf(Object),
  isDesktop: PropTypes.bool,
  siteUrl: PropTypes.string,
  filters: PropTypes.instanceOf(Object),
};
const defaultProps = {
  isPublisherHP: false,
  isAgentProPage: false,
  publisherId: 0,
  propertyTypes: [],
  numResults: 0,
  srptitle: '',
  h2: '',
  breadcrumbData: {},
  isDesktop: false,
  siteUrl: 'https://www.casa.it',
  filters: {},
};
const PublisherHeading = ({
  isPublisherHP,
  isAgentProPage,
  publisherId,
  propertyTypes,
  numResults,
  srptitle,
  h2,
  breadcrumbData,
  isDesktop,
  siteUrl,
  filters,
}) => {
  const { links: { publisher } = {} } = breadcrumbData;

  const pageRegex = /- pagina \d/;
  let srpPageTitle = `${srptitle}${h2 !== '' ? ` ${h2}` : ''}`;
  let page = '';
  if (srpPageTitle.match(pageRegex) !== null) {
    page = srpPageTitle.substring(srpPageTitle.match(pageRegex).index);
    srpPageTitle = srpPageTitle.replace(page, '');
  }

  const getTitleText = () => {
    let titleText = null;
    if (isAgentProPage) {
      titleText = (
        <h1 className="agentProTitle__text tp-w--l c-txt-f0">
          {numResults} agenti immobiliari per {publisher.value}
        </h1>
      );
    } else {
      titleText = isPublisherHP ? (
        <h2 className="srp-pubtitle__text c-txt-f0">
          {numResults} immobili per {publisher}
        </h2>
      ) : (
        <h1 className="srp-hdr__h1 tp-w--l c-txt--f0">
          {numResults > 0 ? `${integerWithThousandSeparator(numResults)} ` : ''}
          {srpPageTitle} per {publisher} {page}
        </h1>
      );
    }
    return titleText;
  };

  let titleText = getTitleText();
  if (publisherId === null) {
    titleText = isPublisherHP ? (
      <h2 className="srp-pubtitle__text">Agenzia non trovata</h2>
    ) : (
      <h1 className="srp-hdr__h1 tp-w--l c-txt--secondary">
        Agenzia non trovata
      </h1>
    );
  }
  const showTitle = !(isPublisherHP && numResults === 0);
  const goToUrl = (url) => {
    window.location.href = url;
  };

  return (
    <>
      {publisherId && (
        <Breadcrumb
          data={breadcrumbData}
          isPublisherHP={isPublisherHP}
          isAgentProPage={isAgentProPage}
          publisherId={publisherId}
          isDesktop={isDesktop}
          siteUrl={siteUrl}
        />
      )}
      {showTitle ? (
        <Grid direction="column" className="srp-pub-hdr pt--m pr--m pl--m mb--m">
          <div className="mb--m">{titleText}</div>
          {isPublisherHP && propertyTypes && propertyTypes.length > 0 && (
            <div className="srp-pub-qlinks">
              {propertyTypes.map(button => (
                <div className="mr--m mb--m">
                  <PillButton
                    key={button.text}
                    highlatedLabel={button.count}
                    label={button.text}
                    action={() => goToUrl(button.url)}
                  />
                </div>
              ))}
              <div className="srp-pub-qlinks__lone mb--m">
                <PillButton
                  key="Vedi tutti"
                  label="Vedi tutti"
                  iconName="filterArrow"
                  clsIcon={{ width: '14px', height: '14px', rotate: '270' }}
                  type="secondary"
                  action={() => goToUrl(`/srp/?pId=${publisherId}`)}
                />
              </div>
            </div>
          )}
        </Grid>
      ) : (
        <BaloonMessageNoResults
          filters={filters}
          className="nrHeader--mb--m"
        />
      )}
    </>
  );
};
PublisherHeading.propTypes = propTypes;
PublisherHeading.defaultProps = defaultProps;
export default PublisherHeading;
