import {
  nearbyLocalToApi, nearbyApiToLocal, storageKeyNearBy, getCompiledNearBy,
  findNearBy,
} from '@helpers/dataModels/poi';
import { setItem, removeItem } from '@helpers/Storage';
import { post } from '@helpers/http';

export default async (state, type, payload) => {
  let newState = state;

  switch (type) {
    case 'nearbySync':
    case 'nearbyList':
    case 'nearbyDelete':
    case 'nearbySet': {
      const pUser = payload.user;
      const pNearby = payload.nearby;
      const { done } = payload;
      const isAuth = pUser && pUser.id;
      const id = pNearby && pNearby.valueId;
      const nearByLocal = getCompiledNearBy();
      const hasLocalNearBy = Object.keys(nearByLocal).length > 0;
      let { nearbyAll = nearByLocal } = payload;
      const dataDone = { nearbyAll, id };
      const onEnd = data => done && done(data);
      const ensureEnd = res => !res.result && onEnd(dataDone);

      if (isAuth) {
        if (type === 'nearbySet') {
          const nearby = nearbyLocalToApi(pNearby);
          const { nearById } = pNearby;
          const data = { nearby, user: pUser, ...nearById && { id: nearById } };
          const url = nearById ? 'nearby/update' : 'nearby/add';
          await post(url, {
            proxy: 1,
            data,
            onEnd({ result } = {}) {
              if (result) {
                dataDone.nearby = nearById ? pNearby : nearbyApiToLocal(result, { id });
              }
              nearbyAll = onEnd(dataDone);
            },
          });
        } else if (type === 'nearbyList') {
          let hasToSync = true;
          const data = { user: pUser };
          const resApiToLocal = result => result.reduce((acc, it) => {
            const item = nearbyApiToLocal(it);
            acc[item.valueId] = item;
            return acc;
          }, {});

          // Sync the data
          if (hasLocalNearBy) {
            // Ensure the local items are not in the remote storage
            const resPreSync = await post('nearby', { proxy: 1, data, onEnd: ensureEnd });
            nearbyAll = resApiToLocal(resPreSync);
            const nearbyAllApi = Object.keys(nearByLocal).map((i) => {
              const localNearBy = nearByLocal[i];
              const isInStore = findNearBy(localNearBy, nearbyAll);
              return !isInStore ? nearbyLocalToApi(localNearBy) : 0;
            }).filter(i => i);
            hasToSync = nearbyAllApi.length > 0;
            hasToSync && await post('nearby/sync', {
              proxy: 1,
              data: { ...data, nearbyAll: nearbyAllApi },
              onEnd(res) { // If the sync is fine I can clear the LS
                res.result && removeItem(storageKeyNearBy);
                ensureEnd(res);
              },
            });
            !hasToSync && removeItem(storageKeyNearBy);
          }

          // Avoid double call to nearby listing
          if (hasToSync) {
            const nearbyRes = await post('nearby', { proxy: 1, data, onEnd: ensureEnd });
            nearbyAll = resApiToLocal(nearbyRes);
          }

          onEnd({ nearbyAll });
        } else if (type === 'nearbyDelete') {
          const { nearById } = pNearby;
          const data = { user: pUser, id: nearById };
          await post('nearby/delete', {
            proxy: 1,
            data,
            onEnd({ result }) {
              result && (nearbyAll = onEnd(dataDone));
            },
          });
        }
      } else {
        dataDone.nearby = pNearby;
        nearbyAll = onEnd(dataDone) || nearbyAll;
        setItem(storageKeyNearBy, JSON.stringify(nearbyAll));
      }

      newState = { nearby: nearbyAll };
      break;
    }
  }

  return newState;
};
