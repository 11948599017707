import React from 'react';

import { isValidVar } from 'shared-utils/src/checkVarType';

import ContentSelectPoi from '@async-components/ContentSelectPoi';
import ContentNearBy from '@async-components/ContentNearBy';
import ContentLocalitySearch from '@async-components/ContentLocalitySearch';
import ContentAgencyNameSearch from '@async-components/ContentAgencyNameSearch';
import ProposeSaveSearchModal from '@async-components/ProposeSaveSearchModal';
import NewSaveSearchFeedbackModal from '@async-components/NewSaveSearchFeedbackModal';
import ContentRecentSearches from '@async-components/ContentRecentSearches';
import ContentLocalities from '@async-components/ContentLocalities';
import Login from '@async-components/Login';
import LoginNewFlow from '@async-components/LoginNewFlow';
import GenericLead from '@async-components/GenericLead';
import SpecificLead from '@async-components/SpecificLead';
import GenericLeadAfterSpecific from '@async-components/GenericLeadAfterSpecific';
import FeedbackModal from '@async-components/FeedbackModal';
import PhoneCall from '@async-components/PhoneCall';
import QueryBuilder from '@async-components/QueryBuiilder';
import SaveSearchQueryBuilder from '@async-components/SaveSearchQueryBuiilder';
import PTypeGroupsModal from '@async-components/PTypeGroupsModal';
import ConsentRequestModal from '@async-components/ConsentRequestModal';
import ConsentMandatoryModal from '@async-components/ConsentMandatoryModal';
import FloorplanModal from '@async-components/FloorplanModal';
// import Survey from '@async-components/Survey';
import CercacasaChannelDialog from '@async-components/cercacasa/ChannelDialog';
import CercacasaLocalitySearch from '@async-components/cercacasa/LocalitySearch';
import CercacasaZonesSearch from '@async-components/cercacasa/ZonesSearch';
import CercacasaMetroStops from '@async-components/cercacasa/MetroStops';
import CercacasaNearbyAddress from '@async-components/cercacasa/NearbyAddress';
import CercacasaErrorFeedback from '@async-components/cercacasa/ErrorFeedback';

import { sharedComponentsLoader, portalLoader } from '../common/DynamicLoader/queryBuilder';
import { createQRCodeOnly } from '../common/DynamicLoader/qrcode';


export const buttonCancel = (action, props = {}) => ({
  value: 'Annulla',
  action,
  bg: 'w',
  border: 'f3',
  color: 'f2',
  ...props,
});

export const buttonConfirm = (action, props = {}) => ({
  value: 'Conferma',
  bg: 'primary',
  action,
  className: 'tp-w--l',
  ...props,
  grid: {
    className: 'modal__grid-confirm',
    ...props.grid || {},
  },
});

export const buttonSave = (action, props = {}) => ({
  value: 'Salva',
  bg: 'primary',
  action,
  className: 'tp-w--l',
  ...props,
  grid: {
    className: 'modal__grid-confirm',
    ...props.grid || {},
  },
});

export const createPoiModal = props => ({
  width: props.isMobile ? null : 600,
  container: data => <ContentSelectPoi {...props} title="Vicino a" modalData={data} />,
});

export const createAgencyGroupModal = props => ({
  title: 'Nome Agenzia',
  width: props.isMobile ? null : 600,
  height: props.isMobile ? null : 620,
  container: data => <ContentAgencyNameSearch upOnFocus {...props} title="Nome Agenzia" modalData={data} />,
  contentClass: 'agencyModal',
});

export const createNearByModal = props => ({
  width: props.isMobile ? null : 600,
  height: props.isMobile ? null : 620,
  container: data => <ContentNearBy {...props} title="Cerco a pochi minuti da" modalData={data} />,
});

export const createRecentSearches = props => ({
  width: props.isMobile ? null : 600,
  height: props.isMobile ? null : 420,
  fullContent: true,
  compactHeader: !props.isMobile,
  version: 2,
  container: data => <ContentRecentSearches {...props} version={2} title="Ricerche recenti" modalData={data} />,
});

export const createLocalities = ({ isMobile, ...props }) => ({
  title: '',
  width: isMobile ? null : 600,
  height: isMobile ? null : 620,
  fullContent: true,
  compactHeader: true,
  header: !isMobile,
  container: data => <ContentLocalities {...props} modalData={data} />,
});

export const createLocalityModal = (props) => {
  let title = 'Scegli il luogo';
  const propsToUse = isValidVar(props) ? props : {};
  if ('isPublisherPage' in propsToUse && propsToUse.isPublisherPage) {
    title = 'Scegli dove';
  } else {
    propsToUse.isPublisherPage = false;
    propsToUse.publisherId = null;
    propsToUse.publisherLocs = [];
    propsToUse.publisherLocsNumber = 0;
  }
  return ({
    title,
    width: props.isMobile ? null : 600,
    height: props.isMobile ? null : 620,
    modalType: 'localityModal',
    container: data => <ContentLocalitySearch title={title} upOnFocus {...propsToUse} modalData={data} />,
  });
};

/**
 * MONDO SAVE SEARCH
 */

export const createProposeSaveSearchModal = (props) => {
  const version = 2;
  return ({
    width: props.searchData.isMobile ? null : 600,
    height: props.searchData.isMobile ? null : 620,
    version,
    compactHeader: true,
    container: data => <ProposeSaveSearchModal {...props} version={version} modalData={data} />,
  });
};

export const createNewSaveSearchFeedbackModal = (props) => {
  const version = 2;
  return ({
    width: props.isMobile ? null : 600,
    height: props.isMobile ? null : 620,
    version,
    compactHeader: true,
    container: data => <NewSaveSearchFeedbackModal {...props} version={version} modalData={data} />,
  });
};

/**
 * MONDO SAVE SEARCH
 */

export const createLoginModal = props => ({
  width: props.device !== 'smartphone' ? 430 : null,
  height: props.device !== 'smartphone' && props.viewportHeight > 800 ? 640 : null,
  compactHeader: true,
  fullContent: true,
  disableOvrl: true,
  version: 2,
  container: data => (
    <LoginNewFlow
      {...props}
      modalData={data}
      panelsLoader={conf => sharedComponentsLoader(conf)}
      version={2}
    />
  ),
});

export const createRizModal = (props) => {
  const version = 2;
  return ({
    compactHeader: !props.isMobile,
    width: props.isMobile ? null : 600,
    version,
    container: data => <GenericLead {...props} version={version} title="Chiedi alle agenzie della zona" modalData={data} />,
  });
};

export const createRizFeedbackModal = (props) => {
  const version = 2;
  return ({
    version,
    compactHeader: true,
    width: props.isMobile ? null : 600,
    height: props.isMobile ? null : 400,
    container: data => <FeedbackModal {...props} version={version} title="Chiedi alle agenzie della zona" modalData={data} />,
  });
};

export const createSpecificModal = (props) => {
  const version = 2;

  const consentDiff = props.user && props.user.isAuthenticated && !props.user.formData.consent
    ? 38
    : 0;

  const desktopHeight = props.isPublisherLead
    ? 458
    : 482;

  return ({
    compactHeader: true,
    width: props.isMobile ? null : 800,
    height: props.isMobile ? null : (desktopHeight + consentDiff), 
    version,
    container: data => (
      <SpecificLead
        {...props}
        version={version}
        modalData={data}
        createQRCodeOnly={createQRCodeOnly}
      />),
  });
};

export const createGenericAfterSpecificModal = (props) => {
  const version = 2;
  
  return ({
    compactHeader: true,
    width: props.device === 'smartphone' ? null : 600,
    height: props.device === 'smartphone' ? null : 620,
    version,
    container: data => (
      <GenericLeadAfterSpecific
        {...props}
        version={version}
        modalData={data}
      />),
  });
};

export const createGenericModal = (props) => {
  const version = 2;
  return ({
    compactHeader: true,
    width: 800,
    height: 549,
    version,
    container: data => (
      <GenericLead
        {...props}
        version={version}
        modalData={data}
      />),
  });
};

export const createFeedbackModal = (props) => {
  const version = 2;
  const isMobile = props.isMobile || props.deviceFrom === 'smartphone';
  return ({
    version,
    startScrolled: true,
    width: isMobile ? null : 600,
    height: isMobile ? null : 400,
    container: data => (
      <FeedbackModal
        {...props}
        version={version}
        isMobile={isMobile}
        modalData={data}
      />
    ),
  });
};

// export const createSurveyModal = (props) => {
//   const version = 2;
//   return ({
//     compactHeader: true,
//     width: 670,
//     height: props.isMobile ? null : 475,
//     fullContent: true,
//     version,
//     container: data => <Survey {...props} version={version} title="Grazie! La mail è stata inviata con successo" modalData={data} />,
//   });
// };

export const createPhonecallModal = (props) => {
  const version = 2;
  return ({
    compactHeader: !props.isMobile,
    width: 'calc(100vw - 3rem)',
    height: props.adRef ? 130 : 90,
    version,
    borderRadius: 'm',
    lockscreenCustomCls: 'dialog-lock',
    container: data => <PhoneCall {...props} version={version} modalData={data} />,
  });
};

export const queryBuilder = (props) => {
  const version = 5;
  return ({
    compactHeader: true,
    width: props.isMobile ? null : 540,
    height: props.isMobile ? null : 640,
    fullContent: true,
    version,
    disableOvrl: false,
    container: data => (
      <QueryBuilder
        {...props}
        version={version}
        modalData={data}
        sharedComponentsLoader={conf => sharedComponentsLoader(conf)}
        portalLoader={conf => portalLoader(conf)}
      />
    ),
  });
};

export const saveSearchQb = (props) => {
  const version = 5;
  return ({
    compactHeader: true,
    width: props.isMobile ? null : 540,
    height: props.isMobile ? null : 640,
    fullContent: true,
    version,
    disableOvrl: true,
    trackOnBackBtnClose: {
      category: 'FavouriteAndAlert',
      action: 'CloseAlertModal',
      position: 'SystemBackButton',
    },
    container: data => (
      <SaveSearchQueryBuilder
        {...props}
        version={version}
        modalData={data}
        sharedComponentsLoader={conf => sharedComponentsLoader(conf)}
        portalLoader={conf => portalLoader(conf)}
        isSaveSearch
      />
    ),
  });
};

export const pTypeGroupsModal = (props) => {
  const version = 5;
  return ({
    compactHeader: true,
    width: props.isMobile ? null : 540,
    height: props.isMobile ? null : 640,
    fullContent: true,
    version,
    container: data => (
      <PTypeGroupsModal
        {...props}
        version={version}
        modalData={data}
        title="Cerco..."
      />
    ),
  });
};

export const createConsentRequestModal = props => ({
  width: props.isMobile ? null : 430,
  height: props.isMobile ? null : 639,  
  compactHeader: true,
  version: 2,
  disableOvrl: true,  
  container: data => <ConsentRequestModal {...props} modalData={data} version={2} />,
});

export const createConsentMandatoryModal = props => ({
  maxWidth: props.isMobile ? null : 430,
  maxHeight: props.isMobile ? null : 380,  
  compactHeader: true,
  version: 2,
  disableOvrl: true,  
  container: data => <ConsentMandatoryModal {...props} modalData={data} version={2} />,
});

export const createFloorplanModal = props => ({
  compactHeader: true,
  version: 2,
  fullScreen: true,
  disableOvrl: true,  
  container: data => <FloorplanModal {...props} modalData={data} version={2} />,
});
export const createCercacasaChannelDialog = (props) => {
  const version = 2;
  return ({
    width: props.isMobile ? 'calc(100vw - 3rem)' : 360,
    height: 120,
    minHeight: 120,
    version,
    borderRadius: 'm',
    lockscreenCustomCls: 'dialog-lock',
    container: data => <CercacasaChannelDialog {...props} version={version} modalData={data} />,
  });
};

export const createCercacasaSmartSuggest = (props) => {
  const version = 2;
  return ({
    compactHeader: true,
    width: props.isMobile ? null : 540,
    height: props.isMobile ? null : 640,
    fullContent: true,
    version,
    container: data => <CercacasaLocalitySearch {...props} version={version} modalData={data} />,
  });
};

export const createCercacasaZonesSearch = (props) => {
  const version = 5;
  return ({
    compactHeader: true,
    width: props.isMobile ? null : 540,
    height: props.isMobile ? null : 640,
    fullContent: true,
    version,
    container: data => <CercacasaZonesSearch {...props} version={version} modalData={data} />,
  });
};

export const createCercacasaMetroStops = (props) => {
  const version = 5;
  return ({
    compactHeader: true,
    width: props.isMobile ? null : 540,
    height: props.isMobile ? null : 640,
    fullContent: true,
    version,
    container: data => <CercacasaMetroStops {...props} version={version} modalData={data} />,
  });
};

export const createCercacasaNearbyAddress = (props) => {
  const version = 2;
  return ({
    compactHeader: true,
    width: props.isMobile ? null : 540,
    height: props.isMobile ? null : 640,
    fullContent: true,
    version,
    container: data => <CercacasaNearbyAddress {...props} version={version} modalData={data} />,
  });
};

export const createCercacasaErrorFeedback = (props) => {
  const version = 2;
  return ({
    width: props.isMobile ? 'calc(100vw - 48px)' : 360,
    height: 120,
    minHeight: props.isMobile ? 318 : 270,
    version,
    borderRadius: 'm',
    lockscreenCustomCls: 'dialog-lock',
    container: data => <CercacasaErrorFeedback {...props} version={version} modalData={data} />,
  });
};
