import parseJSON from 'shared-utils/src/parseJSON';
import { fromArrayToObject } from '@helpers/dataModels/poi';
import { post } from '@helpers/http';
import { defaultBookmarkData } from '@helpers/MyCasaHelpers';
import nearbyReducer from './nearby';

export default async (state, type, payload) => {
  let newState = state;
  // on nearby cleanup in home payload is null, so use optional chaining to 
  // avoid error: user of undefined/null
  const user = payload?.user || state.user;
  const onActionSuccess = payload?.onActionSuccess || null;

  newState = await nearbyReducer(state, type, payload);

  switch (type) {
    case 'storePrevFilters': {
      newState = {
        prevFilterState: {
          filters: state.filters,
          agencyFreetext: state.agencyFreetext || {},
          availableResults: state.availableResults,
          locations: state.locations,
          parentLocation: state.parentLocation,
        },
      };
      break;
    }
    case 'restorePrevFilters': {
      newState = {
        ...state.prevFilterState,
      };
      break;
    }
    case 'updateSelectedPoi': {
      const { filters } = state;
      newState = {
        filters: {
          ...filters,
          poi: payload && payload.length > 0 ? fromArrayToObject(payload) : null,
        },
      };
      break;
    }
    case 'updateSelectedNearBy': {
      const { filters } = state;
      newState = {
        locations: [],
        parentLocation: [],
        tempParentLocation: [],
        availableResults: '',
        filters: {
          ...filters,
          poi: null,
          nearby: payload,
        },
      };
      break;
    }
    case 'deleteBookmark': {
      newState = {
        searchIsSaved: false,
        bookmarkData: defaultBookmarkData,
      };
      break;
    }
    case 'addFavoriteArticle': {
      if (user && user.id) {
        const { id: listingId } = payload;
        const res = await post('/portal-srp/api/v1/mycasa/listing/upsert', {
          data: { listingType: 'bookmark', listingId, user },
        });
        if (res && res.statusCode === 200) {
          let newLsFav = [listingId];
          const lsFav = parseJSON(window.localStorage.getItem('favorites'));
          if (lsFav?.favorites && lsFav.favorites.length > 0) {
            newLsFav = [...lsFav.favorites, listingId];
          }
          window.localStorage.setItem('favorites', JSON.stringify({ favorites: newLsFav }));

          onActionSuccess && onActionSuccess('cristoh');
          
          newState = {
            favorites: [...state.favorites, listingId],
          };
        }
      }
      break;
    }
    case 'removeFavoriteArticle': {
      if (user && user.id) {
        const listingId = payload.id;
        const res = await post('/portal-srp/api/v1/mycasa/listing/remove-bookmark', {
          data: { ids: [listingId], user },
        });
        if (res && res.statusCode === 200) {
          let newLsFav = [];
          const lsFav = parseJSON(window.localStorage.getItem('favorites'));
          if (lsFav?.favorites && lsFav.favorites.length > 0) {
            newLsFav = lsFav.favorites.filter(item => item !== listingId);
          }
          window.localStorage.setItem('favorites', JSON.stringify({ favorites: newLsFav }));
          newState = {
            favorites: state.favorites.filter(item => item !== listingId),
          };
        }
      }
      break;
    }
    case 'fpIconClicked': {
      newState = payload;
      break;
    }
    case 'ptypesSelectFromModal': {
      newState = {
        ptypesSelectFromModal: payload,
      };
      break;
    }
    case 'noMoreFpFeedback': {
      const { filters } = state;
      newState = {
        filters: {
          ...filters,
          ...payload,
        },
      };
      break;
    }
    case 'disableProposeBkmAfterLead':
      newState = {
        proposeBkmAfterLead: {
          propose: false,
          reason: 'No-HasSavedSearches',
        },
      };
      break;
  }
  return newState;
};
