import React from 'react';
import PropTypes from 'prop-types';

import makeClassName from 'shared-utils/src/makeClassName';

import { useCtxGlobal } from '@client/app/Container';

import './critical.scss';

const Agents = ({
  list,
  total,
  link,
  publisherName,
  className,
  direction,
}) => {
  const { globalState: { config: { imageServer } } } = useCtxGlobal();

  const isRow = direction !== 'column';

  const mainCls = makeClassName([
    'pubAgents',
    isRow && 'pubAgents--row',
    className,
  ]);

  const commonAgentsCls = [
    'pubAgents__item',
    'b-r--pill',
    'b--m ',
    'tp-s--s',
    'tp-w--m',
    'c-txt--w',
    'tp--up',
  ];

  const itmCls = isLast => makeClassName([
    ...commonAgentsCls,
    isLast && 'pubAgents__item--last',
    'c-bg--f3',
  ]);

  const totCls = makeClassName([
    ...commonAgentsCls,
    'pubAgents__item--last',
    'c-bg--secondary',
  ]);

  const agentsLnkCls = makeClassName([
    'pubAgents__lnk',
    'tp--u',
    'tp-w--m',
    'c-txt--f5',
    'ml--m',
  ]);

  const goToAgents = () => {
    document.location = link;
  };

  const agentDisplay = data => data.img
    ? <img className="pubAgents__item__thumb" src={`${imageServer.uri}${data.img}`} alt={data.name} width="40" height="40" />
    : data.name;

  const listHasTot = total > 4;

  return (
    <div className={mainCls} onClick={goToAgents}>
      <div className="pubAgents__list">
        {
          list.map((agnt, index) => (
            <div
              key={agnt.name}
              className={itmCls(!listHasTot && index === 4)}
            >
              {agentDisplay(agnt)}
            </div>
          ))
        }
        {listHasTot && <div className={totCls}>+{total - 4}</div>}
      </div>
      <a
        className={agentsLnkCls}
        href={link}
        title={`Scopri gli agenti di ${publisherName}`}
        rel="nofollow"
      >
        I nostri agenti
      </a>
    </div>    
  );
};

export default Agents;

Agents.propTypes = {
  list: PropTypes.instanceOf(Array).isRequired,
  total: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  publisherName: PropTypes.string.isRequired,
  className: PropTypes.string,
  direction: PropTypes.string,
};

Agents.defaultProps = {
  className: '',
  direction: 'column',
};
