import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import icons from './icons';
import './critical.scss';

export default class Icon extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    size: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    style: PropTypes.object,
  }

  static defaultProps = {
    className: '',
    size: 'm',
    color: '',
    height: '',
    width: '',
    style: {},
  }

  getClasses(mainCls = 'icon') {
    const {
      className, size, color, height, width, 
    } = this.props;

    return [
      mainCls,
      className,
      size && !height && !width ? `size--${size}` : '',
      color ? `c-txt-str--${color}` : '', // TODO: To change in `c-txt--` when icon will be transformed in fill
    ].filter(c => c).join(' ').trim();
  }

  renderByList(icons = {}, opts = {}) {
    const cls = opts.className;
    const {
      name, height, width, style, ...props 
    } = this.props;
    const IconR = icons[name];


    return IconR ? <IconR {...props} className={this.getClasses(cls)} style={{ height, width, ...style }} /> : null;
  }

  render() {
    return this.renderByList(icons);
  }
}
