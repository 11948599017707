export default (items) => {
  const sorted = items.sort((itemA, itemB) => {
    if (
      (itemA.checked && !itemB.checked) ||
      (itemA.indeterminate && !itemB.checked)
    ) return -1;
    if (
      (itemB.checked && !itemA.checked) ||
      (itemB.indeterminate && !itemA.checked)
    ) return 1;

    return 0;
  }).map(item => ({
    ...item,
    open: !!item.indeterminate,
  }));

  return sorted;
};
