export function replaceAll(str, find, replace) {
  if (!str) {
    return str;
  }
  let newString;
  if (Array.isArray(find)) {
    find.forEach(
      (test, index) => {
        newString = str.replace(new RegExp(find[index], 'g'), replace[index]);
      },
    );
  } else {
    newString = str.replace(new RegExp(find, 'g'), replace);
  }
  return newString;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeEveryFirstLetter(string) {
  const parts = string.split(' ');
  if (parts.length > 0) {
    const newStringParts = [];
    parts.forEach(
      (part) => {
        newStringParts.push(capitalizeFirstLetter(part));
      },
    );
    return newStringParts.join(' ');
  }
  return null;
}

export function integerWithThousandSeparator(num) {
  let value = '';
  const sep = '.';
  if (num !== undefined && num !== null && !(num instanceof String)) {
    value = num.toString();
  } else {
    value = num;
  }
  return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep) : '0';
}

export function abbreviateNumber(number, decPlaces = 2) {
  if (isNaN(number)) {
    return null;
  }
  let parsedValue = parseInt(number.toString().replace(/\./g, ''), 10);
  const abbrev = ['k', 'm', 'b', 't'];
  const decPlacesCopy = Math.pow(10, decPlaces);
  for (let i = abbrev.length - 1; i >= 0; i -= 1) {
    const size = Math.pow(10, (i + 1) * 3);
    if (size <= parsedValue) {
      parsedValue = Math.round((parsedValue * decPlacesCopy) / size) / decPlacesCopy;
      if ((parsedValue === 1000) && (i < abbrev.length - 1)) {
        parsedValue = 1;
        i += 1;
      }
      parsedValue += abbrev[i];
      break;
    }
  }
  return parsedValue;
}
