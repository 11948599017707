/* eslint-disable react/prop-types */
import React from 'react';
import Icon from '@components/common/Icon';
import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';
import {
  getPoiCategoryName,
  getPoiCategoryMaxDistance,
  getPoiCategoryIcon,
} from '@helpers/dataModels/poi';
import './style.scss';

export default function SelectableItemPoi({
  item, className = '', iconProps = {}, lighter = false, selected = false,
}) {
  const { id } = item;
  const cls = [
    'item-poi tp-w--m',
    className,
    lighter ? 'c-txt--f2' : '',
    selected ? 'item-poi--sel' : '',
  ].filter(i => i).join(' ');
  const maxDist = getPoiCategoryMaxDistance(id);

  return (
    <Grid className={cls} align="center" noWrap>
      <GridItem behavior="fixed">
        <Icon name={getPoiCategoryIcon(id) || item.icon} size="lx" {...iconProps} />
      </GridItem>
      <GridItem className="tp--ell tp-s--l">
        <div className="selectable-list__item-name tp--ell">
          { getPoiCategoryName(id) || item.name }
        </div>
        {
          !!maxDist &&
          <div className={`tp--ell tp-s--xs c-txt--${lighter ? 'f3' : 'f2'}`}>
            entro {maxDist} metri
          </div>
        }
      </GridItem>
    </Grid>
  );
}
