import { DEFAULT_FILTERS_VALUES } from 'shared-utils/src/helpersMyCasa/constants';

export const getTitle = ({
  isAgencySrp = false,
}) => `Al momento non ci sono${isAgencySrp ? ' agenzie in questa località' : ' annunci con le caratteristiche che cerchi'}`;

export const getFilterDefaultValue = (filterName) => {
  const range = [
    'price',
    'mqprice',
    'payment',
    'numRooms',
    'mq',
    'buildingYear',
  ];

  return range.includes(filterName)
    ? {
      [`${filterName}Min`]: DEFAULT_FILTERS_VALUES[`${filterName}Min`],
      [`${filterName}Max`]: DEFAULT_FILTERS_VALUES[`${filterName}Max`],
    } : {
      [filterName]: DEFAULT_FILTERS_VALUES[filterName],
    };  
};
