import React from 'react';
import PropTypes from 'prop-types';

import LoginPublishAd from 'shared-vectors/icons/LoginPublishAd';
import LoginResearchNotifications from 'shared-vectors/icons/LoginResearchNotifications';
import LoginSaveFavourites from 'shared-vectors/icons/LoginSaveFavourites';
import { Icon } from 'shared-vectors';

import casaClaim from 'shared-constants/src/header_footer/casaClaim';
import getExtPath from 'shared-utils/src/getExtPath';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';

import '../../critical.scss';

const CercaCasaPage = ({ children }) => (
  <div className="lndg">
    <Grid
      justify="center"
      align="center"
      className="lndg__dsk__header mb--ml c-bg--w bb--s"
    >
      <a
        href="/"
        title="Case e appartamenti in vendita – Annunci immobiliari - Casa.it"
        style={{ display: 'block', height: 42 }}
      >
        <img
          src={getExtPath('common_images/header/casalogo2024.svg')}
          width="121"
          height="42"
          alt={casaClaim}
        />
      </a>
    </Grid>
    <Grid className="lndg__page">
      <GridItem className="lndg__sidebar c-bg--w" behavior="fixed">
        <Grid
          className="lndg__sidebar__container"
          direction="column"
          align="center"
        >
          <p className="mb--ml tp-s--x2l tp-w--l c-txt--f0">Perchè Casa.it</p>
          <div className="lndg__sidebar__icon mb--m c-bg--bg b-r--pill">
            <Icon name={LoginResearchNotifications} illustration width="24px" height="24px" />
          </div>
          <p className="tp-s--l tp-w--l c-txt--f0 lndg__baloon__hp">
            Il primo sito real estate italiano 
          </p>
          <p className="tp-s--l mb--ml tp-w--s c-txt--f0 lndg__baloon__hp">
            Nata nel 1996 Casa.it è il riferimento storico del panorama digitale del Real Estate.
          </p>
          <div className="lndg__sidebar__icon mb--m c-bg--bg b-r--pill">
            <Icon name={LoginSaveFavourites} illustration width="24px" height="24px" />
          </div>
          <p className="tp-s--l tp-w--l c-txt--f0 lndg__baloon__hp">
            Un punto di riferimento per milioni di persone e famiglie
          </p>
          <p className="tp-s--l mb--ml tp-w--s c-txt--f0 lndg__baloon__hp">
            Ogni mese oltre 6 milioni di utenti cerca o vende casa sul nostro portale
          </p>
          <div className="lndg__sidebar__icon mb--m c-bg--bg b-r--pill">
            <Icon name={LoginPublishAd} illustration width="24px" height="24px" />
          </div>
          <p className="tp-s--l tp-w--l c-txt--f0 lndg__baloon__hp">
            Non solo annunci
          </p>
          <p className="tp-s--l tp-w--s c-txt--f0 lndg__baloon__hp">
            Valutazione immobili, trova agenzie, calcolatore mutui... su Casa.it trovi tutto quello che serve per vendere, comprare o affittare casa!
          </p>
        </Grid>
      </GridItem>
      <GridItem>
        <Grid direction="column" className="lndg__container is-rel">
          {children}
        </Grid>
      </GridItem>
    </Grid>
  </div>
);

export default CercaCasaPage;

CercaCasaPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};
