import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';
import WatchScroll from 'shared-components/components/WatchScroll';

import FilterArrowDsk from 'shared-vectors/icons/FilterArrowDsk';
import { Icon } from 'shared-vectors';

import { useCtxGlobal } from '@client/app/Container';

import FewResultsNextPagesTitle from '@async-components/FewResultsNextPagesTitle';

import Card from '@components/searchPage/results/Card';
import SortSelect from '@components/common/SortSelect/index';

import './style.scss';

const NoResultsTop = ({
  total,
  top,
  trigger,
  expand,
  size,
  loadDetail,
  compactMandateBanner,
  viewport,
  userLogIn,
  cardProps,
  setCurrentFloorplan,
}) => {
  const {
    model: {
      isFetching,
    },
  } = useCtxGlobal();

  const { maplist } = cardProps || {};

  return (
    <>
      <div className={`fill_no ${maplist ? 'mt--s mr--s ml--s' : 'mt--l mb--xs ml--m'}`}>
        <p className={`mb--xs tp-s--${maplist ? '' : 'x2'}l tp-w--l c-txt--${maplist ? 'f0' : 'secondary'}${maplist ? ' fill_no__tit' : ''}`}>
          Ce ne sono alcuni abbastanza vicino
        </p>
        <p className={`tp-s--${maplist ? 's' : 'm'} tp-w--m c-txt--${maplist ? 'f0' : 'f5'}${maplist ? ' fill_no__subtit' : ''}`}>
          Allargando la ricerca abbiamo trovato <span className="tp-w--l">{total} immobil{total === 1 ? 'e' : 'i'} con le caratteristiche che stavi cercando</span>
        </p>
      </div>
      {(!maplist && size) ? (
        <>
          <Grid className="fill_no__sel mt--ms" justify="flex-end">
            <GridItem behavior="fixed">
              <SortSelect />
            </GridItem>
          </Grid>
          <WatchScroll>
            {scrollInfos => <FewResultsNextPagesTitle scrollInfos={scrollInfos} />}
          </WatchScroll>
        </>
      ) : null}
      {top && 
        <div className="fill_no__top">
          {top.map(article => (
            <Card
              key={`article_${article.id}`}
              article={article}
              isSixth={false}
              isFetching={isFetching}
              loadDetail={loadDetail}
              collapseMandateBanner={compactMandateBanner}
              viewport={viewport}            
              isFillin
              setCurrentFloorplan={setCurrentFloorplan}
              userLogIn={userLogIn}
              {...cardProps}
            />
          ))}
        </div>
      }
      {trigger && (
        <div className="fill_no__trig is-rel">
          <Card
            key={`article_${trigger.id}`}
            article={trigger}
            isSixth={false}
            isFetching={isFetching}
            loadDetail={loadDetail}
            collapseMandateBanner={compactMandateBanner}
            viewport={viewport}          
            isFillin
            userLogIn={userLogIn}
            {...cardProps}
          />
          {!size && (
            <>
              <div className="fill_no__trig__opa" />
              <button
                type="button"
                className="fill_no__trig__btn tp-s--l tp-w--l c-bg--secondary c-txt--w b-r--m is-clickable"
                onClick={expand}
              >
                Vedi {total} annunci vicini <Icon name={FilterArrowDsk} width="20px" className="ml--xs" />
              </button>
            </>
          )}
        </div>
      )}    
    </>
  );
};

export default NoResultsTop;

NoResultsTop.propTypes = {
  total: PropTypes.number,
  top: PropTypes.instanceOf(Array),
  trigger: PropTypes.instanceOf(Object),
  viewport: PropTypes.instanceOf(Object),
  expand: PropTypes.func,
  size: PropTypes.number,
  loadDetail: PropTypes.func,
  compactMandateBanner: PropTypes.func,
  userLogIn: PropTypes.func,
  setCurrentFloorplan: PropTypes.func,
  cardProps: PropTypes.instanceOf(Object),
};

NoResultsTop.defaultProps = {
  total: 0,
  top: null,
  trigger: null,
  expand: () => {},
  size: null,
  loadDetail: () => { },
  compactMandateBanner: () => { },
  userLogIn: () => { },
  viewport: {},
  setCurrentFloorplan: () => {},
  cardProps: {},
};
