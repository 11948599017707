import * as actions from './actions';

const initialState = {}; // new InitialState;

export default function homeReducer(state = initialState, action) {
  switch (action.type) {

    case actions.FETCH_HOME_START: {
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_HOME_SUCCESS: {
      return Object.assign({}, action.payload, state, {
        isFetching: false,
        errorMessage: '',
      });
    }

    case actions.FETCH_HOME_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_AVAILABLE_RESULTS_START: {
      return Object.assign({}, state, {
        isFetchingResults: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_AVAILABLE_RESULTS_SUCCESS: {
      return Object.assign({}, state, action.payload, {
        isFetchingResults: false,
        errorMessage: '',
      });
    }

    case actions.FETCH_AVAILABLE_RESULTS_ERROR: {
      return Object.assign({}, state, {
        isFetchingResults: false,
        errorMessage: action.payload.message,
      });
    }
    
    default: {
      return state;
    }
  }
}
