import React from 'react';
import PropTypes from 'prop-types';

import makeClassName from 'shared-utils/src/makeClassName';

import Agency from './Agency';
import Agent from './Agent';

import './critical.scss';

const PublisherInfos = ({
  device,
  data,
  user,
  isLocalStorageAvailable,
  disableAuctions,
  collapse,
  isFromAgentPro,
  imgServer,
}) => {
  const { isAgentPro, publisherLogo, hasCrossSubscription } = data;
  const hasPlaceholder = !!(publisherLogo.indexOf('agencylogo') > -1 || publisherLogo.indexOf('placeholder') > -1);
  const resize = hasCrossSubscription
    ? '125x60-resize/'
    : '';
  const logoSrc = `${imgServer}${resize}${publisherLogo}`.replace('//agencylogo', '/agencylogo');

  const mainCls = makeClassName([
    'pbInfos',
    collapse && 'collapse',
  ]);

  return (
    <div className={mainCls}>
      {
        isAgentPro
          ? (
            <Agent
              data={data}
              logoSrc={logoSrc}
              hasPlaceholder={hasPlaceholder}
              user={user}
              isLocalStorageAvailable={isLocalStorageAvailable}
              disableAuctions={disableAuctions}
              device={device}
              imgServer={imgServer}
            />
          ) : (
            <Agency
              data={data}
              logoSrc={logoSrc}
              hasPlaceholder={hasPlaceholder}
              isFromAgentPro={isFromAgentPro}
              user={user}
              isLocalStorageAvailable={isLocalStorageAvailable}
              disableAuctions={disableAuctions}
              device={device}
            />
          )
      }
    </div>
  );
};

export default PublisherInfos;

PublisherInfos.propTypes = {
  device: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  isLocalStorageAvailable: PropTypes.bool,
  disableAuctions: PropTypes.bool,
  collapse: PropTypes.bool,
  isFromAgentPro: PropTypes.bool,
  imgServer: '',
};

PublisherInfos.defaultProps = {
  device: '',
  data: {},
  user: {},
  isLocalStorageAvailable: false,
  disableAuctions: false,
  collapse: false,
  isFromAgentPro: false,
  imgServer: PropTypes.string,
};
