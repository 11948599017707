import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import makeClassName from 'shared-utils/src/makeClassName';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';
import CheckboxField from 'shared-components/components/CheckboxField';

import DEFAULT_FILTERS_CONFIGURATION, { LICENSE_TYPE_GROUPS } from 'shared-constants/src/filters';

import './style.scss';

const PTypeItem = ({
  name,
  count,
  checked,
  onChange,
  baseCls,
}) => {
  const labelCls = makeClassName([
    'tp-s--s',
    checked ? 'tp-w--l c-txt--f0' : 'tp-w--m c-txt--secondary',
  ]);
  const addMe = () => onChange(name);
  return (
    <CheckboxField className={`${baseCls}__ckbox`} checked={checked} onChange={addMe}>
      <Grid justify="space-between" align="center">
        <p className={labelCls}>{name}</p>
        {count !== null && <p className="tp-s--xs tp-w--m c-txt--f2">{count}</p>}
      </Grid>
    </CheckboxField>
  );
};

PTypeItem.propTypes = {
  name: PropTypes.string,
  count: PropTypes.number,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  baseCls: PropTypes.string,
};

PTypeItem.defaultProps = {
  name: '',
  count: null,
  checked: false,
  onChange: () => {},
  baseCls: 'ptPanel',
};

const PTypes = ({ 
  filters,
  ptypesAggregate,
  onTypesChange,
  queryBuilderProps,
  activeFiltersCount,
  inModal,
  isMobile,
  content,
}) => {
  const inputItems = queryBuilderProps
    ? queryBuilderProps.filters[content]
    : filters[content];

  const mobile = queryBuilderProps
    ? queryBuilderProps.isMobile
    : isMobile;

  let availableItems;

  if (content === 'propertyTypes') {
    const propertyTypeGroup = queryBuilderProps
      ? queryBuilderProps.filters.propertyTypeGroup
      : filters.propertyTypeGroup;

    availableItems = propertyTypeGroup
      ? [...DEFAULT_FILTERS_CONFIGURATION.propertyTypes.values[propertyTypeGroup]]
      : [];      
  } else {
    availableItems = LICENSE_TYPE_GROUPS();
  }

  if (!availableItems.length) {
    return null;
  }

  const resetToPrevious = queryBuilderProps
    ? queryBuilderProps.resetToPrevious
    : null;

  const [selected, setSelected] = useState(inputItems);
  useEffect(() => setSelected(inputItems), [inputItems]);
  useEffect(() => setSelected(inputItems), [resetToPrevious]);


  const qbUpdate = (choice) => {    
    if (content === 'license_type_groups') {
      queryBuilderProps.setQueryBuilderTempFiltersFromPanel({ [content]: choice }, true);
    } else {
      queryBuilderProps.setQueryBuilderTempFiltersFromPanel({ ...queryBuilderProps.filters, [content]: choice });
    }
  };

  const emptySelected = () => {
    setSelected([]);
    inModal
      ? qbUpdate([])
      : onTypesChange({ type: content, list: [] });
  };

  const updateSelected = (val) => {
    const newSelected = [...selected];
    const correctVal = content !== 'propertyTypes' ? availableItems.filter(i => i.plural === val)[0].display : val;
    const valIndex = newSelected.indexOf(correctVal);
    if (valIndex < 0) {
      newSelected.push(correctVal);
    } else {
      newSelected.splice(valIndex, 1);
    }
    setSelected(newSelected);

    inModal
      ? qbUpdate(newSelected)
      : onTypesChange({ type: content, list: newSelected });
  };

  const all = content === 'propertyTypes' ? availableItems.shift() : {
    display: 'Tutte le attività',
    value: 'Tutte le attività',
    plural: 'Tutte le attività',
    slug: 'tutte',
  };

  const hasPtypesAggregate = content === 'propertyTypes' && !!ptypesAggregate && !activeFiltersCount;

  const baseCls = `ptPanel${inModal ? '__mdl' : ''}`;
  const itemsCls = inModal ? ' pr--m pl--m c-bg--w' : '';

  let modalGridHeight = 'auto';
  if (inModal) {
    if (mobile) {
      const mdlHeaderHeight = 49;
      const mdlFooterHeight = 57;
      modalGridHeight = `${window.innerHeight - mdlHeaderHeight - mdlFooterHeight}px`;
    } else {
      modalGridHeight = '542px';
    }
  }

  const items = availableItems.map(
    (ptype) => {
      let count = null;
      if (hasPtypesAggregate) {      
        count = hasPtypesAggregate && ptypesAggregate.ptypes[ptype.display] ? ptypesAggregate.ptypes[ptype.display] : 0;
      }

      const valueToDisplay = content === 'propertyTypes' ? ptype.display : ptype.plural;

      return (
        <Grid
          key={ptype.slug}
          className={`${baseCls}__item${itemsCls}`}
          align="center"
        >
          <PTypeItem
            name={valueToDisplay}
            count={count}
            checked={selected.indexOf(ptype.display) > -1}
            onChange={updateSelected}
            baseCls={baseCls}
          />
        </Grid>
      );
    },
  );

  return (
    <Grid
      className={`${baseCls}${inModal ? ' pt--m' : ''}`}
      direction="column"
      style={{ height: modalGridHeight }}
    >
      <GridItem behavior="fixed">
        <Grid
          className={`${baseCls}__top${itemsCls}`}
          align="center"
        >
          <PTypeItem
            name={all.display}
            count={hasPtypesAggregate ? ptypesAggregate.total : null}
            checked={selected.length === 0}
            onChange={emptySelected}
          />
        </Grid>
        <div className={`${baseCls}__option pr--m pl--m`}>
          <p className="tp-s--xs tp-w--m c-txt--f0">oppure seleziona una o più {content === 'propertyTypes' ? 'tipologia' : 'attività'}</p>
        </div>
      </GridItem>
      {
        inModal
          ? (
            <GridItem className={`${baseCls}__items is-over--a`}>
              {items}
            </GridItem>
          ) : items
      }
    </Grid>
  );
};

export default PTypes;

PTypes.propTypes = {
  filters: PropTypes.instanceOf(Object),
  ptypesAggregate: PropTypes.instanceOf(Object),
  onTypesChange: PropTypes.func,
  queryBuilderProps: PropTypes.instanceOf(Object),
  activeFiltersCount: PropTypes.number,
  inModal: PropTypes.bool,
  isMobile: PropTypes.bool,
  content: PropTypes.string,
};

PTypes.defaultProps = {
  filters: {},
  ptypesAggregate: null,
  onTypesChange: () => {},
  queryBuilderProps: null,
  activeFiltersCount: 0,
  inModal: false,
  isMobile: false,
  content: 'propertyTypes',
};
