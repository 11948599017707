import React from 'react';

import PAGES_TYPE from 'shared-constants/src/pagesType';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';

import { useCtxGlobal } from '@client/app/Container';

import SortSelect from '@components/common/SortSelect/index';

import BaloonMessageNoResults from './baloonMessageNoResults';

import './style.scss';

const NoResults = () => {
  const {
    model,
    model: {
      total,
      isAgencySrp,
      isPublisherPage,
      fewResults,
    },
    filtersProps: { filters },
    pageType,
  } = useCtxGlobal();

  const isMap = pageType === PAGES_TYPE.SRP_MAP;

  if (total || isPublisherPage || filters.pId) {
    return null;
  }

  if (!isMap && fewResults && fewResults.page > 1) {
    return (
      <Grid className="fill_no__sel nrHeader__mobhid mt--ms" justify="flex-end">
        <GridItem behavior="fixed">
          <SortSelect />
        </GridItem>
      </Grid>
    );
  }

  return (
    <BaloonMessageNoResults
      isAgencySrp={isAgencySrp}
      filters={filters}
      isMap={isMap}
    />
  );
};

export default NoResults;
