/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef } from 'react';

import Check from 'shared-vectors/icons/Check';
import VIcon from 'shared-vectors/src/components/Icon';

import { SORT_VALUES, SORT_DEFAULT_LABEL } from 'shared-constants/src/filters';

import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';
import sleep from 'shared-utils/src/sleep';

import Icon from '@components/common/Icon';

import { useCtxGlobal } from '@client/app/Container';

import './style.scss';

const defaulLabelValues = ['default', 'relevance'];

const sortValues = SORT_VALUES.realEstate;

const SortSelect = () => {
  const {
    isMobile,
    filtersProps: {
      filters: { sortType },
      setFilters,
    },
  } = useCtxGlobal();

  const ul = useRef(null);

  const closeUl = () => {
    ul.current.classList.remove('stndSel__ul--open');
    window.removeEventListener('click', closeUl);
  };

  const openUl = () => {
    ul.current.classList.add('stndSel__ul--open');
    setTimeout(() => window.addEventListener('click', closeUl));
  };

  const toggleUl = () => {
    if (isMobile) {
      return;
    }
    if (ul.current.classList.contains('stndSel__ul--open')) {
      closeUl();
    } else {
      openUl();
    }
  };

  const sortChange = (e) => {
    const value = isMobile ? e.target.value : e;

    sleep(300).then(() => trackGTMEvents(
      {
        category: 'Interaction',
        action: 'ChangeSortOrder',
      },
      {
        sortOrder: `from${sortType}-to${value}`,
      },
      true,
    ));
    setFilters({ sortType: value });
  };

  return (
    <div className="stndSel is-rel">
      <p
        className={`stndSel__trg tp-s--m tp-w--m c-txt--${
          isMobile ? 'secondary' : 'f2'
        } is-clickable`}
        onClick={toggleUl}
      >
        <Icon name="sort" size="" className="mr--s" />
        {defaulLabelValues.includes(sortType)
          ? SORT_DEFAULT_LABEL
          : sortValues[sortType]}
      </p>
      {isMobile ? (
        <select
          className="stndSel__select"
          name="sort"
          dir="rtl"
          value={sortType}
          onChange={(e) => {
            sortChange(e);
          }}
        >
          {Object.keys(sortValues).map((val) => (
            <option value={val}>{sortValues[val]}</option>
          ))}
        </select>
      ) : (
        <ul ref={ul} className="stndSel__ul c-bg--w">
          {Object.keys(sortValues).map((val) => {
            const isSelected =
              val === sortType ||
              (val === 'relevance' && sortType === 'default');
            const cls = `stndSel__ul__li pr--s pl--s tp-s--s tp-w--s c-txt--f2 is-clickable${
              isSelected ? ' sel' : ''
            }`;
            const action = () => sortChange(val);
            return (
              <li className={cls} onClick={action}>
                {sortValues[val]}
                {isSelected && (
                  <VIcon name={Check} width="12px" height="12px" />
                )}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default SortSelect;
