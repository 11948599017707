const selectedLocalities = (items, selected, result = []) => {
  items.forEach((item) => {
    if (selected.filter(sel => sel.hkey === item.hkey)[0]) {
      result.push(item);
    }
    item.children && selectedLocalities(item.children, selected, result);
  });
  return result;
};

export default selectedLocalities;
