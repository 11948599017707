import React from 'react';

export const MARKERS_COUNT_LIMIT = 300;

export const singleMarker = `
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M20 30.5C20 31.3284 18.2091 32 16 32C13.7909 32 12 31.3284 12 30.5C12 29.6716 13.7909 29 16 29C18.2091 29 20 29.6716 20 30.5Z" fill="#96A1B0" />
  <path class="csamrk__fill" d="M16.3068 29.745C16.1273 29.8968 15.8727 29.8968 15.6932 29.745C13.8662 28.2007 4.5 19.9112 4.5 12.2778C4.5 6.04278 9.64214 1 16 1C22.3579 1 27.5 6.04278 27.5 12.2778C27.5 19.9112 18.1338 28.2007 16.3068 29.745Z" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.62993 23.9186C6.03473 20.6674 3.5 16.4553 3.5 12.2778C3.5 5.47219 9.10834 0 16 0C22.8917 0 28.5 5.47219 28.5 12.2778C28.5 16.4553 25.9653 20.6674 23.3701 23.9186C20.7422 27.2109 17.8886 29.7173 16.9524 30.5087C16.4001 30.9755 15.5999 30.9755 15.0476 30.5087C14.1114 29.7173 11.2578 27.2109 8.62993 23.9186ZM15.6932 29.745C15.8727 29.8968 16.1273 29.8968 16.3068 29.745C18.1338 28.2007 27.5 19.9112 27.5 12.2778C27.5 6.04278 22.3579 1 16 1C9.64214 1 4.5 6.04278 4.5 12.2778C4.5 19.9112 13.8662 28.2007 15.6932 29.745Z" fill="white" />
  <path d="M21.5 12C21.5 8.96243 19.0376 6.5 16 6.5C12.9624 6.5 10.5 8.96243 10.5 12C10.5 15.0376 12.9624 17.5 16 17.5C19.0376 17.5 21.5 15.0376 21.5 12Z" fill="white" />
</svg>`;

export const approssimateMarker = `
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path class="csamrk__fill" d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16Z" fill="#E4002B"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M16 5C9.92487 5 5 9.92487 5 16C5 22.0751 9.92487 27 16 27C22.0751 27 27 22.0751 27 16C27 9.92487 22.0751 5 16 5ZM4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16Z" fill="white"/>
  <path d="M21.5 16C21.5 12.9624 19.0376 10.5 16 10.5C12.9624 10.5 10.5 12.9624 10.5 16C10.5 19.0376 12.9624 21.5 16 21.5C19.0376 21.5 21.5 19.0376 21.5 16Z" fill="white"/>
</svg>`;

export const favoriteMarker = `
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M20 30.5C20 31.3284 18.2091 32 16 32C13.7909 32 12 31.3284 12 30.5C12 29.6716 13.7909 29 16 29C18.2091 29 20 29.6716 20 30.5Z" fill="#96A1B0"/>
  <path class="csamrk__fill" d="M16.3068 29.745C16.1273 29.8968 15.8727 29.8968 15.6932 29.745C13.8662 28.2007 4.5 19.9112 4.5 12.2778C4.5 6.04278 9.64214 1 16 1C22.3579 1 27.5 6.04278 27.5 12.2778C27.5 19.9112 18.1338 28.2007 16.3068 29.745Z" fill="#E4002B"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.62993 23.9186C6.03473 20.6674 3.5 16.4553 3.5 12.2778C3.5 5.47219 9.10834 0 16 0C22.8917 0 28.5 5.47219 28.5 12.2778C28.5 16.4553 25.9653 20.6674 23.3701 23.9186C20.7422 27.2109 17.8886 29.7173 16.9524 30.5087C16.4001 30.9755 15.5999 30.9755 15.0476 30.5087C14.1114 29.7173 11.2578 27.2109 8.62993 23.9186ZM15.6932 29.745C15.8727 29.8968 16.1273 29.8968 16.3068 29.745C18.1338 28.2007 27.5 19.9112 27.5 12.2778C27.5 6.04278 22.3579 1 16 1C9.64214 1 4.5 6.04278 4.5 12.2778C4.5 19.9112 13.8662 28.2007 15.6932 29.745Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4643 4.34256C15.6808 3.88581 16.3305 3.88581 16.5469 4.34256L16.5496 4.34826L18.662 9.19494L18.6639 9.19687L23.3982 9.19728C23.9536 9.19728 24.2012 9.87046 23.8082 10.2402L23.8036 10.2445L19.9961 13.7144C19.9962 13.7161 19.9965 13.7183 19.9973 13.7209L20.0005 13.7309L21.5732 19.2057C21.7496 19.7467 21.1428 20.207 20.6695 19.8995L20.6637 19.8958L16.0016 16.7518L11.3338 19.8996C10.8612 20.2066 10.2592 19.7466 10.4386 19.2118L12.0138 13.7128L12.0139 13.7111L12.0136 13.7097C12.0132 13.7081 12.0125 13.7062 12.0114 13.7044L12.0105 13.7029L8.20178 10.2384C7.7773 9.85996 8.07557 9.19728 8.60504 9.19728H13.3466L13.3492 9.19509L15.4616 4.34826L15.4643 4.34256Z" fill="white"/>
</svg>`;

export const approssimateFavoriteMarker = `
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path class="csamrk__fill" d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16Z" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M16 5C9.92487 5 5 9.92487 5 16C5 22.0751 9.92487 27 16 27C22.0751 27 27 22.0751 27 16C27 9.92487 22.0751 5 16 5ZM4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4978 8.32115C15.7007 7.89295 16.3098 7.89295 16.5128 8.32115L16.5153 8.32649L18.4956 12.8703L18.4974 12.8721L22.9358 12.8725C23.4565 12.8725 23.6886 13.5036 23.3202 13.8502L23.3159 13.8542L19.7464 17.1073C19.7464 17.1088 19.7467 17.1109 19.7475 17.1133L19.7504 17.1227L21.2249 22.2553C21.3903 22.7625 20.8214 23.1941 20.3777 22.9058L20.3723 22.9023L16.0015 19.9548L11.6254 22.9058C11.1824 23.1937 10.618 22.7624 10.7861 22.261L12.2629 17.1058L12.2631 17.1042L12.2628 17.1028C12.2624 17.1013 12.2617 17.0996 12.2607 17.0979L12.2598 17.0965L8.68917 13.8485C8.29122 13.4937 8.57085 12.8725 9.06722 12.8725H13.5124L13.5149 12.8704L15.4953 8.32649L15.4978 8.32115Z" fill="white"/>
</svg>`;

export const clusterStar = `
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.55053 1.61885C7.73093 1.23816 8.27248 1.23816 8.45289 1.61885L8.45514 1.6236L10.2158 5.66326L10.2173 5.66486L10.218 5.66521H14.1634C14.6263 5.66521 14.8326 6.22629 14.5051 6.53445L14.5012 6.53807L11.3277 9.43019C11.3278 9.43157 11.328 9.43341 11.3287 9.43558L11.3314 9.44392L12.6422 14.0071C12.7892 14.458 12.2835 14.8417 11.889 14.5854L11.8842 14.5823L7.99837 11.9618L4.10779 14.5855C3.71387 14.8414 3.21216 14.4579 3.36161 14.0122L4.67457 9.42888L4.67467 9.42747L4.6744 9.42622C4.67405 9.4249 4.67345 9.42336 4.67257 9.42183L4.67179 9.42058L1.49728 6.53294C1.14348 6.21755 1.39209 5.66521 1.83339 5.66521H5.78543L5.7861 5.66486L5.78758 5.66338L7.54828 1.6236L7.55053 1.61885Z" fill="white"/>
</svg>`;

export const closeIcon = `
<svg viewBox="0 0 19 19">
  <path class="csamrk--poi__close--icon" fill="none" stroke="#697684" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4" d="M.7.7l17 17m0-17l-17 17" />
</svg>`;

export const pinIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <ellipse cx="16" cy="30.5" rx="4" ry="1.5" fill="#96A1B0"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17 15.9381C20.9463 15.446 24 12.0796 24 8C24 3.58172 20.4183 0 16 0C11.5817 0 8 3.58172 8 8C8 12.0796 11.0537 15.446 15 15.9381L15 30C15 30.5523 15.4477 31 16 31C16.5523 31 17 30.5523 17 30L17 15.9381Z" fill="#39434E"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7574 3.75736C12.8826 2.63214 14.4087 2 16 2C16.5523 2 17 2.44772 17 3C17 3.55228 16.5523 4 16 4C14.9391 4 13.9217 4.42143 13.1716 5.17157C12.4214 5.92172 12 6.93913 12 8C12 8.55228 11.5523 9 11 9C10.4477 9 10 8.55228 10 8C10 6.4087 10.6321 4.88258 11.7574 3.75736Z" fill="white"/>
</svg>`;

export const iconCanc = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
  <path style="stroke: none" fill="#39434E" fill-rule="evenodd" d="M8 0a8 8 0 110 16A8 8 0 018 0zm0 3.3a.7.7 0 00-.7.7v3.3H4a.7.7 0 100 1.4h3.3V12a.7.7 0 101.4 0V8.7H12a.7.7 0 100-1.4H8.7V4a.7.7 0 00-.7-.7z" />
</svg>`;

export const iconBack = `
<svg viewBox="0 0 15 11">
  <path fill="none" d="M7 10h4c1 0 3-1 3-3s-2-3-3-3H1m3 3L1 4m3-3L1 4"></path>
</svg>`;

export const iconPencil = `
<svg fill="none" viewBox="0 0 24 24">
  <path fill="#fff" fill-rule="evenodd" d="M17.22 3.5a.5.5 0 01.354.146l2.78 2.78a.5.5 0 010 .707L8.527 18.96a.5.5 0 01-.195.12l-4.117 1.375a.5.5 0 01-.207.045H4a.5.5 0 01-.439-.74l1.367-4.092a.5.5 0 01.12-.195l9.71-9.716a.468.468 0 01.046-.046l2.063-2.064a.5.5 0 01.354-.147zm-2.085 3.295l-9.296 9.302-1.038 3.108 3.102-1.036 9.303-9.303-2.071-2.071zm2.778 1.364l-2.071-2.072 1.379-1.38 2.072 2.072-1.38 1.38z" clip-rule="evenodd" />
</svg>`;

export const iconFlag = `
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="32/flag filled">
    <g id="Icon">
      <path d="M7.875 26.6875C7.875 27.4124 6.308 28 4.375 28C2.442 28 0.875 27.4124 0.875 26.6875C0.875 25.9626 2.442 25.375 4.375 25.375C6.308 25.375 7.875 25.9626 7.875 26.6875Z" fill="#96A1B0" />
      <path d="M5.25 9.87786C7.09526 8.83887 8.94527 8.90283 10.7887 9.37126V3.87364C8.83651 3.31605 7.04858 3.26624 5.25 4.53829V9.87786Z" fill="white" />
      <path d="M10.7887 9.37126L10.7888 14.8558C11.9749 15.1482 13.1195 15.6048 14.1972 16.0346L14.324 16.0852C15.3319 16.4869 16.2854 16.8613 17.2026 17.1239V11.5412C16.1351 11.27 15.0654 10.8631 13.9957 10.4563C13.9957 10.4562 13.9957 10.4563 13.9957 10.4563C13.7283 10.3545 13.4609 10.2528 13.1935 10.1532C12.3913 9.85443 11.5893 9.57469 10.7887 9.37126Z" fill="white" />
      <path d="M17.2112 6.1442V11.5413C19.0547 12.0097 20.9047 12.0736 22.75 11.0346V5.70466C20.8677 6.65239 18.9926 6.5834 17.2112 6.1442Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.375 0.875C4.85825 0.875 5.25 1.26675 5.25 1.75V2.49941C8.38565 0.92066 11.501 2.16326 14.1972 3.23866L14.324 3.28922C17.6119 4.59979 20.3206 5.6188 23.0793 3.41802C23.3421 3.2084 23.7017 3.16783 24.0046 3.31365C24.3074 3.45947 24.5 3.7659 24.5 4.10203V16.898C24.5 17.1642 24.3788 17.416 24.1707 17.582C20.5649 20.4585 16.9062 18.9992 13.8028 17.7614L13.676 17.7108C10.519 16.4524 7.89603 15.4628 5.25 17.3342V26.25C5.25 26.7332 4.85825 27.125 4.375 27.125C3.89175 27.125 3.5 26.7332 3.5 26.25V1.75C3.5 1.26675 3.89175 0.875 4.375 0.875ZM10.7887 9.37126C8.94527 8.90283 7.09526 8.83887 5.25 9.87786V4.53829C7.04858 3.26624 8.83651 3.31605 10.7887 3.87364V9.37126ZM22.75 11.0346V5.70466C20.8646 6.65392 18.9866 6.58317 17.2026 6.14208V11.5412C16.1351 11.27 15.0654 10.8631 13.9957 10.4563C12.926 10.0494 11.8562 9.64251 10.7887 9.37126L10.7888 14.8558C11.9749 15.1482 13.1195 15.6048 14.1972 16.0346L14.324 16.0852C15.3351 16.4882 16.2914 16.8636 17.2112 17.1264V11.5413C19.0547 12.0097 20.9047 12.0736 22.75 11.0346Z" fill="#39434E" />
    </g>
  </g>
</svg>`;

export const animatedLoader = `
  <svg
    class="csa_mapsearch__draw__loader"
    version="1.1"
    id="L5"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 0 0"
  >
    <circle fill="#000" stroke="none" cx="24" cy="50" r="6">
      <animateTransform 
        attributeName="transform" 
        dur="1s" 
        type="translate" 
        values="0 15 ; 0 -15; 0 15" 
        repeatCount="indefinite" 
        begin="0.1"
      />
    </circle>
    <circle fill="#000" stroke="none" cx="48" cy="50" r="6">
      <animateTransform 
        attributeName="transform" 
        dur="1s" 
        type="translate" 
        values="0 10 ; 0 -10; 0 10" 
        repeatCount="indefinite" 
        begin="0.2"
      />
    </circle>
    <circle fill="#000" stroke="none" cx="72" cy="50" r="6">
      <animateTransform 
        attributeName="transform" 
        dur="1s" 
        type="translate" 
        values="0 5 ; 0 -5; 0 5" 
        repeatCount="indefinite" 
        begin="0.3"
      />
    </circle>
  </svg>
`;

export const LS_RECENTADDRESSES = 'csa_recentAddresses';
export const RECENTADDRESSES_LS_LIMIT = 50;

export const DRAWING_ACTIONS_MAP = {
  polygon: 'draw',
  circle: 'around',
  nearby: 'nearby',
};

export const DRAWING_ACTIONS_COPY = {
  draw: {
    desktop: 'Disegna area',
    mobile: 'Disegna area',
    secondary: 'Disegna l’area dove vuoi cercare',
  },
  around: {
    desktop: 'Cerca intorno a...',
    mobile: 'Intorno a',
    secondary: 'Trova immobili intorno a un punto',
  },
  aroundyou: {
    desktop: 'Cerca intorno a te',
    mobile: 'Intorno a te',
    secondary: 'Trova immobili intorno a te',
  },
  nearby: {
    desktop: 'Cerca a pochi minuti da...',
    mobile: 'A pochi minuti da',
    secondary: 'Trova immobili per durata tragitto',
  },
};

const lineSymbol = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  scale: 2,
};

export const polylineStyle = {
  drawing: {
    strokeColor: '#39434E',
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    icons: [],
  },
  editing: {
    strokeOpacity: 0,
    icons: [{
      icon: lineSymbol,
      offset: '0',
      repeat: '10px',
    }],
    clickable: false,
  },
  complete: {
    strokeColor: '#39434E',
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    icons: [],
  },
};

export const polygonStyle = {
  drawing: {
    fillColor: '#96A1B0',
    fillOpacity: 0.3,
    strokeColor: '#39434E',
    strokeOpacity: 0,
    strokeWeight: 0,
    clickable: false,
  },
  complete: {
    fillColor: '#96A1B0',
    fillOpacity: 0.1,
    strokeColor: '#39434E',
    strokeOpacity: 0,
    strokeWeight: 0,
    clickable: false,
  },
};

export const MIN_CIRCLE_RADIUS = 100;
export const INITIAL_CIRCLE_RADIUS = 500;
export const INITIAL_CIRCLE_RADIUS_RANGE_LIMIT = 2000;
export const MAX_CIRCLE_RADIUS_RANGE_LIMIT_DEFAULT = 64000;
export const MAX_CIRCLE_RADIUS_RANGE_LIMIT_SAY = 2000;

export const animated = () => (
  <svg
    className="csa_mapsearch__draw__loader csa_mapsearch__draw__loader--controls"
    version="1.1"
    id="L5"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 0 0"
  >
    <circle fill="#FFF" stroke="none" cx="24" cy="50" r="6">
      <animateTransform 
        attributeName="transform" 
        dur="1s" 
        type="translate" 
        values="0 15 ; 0 -15; 0 15" 
        repeatCount="indefinite" 
        begin="0.1"
      />
    </circle>
    <circle fill="#FFF" stroke="none" cx="48" cy="50" r="6">
      <animateTransform 
        attributeName="transform" 
        dur="1s" 
        type="translate" 
        values="0 10 ; 0 -10; 0 10" 
        repeatCount="indefinite" 
        begin="0.2"
      />
    </circle>
    <circle fill="#FFF" stroke="none" cx="72" cy="50" r="6">
      <animateTransform 
        attributeName="transform" 
        dur="1s" 
        type="translate" 
        values="0 5 ; 0 -5; 0 5" 
        repeatCount="indefinite" 
        begin="0.3"
      />
    </circle>
  </svg>
);

export const Arrow = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="11" viewBox="0 0 21 11" fill="none" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.6146 1.19616C11.4152 -0.398723 8.99636 -0.398721 7.79691 1.19617L1.90845 9.02592H0V11H2.91464H17.4969H21V9.02592H18.5031L12.6146 1.19616ZM11.0087 2.37278C11.0025 2.36447 10.9961 2.35629 10.9897 2.34824C10.9961 2.35629 11.0025 2.36447 11.0087 2.37278L11.0087 2.37278Z" fill="#39434E" />
  </svg>
);

export const TRAVEL_COPY_MAP = {
  walk: 'a piedi',
  bike: 'in bici',
  car: 'in macchina',
};

export const initialRadius = (serverRadius = null) => {
  if (serverRadius && serverRadius > INITIAL_CIRCLE_RADIUS_RANGE_LIMIT) {
    return Math.ceil((serverRadius / 1000) / 2) * 2000;
  }
  return INITIAL_CIRCLE_RADIUS_RANGE_LIMIT;
};
