const locationsToItems = (suggestions = [], selected = [], parent) => suggestions.map((sug, index) => {
  const { children } = sug;

  const selectedMap = selected.reduce((acc, sel) => {
    acc[sel.hkey] = sel;
    return acc;
  }, {});

  return {
    label: sug.name,
    count: sug.count,
    text_count: sug.text_count,
    hkey: sug.hkey,
    checked: !!selectedMap[sug.hkey] || (parent && selectedMap[parent.hkey]),
    indeterminate: children && children.some(chl => !!selectedMap[chl.hkey]),
    pHkey: parent && parent.hkey,    
    level: sug.level,
    children: children && children.length && locationsToItems(children, selected, sug),
    description: sug.description || '',
    lvlHasDescription: sug.level === 120,
    isFirst: index === 0,
    isLast: index === suggestions.length - 1,
    branchStart: sug.branchStart || false,
    firstOfBranch: sug.firstOfBranch || false,
    lastOfBranch: sug.lastOfBranch || false,
  };
});

export default locationsToItems;
