/* eslint-disable no-console */
// @Flow
export const FETCH_HUBPAGE = 'FETCH_HUBPAGE';
export const FETCH_HUBPAGE_START = 'FETCH_HUBPAGE_START';
export const FETCH_HUBPAGE_SUCCESS = 'FETCH_HUBPAGE_SUCCESS';
export const FETCH_HUBPAGE_ERROR = 'FETCH_HUBPAGE_ERROR';

const fetchFn = (fetch, verb, params) => fetch('/portal-srp/api/v1/hubPage', {
  method: verb,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Connection: 'keep-alive',
  },
  body: JSON.stringify(params),
  credentials: 'same-origin',
});

export function fetchHubPage(params) {
  return ({ fetch }) => ({
    type: FETCH_HUBPAGE,
    payload: {
      search: 'something',
      promise: fetchFn(fetch, 'POST', params)
        .then(response => response.json())
        .catch(err => console.error('error', err)),
    },
  });
}
