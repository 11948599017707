import { DEFAULT_FILTERS_CONFIGURATION } from 'shared-constants/src/filters';

export const resetFilter = (name, result = {}) => {
  const { defaultMinValue, defaultValue } = DEFAULT_FILTERS_CONFIGURATION[name];

  if (defaultMinValue) {
    result[`${name}Min`] = defaultMinValue;
    result[`${name}Max`] = defaultMinValue;
  } else if (!['propertyTypes', 'category', 'channel', 'license_type_groups'].includes(name)) {
    result[name] = defaultValue;
  }

  return result;
};
