export const NOT_AVAILABLE = 'NOT_AVAILABLE';

export const channelConversion = {
  vendita: 'buy',
  affitto: 'rent',
  affitti: 'rent',
  'affitto-breve': 'rent',
};

export const categoryConversion = {
  residenziale: 'residential',
  commerciale: 'commercial',
  vacanze: 'holiday',
};


export const useTypeConversion = {
  case: 'residential',
  'nuove-costruzioni': 'residential',
  stanze: 'residential',
  'edifici-palazzi': 'residential',
  'garage-posti-auto': 'residential',
  'cantine-solai': 'residential',
  uffici: 'commercial',
  commerciale: 'commercial',
  capannoni: 'commercial',
  terreni: 'commercial',
  'case-vacanza': 'holiday',
};

export const depthConversion = {
  PSPE: 'platinum',
  PREM: 'gold',
  STOP: 'silver',
  TOP: 'bronze',
};

export const getSearchType = (query) => {
  if (!query || !(typeof query === 'object')) {
    return;
  }
  const queryKeys = Object.keys(query);

  const searchTypes = [];
  if (queryKeys.includes('localities') && query.localities) {
    searchTypes.push('localities');
  }
  if (queryKeys.includes('nearby')) {
    searchTypes.push('nearby');
  }
  if (queryKeys.includes('geopolygon')) {
    searchTypes.push('geopolygon');
  }
  if (queryKeys.includes('geocircle')) {
    searchTypes.push('geocircle');
  }
  if (queryKeys.includes('geobounds') && searchTypes.length === 0) {
    searchTypes.push('geobounds');
  }
  if (queryKeys.includes('poi')) {
    const poiTypes = Object.keys(query.poi).join('-');
    searchTypes.push(`poi${(poiTypes ? `-${poiTypes}` : '')}`);
  }

  return searchTypes.join('-');
};

export const getSearchSubType = (loc) => {
  if (!loc || !loc.level || loc.level < 4) {
    return NOT_AVAILABLE;
  }

  switch (loc.level) {
    case 6:
      return 'province';
    case 9:
      return 'city';
    case 10:
      return 'zone_block';
    case 11:
      return 'zone_block';
    case 118:
      return 'metro_line';
    case 120:
      return 'metro_stop';
    case 130:
      return 'nonadmin_zone';
    case 132:
      return 'nonadmin_subzone';
    default:
      return 'region';
  }
};

export const extractLocalityData = (location) => {
  const processedLocation = {};
  if ('L2' in location.levels) {
    processedLocation.propertyCountry = location.levels.L2;
  }
  if ('L4' in location.levels) {
    processedLocation.propertyRegion = location.levels.L4;
  }
  if ('L5' in location.levels) {
    processedLocation.propertyDepartment = location.levels.L5;
  }
  if ('L6' in location.levels) {
    processedLocation.propertyProvince = location.levels.L6;
  }
  if ('L11' in location.levels) {
    processedLocation.propertySubzone = location.levels.L11;
  }
  // if ('L120' in location.levels) {
  //   processedLocation.propertySubzone = location.levels.L120;
  // }
  if ('L8' in location.levels) {
    processedLocation.propertyIndustrialArea = location.levels.L8;
  }
  if ('L9' in location.levels) {
    processedLocation.propertyCity = location.levels.L9;
  }
  if ('L10' in location.levels) {
    processedLocation.propertyZone = location.levels.L10;
  }
  // if ('L118' in location.levels) {
  //   processedLocation.propertyZone = location.levels.L118;
  // }
  return processedLocation;
};

export const prepareLocationTypeSegment = (locationSegment, segmentItems) => {
  const data = segmentItems === NOT_AVAILABLE
    ? []
    : segmentItems.split('_');

  if (segmentItems.length < 150 && data.indexOf(locationSegment) === -1) {
    data.push(locationSegment);
  }
  if (data.join('_').length > 150) {
    data.pop();
  }
  return data.join('_');
};

export const extractPropertyTypes = (propertyTypes) => {
  let ptypesString = NOT_AVAILABLE;
  let usable = propertyTypes;
  if (usable && typeof usable === 'string') {
    usable = usable.split(',');
  }
  if (usable.length === 1) {
    [ptypesString] = usable;
  } else {
    ptypesString = `${usable[0]} +${usable.length - 1}`;
  }
  return ptypesString;
};

export const extractRange = (rangeType, queryFilters) => {
  let range = '';
  if (`${rangeType}Min` in queryFilters || `${rangeType}Max` in queryFilters) {
    if (`${rangeType}Min` in queryFilters) {
      range += queryFilters[`${rangeType}Min`];
    }
    if (`${rangeType}Max` in queryFilters) {
      range += `-${queryFilters[`${rangeType}Max`]}`;
    } else {
      range += '-';
    }
  } else {
    range = NOT_AVAILABLE;
  }
  return range;
};

export const qfNotFilters = [
  'page',
  'tr',
  'category',
  'propertyTypeGroup',
  // locality params
  'q',
  'nearby',
  // search on map params
  'geopolygon',
  'geocircle',
  'geobounds',
  // publisher param
  'pId',
];

export const notStandardFilters = [
  'priceMin',
  'priceMax',
  'mqpriceMin',
  'mqpriceMax',
  'paymentMin',
  'paymentMax',
  'mqMin',
  'mqMax',
  'numRoomsMin',
  'numRoomsMax',
  'buildingYearMin',
  'buildingYearMax',
  'numBaths',
  'exclude_private_negotiation',
  'only_private_negotiation',
  'only_auction',
  'exclude_auction',
  'poi',
];

export const freeTextTypes = [
  'freetext',
  'postalcode',
];
