import * as actions from './actions';

const initialState = {}; // new InitialState;

export default function subscriptionReducer(state = initialState, action) {
  switch (action.type) {

    case actions.FETCH_SUBSCRIPTION_START: {
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_SUBSCRIPTION_SUCCESS: {
      return Object.assign({}, action.payload, state, {
        isFetching: false,
        errorMessage: '',
      });
    }

    case actions.FETCH_SUBSCRIPTION_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_DEACTIVATION_START: {
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_DEACTIVATION_SUCCESS: {
      return Object.assign({}, action.payload, state, {
        isFetching: false,
        errorMessage: '',
      });
    }

    case actions.FETCH_DEACTIVATION_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload.message,
      });
    }

    case actions.EDIT_FREQUENCY_EMAIL_START: {
      return Object.assign({}, state, {
        isFetchingResults: true,
        errorMessage: '',
      });
    }

    case actions.EDIT_FREQUENCY_EMAIL_SUCCESS: {
      return Object.assign({}, state, action.payload, {
        isFetchingResults: false,
        errorMessage: '',
      });
    }

    case actions.EDIT_FREQUENCY_EMAIL_ERROR: {
      return Object.assign({}, state, {
        isFetchingResults: false,
        errorMessage: action.payload.message,
      });
    }
    
    default: {
      return state;
    }
  }
}
