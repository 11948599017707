import {
  isValidString,
} from 'shared-utils/src/checkVarType';

import {
  SEOPageTitlePtype,
} from '../../helpers/SEOPagesHelpers';

const homeSEOData = (filters, pathname) => {
  const {
    tr,
    category,
    propertyType,
    numRooms,
    buildingCondition,
    only_auction: onlyAuction,
  } = filters;
  const isListeAffitto = pathname === '/liste/affitto/';
  const singularTr = tr === 'affitti' ? 'affitto' : tr;
  const hasPtype = isValidString(propertyType);
  const hasPTGroup = category !== 'residenziale' || isValidString(buildingCondition);
  let title = isListeAffitto
    ? 'Case in affitto - Annunci immobiliari su Casa.it'
    : 'Case e appartamenti in vendita e in affitto - Annunci immobiliari su Casa.it';
  let description = isListeAffitto
    ? 'Trova la tua casa in affitto, in tutta Italia, con una suddivisione per provincia. Migliaia di annunci disponibili in affitto per ogni provincia italiana.'
    : 'Con oltre 1 milione di annunci immobiliari in vendita e in affitto, Casa.it è il luogo ideale dove acquistare o vendere casa. Se stai cercando un appartamento, un attico, una villetta o una villa qui puoi trovare l\'immobile perfetto';
  let canonical = '/';

  const hreflang = {
    it: pathname !== '/' ? pathname : '',
  };
  if (hasPtype) {
    title = `${SEOPageTitlePtype(propertyType, numRooms)} in ${singularTr} in tutta Italia - Casa.it`;
    description = `Trova ${SEOPageTitlePtype(propertyType, numRooms, true).toLowerCase()} in ${singularTr}, in tutta Italia, con una suddivisione per provincia. Migliaia di annunci di ${SEOPageTitlePtype(propertyType, numRooms).toLowerCase()} disponibili in ${singularTr} per ogni provincia italiana`;
    canonical = pathname;
    hreflang.it = pathname;
  } else if (hasPTGroup) {
    const catToUse = isValidString(buildingCondition) ? 'nuove-costruzioni' : category;
    title = `${SEOPageTitlePtype(catToUse, 0)} in ${singularTr} in tutta Italia - Casa.it`;
    description = `Trova ${SEOPageTitlePtype(catToUse, 0, true).toLowerCase()} in ${singularTr}, in tutta Italia, con una suddivisione per provincia. Migliaia di annunci di ${SEOPageTitlePtype(catToUse, 0).toLowerCase()} disponibili in ${singularTr} per ogni provincia italiana`;
    canonical = pathname;
    hreflang.it = pathname;
  } else if (onlyAuction) {
    title = 'Aste Giudiziarie - Annunci immobiliari su Casa.it';
    description = 'Trova le aste giudiziarie in Italia, con la suddivisione per provincia. Annunci di aste immobiliari corredate da foto e informazioni utili per ogni provincia';
    canonical = pathname;
    hreflang.it = pathname;
  }
  return {
    seo: {
      title,
      meta: {
        description: {
          content: description,
        },
      },
    },
    urls: {
      canonical,
      hreflang,
    },
    robots_index: true,
  };
};
export default homeSEOData;
