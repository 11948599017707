import { canUseDOM } from '../../components';

export const FETCH_SEARCH_AROUND_YOU_CLIENT = 'FETCH_SEARCH_AROUND_YOU_CLIENT';
export const FETCH_SEARCH_AROUND_YOU = 'FETCH_SEARCH_AROUND_YOU';
export const FETCH_SEARCH_AROUND_YOU_START = 'FETCH_SEARCH_AROUND_YOU_START';
export const FETCH_SEARCH_AROUND_YOU_SUCCESS = 'FETCH_SEARCH_AROUND_YOU_SUCCESS';
export const FETCH_SEARCH_AROUND_YOU_ERROR = 'FETCH_SEARCH_AROUND_YOU_ERROR';

const fetchFn = (fetch: Function, verb: string, params: Object, uripath: string = '') => fetch(`/portal-srp/api/v1/cerca-intorno-a-te/`, {
  method: verb,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Connection: 'keep-alive',
  },
  body: JSON.stringify(params),
  credentials: 'same-origin',
});

export function fetchSearchAroundYou(params: Object) {
  return ({ fetch }: { fetch: Function }) => ({
    type: FETCH_SEARCH_AROUND_YOU,
    payload: {
      search: 'something',
      promise: fetchFn(fetch, 'POST', params)
        .then(response => response.json())
        .catch(err => console.error('error', err)),
    },
  });
}
