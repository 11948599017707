import React from 'react';

import {
  DEFAULT_FILTERS_CONFIGURATION,
  propTypeGroupRent, propTypeGroupValuesRent,
} from 'shared-constants/src/filters';
import { filterOldCategory } from 'shared-constants/src/filters/old';
import { FILTERS_TO_EXCLUDE_FROM_DISPLAY_COUNT, filterSectionNames } from 'shared-constants/src/filters/utils';
import { filterBoxesConfiguration } from 'shared-constants/src/filters/groups';
import { isArray, isValidVar } from 'shared-utils/src/checkVarType';
import isEqual from '@lodash/isEqual';
import includes from '@lodash/includes';
import { formatCurrency } from '@common/i18n/helpers';

export const boldWrap = (text, additionaClass = null) => (<b key={text} className={`tp--b c-txt--f0 tp--ell${additionaClass ? ` ${additionaClass}` : ''}`}>{text}</b>);

export const checkDisableFilters = ({ filter, queryFilters, filterConf }) => {

  Object.keys(queryFilters).forEach((key) => {
    switch (key) {
      case 'only_private_negotiation':
        if (queryFilters[key]) {
          if (
            filter === 'price' ||
            filter === 'priceType' ||
            filter === 'payment' ||
            filter === 'mqprice' ||
            filter === 'exclude_private_negotiation'
          ) {
            filterConf.disableFromConf = true;
          }
        }
        break;
      case 'exclude_private_negotiation':
      case 'priceMin':
      case 'priceMax':
      case 'paymentMin':
      case 'paymentMax':
      case 'mqpriceMin':
      case 'mqpriceMax':
        if (queryFilters[key] && queryFilters[key] !== 'all') {
          if (filter === 'only_private_negotiation') {
            filterConf.disableFromConf = true;
          }
        }
        break;
      default:
        break;
    }
  });

};

export const getFilterData = (name) => {
  let result = DEFAULT_FILTERS_CONFIGURATION[name];

  // Adding new keys in DEFAULT_FILTERS_CONFIGURATIONS seems to break MyCasa
  // So I'm gonna add this amazing check
  if (name === 'category-affitto-breve') {
    result = propTypeGroupRent;
  } else if (name === 'oldcategory') {
    result = filterOldCategory;
  }

  return result;
};

export const getDisplayValue = (filter, value) => {
  const filterData = getFilterData(filter) || {};
  let shortRentWorkaround = value;

  // as in getFilterData
  // Adding new keys in DEFAULT_FILTERS_CONFIGURATIONS seems to break MyCasa
  // So I'm gonna add this amazing check
  if (filter === 'category-affitto-breve' && value === 'case') {
    shortRentWorkaround = 'case-vacanza';
  }

  const val = (filterData.values || []).filter(item => item.value === shortRentWorkaround)[0];
  return val && val.display;
};

/**
 * Returns the values as a preview.
 * Used as previews in filter sections
 * @param {Object} filters Current selected filters
 * @param {String} filterName Filter name
 * @param {String} filterValue Current filter value
 * @returns {String}
 */
export const getPreviewFromFilters = (filters, filterName, filterValue, isAgencySrp) => {
  let result = getDisplayValue(filterName, filterValue);
  const { channel } = filters;

  // Handle exceptions
  switch (filterName) {

    case 'channel':
      if (!filterValue) {
        result = 'Vendita, Affitto, Affitto breve';
      }
      if (isAgencySrp && filterValue === 'vendita') {
        result = 'Comprare';
      }
      break;
    case 'category':
      if (channel === 'affitto-breve') {
        result = getDisplayValue('category-affitto-breve', filterValue);
      }

      // if (propertyTypes.length === 1) {
      //   [result] = propertyTypes;
      // } else if (propertyTypes.length > 1) {
      //   result = propertyTypes.join(', ');
      // }
      break;
  }

  return result;
};

export const getValuesExcluded = (values, excludeValues, toExcludeKeys, filters = {}) => values.filter((filt) => {
  const valuesToExclude = toExcludeKeys.reduce((obj, ex) => {
    ex === filt.value && obj.push(excludeValues[ex]);
    return obj;
  }, []);
  let toExclude = false;

  valuesToExclude.map((exc) => {
    const excludeArr = Array.isArray ? exc : [exc];

    excludeArr.forEach((exclude) => {
      const valueFilter = filters[exclude.type];

      if (valueFilter && valueFilter === exclude.value) {
        toExclude = true;
      }
    });
  });

  return !toExclude;
});

export const checkExcludeValues = (originalValues, valuesDependsOn, excludeValues = {}, dependsOn, filters = {}) => {
  let toExcludeKeys = Object.keys(excludeValues);
  let result = valuesDependsOn;

  if (toExcludeKeys.length && Array.isArray(originalValues)) {
    result = getValuesExcluded(originalValues, excludeValues, toExcludeKeys, filters);
  } else if (toExcludeKeys.length && !Array.isArray(originalValues) && dependsOn) {
    const keyType = filters[dependsOn];
    const valueToExcludeSelected = excludeValues[keyType];

    if (valueToExcludeSelected) {
      toExcludeKeys = Object.keys(valueToExcludeSelected);
      result = getValuesExcluded(valuesDependsOn, valueToExcludeSelected, toExcludeKeys, filters);
    }
  }

  return result;
};

/**
 * Get values from specific  filter
 */
export const getValues = (filter, filters = {}) => {
  const filterData = getFilterData(filter);
  const { dependsOn, excludeValues } = filterData;
  let result = filterData.values || [];
  if (dependsOn) {
    if (filters[dependsOn]) {
      result = filterData.values[filters[dependsOn]];
    } else if (filter === 'price') {
      result = filterData.values.vendita;
    }
  }

  result = checkExcludeValues(filterData.values, result, excludeValues, dependsOn, filters);

  // Handle exceptions
  switch (filter) {
    case 'category':
      // if (filters.pId) {
      //   result = getFilterData('oldcategory').values;
      // }
      if (filters.channel === 'affitto-breve') {
        result = propTypeGroupValuesRent;
      }
      break;
    case 'propertyTypes':
      result = !isArray(result) ? [] : result;
      break;
    case 'priceType':
      // exclude mortgage rate filter for the following propertyTypeGroups & propertyTypes
      if (
        [
          'commerciale',
          'terreni',
          'stanze',
          'garage-posti-auto',
        ].indexOf(filters.propertyTypeGroup) > -1
        // || (filters.propertyTypes.length === 1 && filters.propertyTypes[0] === 'Attività/Licenza commerciale')
      ) {
        result = [...result].slice(0, 2);
      }
      break;
  }

  return result;
};

export const getActiveFilters = (filtersList) => {
  const filtersData = DEFAULT_FILTERS_CONFIGURATION;
  const activeFiltersList = {};
  let activeFiltersCount = 0;

  Object.keys(filtersList).forEach((filterName) => {
    if (filterName in filtersData) {
      const filterList = filtersList[filterName];
      const { defaultValue } = filtersData[filterName];
      switch (typeof filterList) {
        case 'string':
        case 'boolean':
          if (filterList !== defaultValue) {
            activeFiltersList[filterName] = filterList;
            if (FILTERS_TO_EXCLUDE_FROM_DISPLAY_COUNT.indexOf(filterName) === -1) {
              activeFiltersCount += 1;
            }
          }
          break;
        default:
          if (!isEqual(filterList, defaultValue)) {
            activeFiltersList[filterName] = filterList;
            if (FILTERS_TO_EXCLUDE_FROM_DISPLAY_COUNT.indexOf(filterName) === -1) {
              activeFiltersCount += 1;
            }
          }
      }
    } else if (filterName.indexOf('Min') > -1 || filterName.indexOf('Max') > -1) {
      const isMin = filterName.indexOf('Min') > -1;
      const parentFilterName = filterName.replace('Min', '').replace('Max', '');
      const filterData = filtersData[parentFilterName];

      if (filterData) {
        if (isMin) {
          if (filtersList[`${parentFilterName}Min`] !== filterData.defaultMinValue) {
            activeFiltersList[`${parentFilterName}Min`] = filtersList[`${parentFilterName}Min`];
            activeFiltersCount += 1;
          }
        } else if (filtersList[`${parentFilterName}Max`] !== filterData.defaultMaxValue) {
          activeFiltersList[`${parentFilterName}Max`] = filtersList[`${parentFilterName}Max`];
          if (!(`${parentFilterName}Min` in activeFiltersList)) {
            activeFiltersCount += 1;
          }
        }
      }
    }
  });

  if (filtersList.poi) {
    activeFiltersList.poi = filtersList.poi;
    activeFiltersCount += 1;
  }

  return {
    activeFiltersList,
    activeFiltersCount,
  };
};

export const getActiveFiltersCountWithPTypes = (filters) => {
  let { activeFiltersCount } = getActiveFilters(filters);
  if (filters.propertyTypes && filters.propertyTypes.length) {
    activeFiltersCount += 1;
  }
  return activeFiltersCount;
};

export const getFilterBoxes = () => ({
  box1: Object.keys(filterBoxesConfiguration.box1),
  box2: Object.keys(filterBoxesConfiguration.box2),
  box3: Object.keys(filterBoxesConfiguration.box3),
  box4: Object.keys(filterBoxesConfiguration.box4),
  box5: Object.keys(filterBoxesConfiguration.box5),
});

export const getFilterLists = (visible) => {
  const {
    box1,
    box2,
    box3,
    box4,
    box5,
  } = getFilterBoxes();

  const allFilters = [
    ...box1,
    ...box2,
    ...box3,
    ...box4,
    ...box5,
  ];

  const hiddenFilters = allFilters.slice(visible - 1);

  return {
    allFilters,
    hiddenFilters,
    box1,
    box2,
    box3,
    box4,
    box5,
  };
};

// Based on filter configuration, reset hidden to default values
export const resetHiddenFilters = (filters) => {
  const filterKeys = Object.keys(filters);
  return filterKeys.reduce((acc, filter) => {
    const conf = DEFAULT_FILTERS_CONFIGURATION[filter];
    const isHidden = conf?.isVisible && !conf.isVisible(filters);
    acc[filter] = isHidden ? conf?.defaultValue : filters[filter];
    return acc;
  }, {});
};

export const createFilterLabels = (filterName, filterValues, channel, isAgencySrp) => {
  let result;

  const labelFrom = 'da';
  const labelTo = 'a';

  const getFirstValidValue = (types, apx) => types
    .filter(type => filterValues[`${type}${apx}`])
    .map(type => filterValues[`${type}${apx}`])[0];

  const createPriceDescr = () => {
    const descr = (filterValues.mqpriceMax || filterValues.mqpriceMin) ? ' al mq' :
      (filterValues.paymentMax || filterValues.paymentMin) ? ' al mese' : '';
    return descr;
  };

  switch (filterName) {
    case 'category': {
      let value = filterValues.category || filterValues.propertyTypeGroup || getFilterData('category').defaultValue;
      value = getPreviewFromFilters({ channel }, 'category', value);

      result = !!value && <>Cerco {boldWrap(value)}</>;
      break;
    }
    case 'channel': {
      const defValue = isAgencySrp
        ? 'Comprare'
        : getFilterData('channel').defaultValue;
      const value = filterValues.channel || defValue;
      result = !!value && <>${isAgencySrp ? 'per' : 'in'} {boldWrap(getDisplayValue('channel', value))}</>;
      break;
    }
    case 'price': {
      const priceTypes = getFilterData('priceType').values.map(type => type.value);
      const from = getFirstValidValue(priceTypes, 'Min');
      const to = getFirstValidValue(priceTypes, 'Max');
      const renderCurr = (curr, pfx = '') => pfx
        ? <>{pfx}&nbsp;{boldWrap(formatCurrency(curr).replace(',00', ''))}</>
        : <>{boldWrap(formatCurrency(curr).replace(',00', ''))}</>;
      const typePrice = createPriceDescr(from, to);
      const tr = filterValues.notrattris ? '(escl. Trattat. riserv.)' : null;
      result = !!(from || to) &&
        from && to ?
        <>{renderCurr(from, labelFrom)}&nbsp;{renderCurr(to, labelTo)}&nbsp;{typePrice}&nbsp;{tr}</> :
        from ?
          <>a partire&nbsp;{renderCurr(from, labelFrom)}&nbsp;{typePrice}&nbsp;{tr}</> :
          to ?
            <>con budget di&nbsp;{renderCurr(to)}&nbsp;{typePrice}&nbsp;{tr}</> :
            tr ? <>Prezzo&nbsp;{boldWrap(tr)}</> : '';
      break;
    }
    case 'mq': {
      const from = filterValues.mqMin;
      const to = filterValues.mqMax;
      result = !!(from || to) &&
        from && to ?
        <>da&nbsp;{boldWrap(from)}&nbsp;a&nbsp;{boldWrap(`${to} mq`)}</> :
        from ?
          <>da almeno&nbsp;{boldWrap(`${from} mq`)}</> :
          to ?
            <>al massimo&nbsp;{boldWrap(`${to} mq`)}</> : '';
      break;
    }
    case 'rooms': {
      const { numRoomsMin, numRoomsMax } = filterValues;
      const from = numRoomsMin && parseFloat(numRoomsMin);
      const to = numRoomsMax && parseFloat(numRoomsMax);
      const sub = 'locale';
      const subs = 'locali';
      result = !!(from || to) &&
        from && to ?
        <>da&nbsp;{boldWrap(from)}&nbsp;a&nbsp;{boldWrap(`${to} ${subs}`)}</> :
        from ?
          <>con almeno&nbsp;{boldWrap(`${from} ${from > 1 ? subs : sub}`)}</> :
          to ?
            <>con massimo&nbsp;{boldWrap(`${to} ${to > 1 ? subs : sub}`)}</> : '';

      if (from && to && from === to) {
        if (to === 1) {
          result = <>solo&nbsp;{boldWrap('Monolocali')}</>;
        } else if (to === 2) {
          result = <>solo&nbsp;{boldWrap('Bilocali')}</>;
        } else if (to === 3) {
          result = <>solo&nbsp;{boldWrap('Trilocali')}</>;
        } else if (to === 4) {
          result = <>solo&nbsp;{boldWrap('Quadrilocali')}</>;
        } else {
          result = <>solo&nbsp;{boldWrap(`${to} ${subs}`)}</>;
        }
      }

      break;
    }
    case 'baths': {
      const value = filterValues.numBaths;
      const label = value > 1 ? 'bagni' : 'bagno';
      result = !!value && <>con almeno&nbsp;{boldWrap(`${value} ${label}`)}</>;
      break;
    }
    case 'numParkingSpaces': {
      const value = filterValues.numParkingSpaces;
      const label = value > 1 ? 'box / posti auto' : 'box / posto auto';
      result = !!value && <>con almeno {boldWrap(`${value} ${label}`)}</>;
      break;
    }
    case 'level': {
      const value = filterValues.level;
      const getStrValue = (val) => { // eslint-disable-line
        switch (val) {
          case 'piano terra':
            return <>al&nbsp;{boldWrap('piano terra')}</>;
          case 'intermedio':
            return <>a un&nbsp;{boldWrap('piano intermedio')}</>;
          case 'ultimo':
            return <>all'{boldWrap('ultimo piano')}</>;
        }
      };
      const strVal = getStrValue(value);
      result = !!value && (strVal || <>dal&nbsp;{boldWrap(`${value}° piano in su`)}</>);
      break;
    }
    case 'lift': {
      const value = filterValues.lift;
      let subValue;

      if (value === true || value === 'true') {
        subValue = <>con&nbsp;{boldWrap(`l'ascensore`)}</>; // eslint-disable-line
      } else if (value === false || value === 'false') {
        subValue = boldWrap('senza ascensore');
      } else if (!isNaN(value)) {
        const title = `con ascensore, oltre ${value}° piano`;
        subValue = <span title={title}>con&nbsp;{boldWrap('ascensore')}, oltre il&nbsp;{boldWrap(`${value}° piano`)}</span>;
      }

      result = !!subValue && subValue;
      break;
    }
    case 'heatingType': {
      const value = filterValues.heatingType || [];
      const getStrValue = (val) => {
        const labelAut = 'autonomo';
        const labelCent = 'centralizzato';
        const inclAut = includes(val, labelAut);
        const inclCent = includes(val, labelCent);
        let res = '';

        if (inclAut && inclCent) {
          res = 'riscaldamento';
        } else if (inclAut) {
          res = 'riscaldamento autonomo';
        } else if (inclCent) {
          res = 'riscaldamento centralizzato';
        }

        return res;
      };
      result = value.length && <>con&nbsp;{boldWrap(getStrValue(value))}</>;
      break;
    }
    case 'garden': {
      const value = filterValues.garden || [];
      const getStrValue = (val) => {
        const label1 = 'comune';
        const label2 = 'privato';
        const incl1 = includes(val, label1);
        const incl2 = includes(val, label2);
        let res = '';

        if (incl1 && incl2) {
          res = 'giardino';
        } else if (incl1) {
          res = 'giardino comune';
        } else if (incl2) {
          res = 'giardino privato';
        }

        return res;
      };
      result = value.length && <>con&nbsp;{boldWrap(getStrValue(value))}</>;
      break;
    }

    case 'energyClass': {
      const value = filterValues.energyClass;
      result = !!value && <>con&nbsp;{boldWrap('classe energetica')}&nbsp;almeno&nbsp;{boldWrap(value)}</>;
      break;
    }
    case 'buildingCondition': {
      const value = filterValues.buildingCondition || [];
      const values = [];
      let subValue;

      if (includes(value, 'nuovo-ristrutturato')) {
        values.push('ottime condizioni');
      }
      if (includes(value, 'abitabile')) {
        values.push('buone condizioni');
      }
      if (includes(value, 'da-ristrutturare')) {
        values.push('da ristrutturare');
      }

      if (values.length) {
        subValue = (
          <>
            in {values.map(el => boldWrap(el, el)).reduce(
              (prev, curr) => <>{prev}, {curr}</>,
            )}
          </>
        );
      }

      result = subValue;
      break;
    }
    case 'buildingYear': {
      const from = filterValues.buildingYearMin;
      const to = filterValues.buildingYearMax;
      result = !!(from || to)
        && (
          <>
            Costruita
            {
              from && to ?
                <> tra il&nbsp;{boldWrap(from)}&nbsp;e il&nbsp;{boldWrap(to)}</> :
                from ?
                  <> a partire dal&nbsp;{boldWrap(from)}</> :
                  to ?
                    <> entro il&nbsp;{boldWrap(to)}</> : ''
            }
          </>
        );
      break;
    }
    case 'availability': {
      let value;

      switch (filterValues.availability) {
        case 'occupato':
          value = 'Occupata al rogito';
          break;
        case 'libero':
          value = 'Libera al rogito';
          break;
        case 'nuda-proprieta':
        case 'nuda proprieta':
          value = 'in Nuda Proprietà';
          break;
        case 'affittato':
          value = 'Affittata al rogito';
          break;
      }

      result = !!value && boldWrap(value);
      break;
    }
    case 'sellerType': {
      const sellers = filterValues.sellerType || [];
      result = sellers.length
        ? <>da&nbsp;{sellers.map(el => boldWrap(el, el)).reduce((prev, curr) => <>{prev}, {curr}</>)}</>
        : null;
      break;
    }

    /*
      TECH-139 - filtri
    */

    case 'furniture': {
      const { furniture } = filterValues;
      const value = furniture || [];
      const values = {
        none: 'senza arredamento',
        partially: 'parzialmente arredato',
        full: 'arredato',
      };
      if (value.length === 3) {
        result = <>{boldWrap('senza arredamento')},&nbsp;{boldWrap('con arredamento parziale')}&nbsp;o&nbsp;{boldWrap('con arredamento')}</>;
      } else if (value.length === 2) {
        if (!includes(value, 'none')) {
          result = <>{boldWrap('arredato')}&nbsp;o&nbsp;{boldWrap('parzialmente arredato')}</>;
        } else if (includes(value, 'partially')) {
          result = <>{boldWrap('senza arredamento')}&nbsp;o&nbsp;{boldWrap('parzialmente arredato')}</>;
        } else {
          result = <>con o senza&nbsp;{boldWrap('arredamento')}</>;
        }
      } else if (value.length) {
        result = <>{boldWrap(values[value[0]])}</>;
      }
      break;
    }

    // case 'air_conditioned': {
    //   const { air_conditioned } = filterValues;
    //   if (air_conditioned) {
    //     result = <>Solo <strong>Immobili all'asta</strong></>;
    //   } else if (air_conditioned === false) {
    //     result = <>Esclusi <strong>Immobili all'asta</strong></>;
    //   }
    //   break;
    // }

    case 'publication_date': {
      const { publication_date } = filterValues;
      const values = {
        '2d': 'ultime 48 ore',
        '7d': 'ultima settimana',
        '30d': 'ultimo mese',
      };

      const articles = {
        '2d': 'nelle ',
        '7d': 'nell\'',
        '30d': 'nell\'',
      };
      if (publication_date && articles[publication_date]) {
        result = <>{articles[publication_date]}{boldWrap(values[publication_date])}</>;
      } else {
        result = null;
      }
      break;
    }

    /*
      TECH-139 - filtri
    */

    case 'is_auction': {
      const { is_auction } = filterValues;
      if (is_auction) {
        result = <>Solo&nbsp;{boldWrap('Immobili all\'asta')}</>;
      } else if (is_auction === false) {
        result = <>Esclusi&nbsp;{boldWrap('Immobili all\'asta')}</>;
      }
      break;
    }

    case 'balconyAndTerrace': {
      const { balconyAndTerrace } = filterValues;
      if (balconyAndTerrace) {
        const { length } = balconyAndTerrace;
        const [value] = balconyAndTerrace;
        if (length && value) {
          if (length === 2) {
            result = <>con&nbsp;{boldWrap('balcone')}&nbsp;o&nbsp;{boldWrap('terrazzo')}</>;
          } else if (value === 'terrazzo') {
            result = <>con&nbsp;{boldWrap('terrazzo')}</>;
          } else {
            result = <>con&nbsp;{boldWrap('balcone')}</>;
          }
        }
      }
      break;
    }

    case 'includes_property_ownership': {
      const { includes_property_ownership } = filterValues;
      if (includes_property_ownership) {
        result = <>solo&nbsp;{boldWrap(`${includes_property_ownership === 'true' ? 'con' : 'senza'} mura`)}</>;
      }
      break;
    }

    case 'commercial_building_location': {
      const { commercial_building_location } = filterValues;
      if (commercial_building_location) {
        const valuesArr = !Array.isArray(commercial_building_location)
          ? commercial_building_location.split(',')
          : commercial_building_location;
        const displayValues = valuesArr.map(val => DEFAULT_FILTERS_CONFIGURATION.commercial_building_location.values.filter(el => el.value === val)[0].display);
        const last = displayValues.pop();
        result = <>solo&nbsp;{boldWrap(`${displayValues.join(', ')}${displayValues.length ? ' e ' : ''}${last}`)}</>;
      }
      break;
    }

    default: break;
  }

  const defaulFilterName = () => {
    if (filterName !== 'price') {
      return filterSectionNames[filterName];
    }

    return channel && channel !== 'vendita'
      ? filterSectionNames[`${filterName}_rent`]
      : filterSectionNames[filterName];
  };

  return {
    result: result || defaulFilterName(),
    isActive: (isValidVar(result) && result !== '' && result !== 0 && result !== false),
  };
};
