import parseJSON from 'shared-utils/src/parseJSON';
import getStore from '@common/store';
import selectorSearch from '@common/state/selectors/search';
import { getItem, setItem } from '@helpers/Storage';
import { randomStr } from '@helpers';

let categoriesMap;

const getCategoriesMap = () => {
  const store = getStore();
  if (!categoriesMap && store) {
    categoriesMap = selectorSearch(store.getState(), 'allPoi');
  }

  return categoriesMap || {};
};

export const getCategoriesMapItem = id => getCategoriesMap()[id] || {};

export const comprehensiveSchoolDefinitions = {
  name: 'Istituto comprensivo',
  short_name: 'istituto comprensivo',
  singular_name: 'Istituto comprensivo',
  icon: '',
  marker: '/portal-srp/assets/img/map/mapPois/comprensivo.png',
  maxDistance: 500,
  priority: 500,
};

/**
 * Get POI category name
 * @param {String} id
 * @returns {String} POI category name
 * @example
 * getPoiCategoryName('metros')
 * // -> 'Fermata della metro'
 */
export const getPoiCategoryList = () => Object.keys(getCategoriesMap());

export const getPoiCategoryName = id => getCategoriesMapItem(id).pluralNameFull?.toLowerCase() || '';

export const getPoiCategoryShortName = id => getCategoriesMapItem(id).shortName || '';

export const getPoiSingularName = id => getCategoriesMapItem(id).singularName || '';

export const getPoiFullSingularName = id => getCategoriesMapItem(id).singularNameFull || '';

export const getPoiCategoryMaxDistance = id => getCategoriesMapItem(id).maxDistance || 0;

export const getPoiCategoryIcon = id => getCategoriesMapItem(id)?.icon?.sharedVector || '';

export const getPoiSingularFromName = id => getCategoriesMapItem(id).singularFromName || {};

export const getPoiCategoryMarker = (id) => {
  const config = selectorSearch(getStore().getState(), 'config');
  const basePath = config?.remote?.basePathAssets || '';
  const base = basePath.slice(-1) === '/' ? basePath.slice(0, -1) : basePath;
  const filepath = getCategoriesMapItem(id)?.marker?.filePath || '';
  return filepath && `${base}${filepath}.svg`;
};

/**
 * Return an array of POI data models from array of IDs
 * @param {Array<String>} ids Array of IDs, eg. ['shop', 'bus', ...]
 * @returns {Array<Object>}
 * @example
 * getFromIds(['shop', 'bus']);
 * // -> [ {name: 'Shop name', ...}, {...}]
 */
export const getFromIds = ids => (ids || [])
  .map((id) => {
    const items = getCategoriesMap();
    return items[id] && items[id]?.enabledOn?.filters && {
      id,
      ...items[id],
    };
  })
  .filter(item => item);


/**
 * Return an array of POI data models from array returned from API
 * @param {Array<Object>} data
 * @returns {Array<Object>}
 * @example
 * getFromApiResult([ { "asili": 2 }, { "scuole": 2 } ])
 * // -> [{ name: 'Asili nido', ...}, {...}]
 */
export const getFromApiResult = (data) => {
  const usableData = (data && Array.isArray(data) ? data : []).filter(item => item[Object.keys(item)[0]] > 0);
  return getFromIds(
    usableData.map(item => Object.keys(item)[0]),
  ).sort((a, b) => a.priority > b.priority ? 1 : -1);
};

export const fromArrayToObject = poiArray => (
  poiArray.reduce((acc, id) => {
    acc[id] = { lte: getPoiCategoryMaxDistance(id) };
    return acc;
  }, {})
);

// Vehicles

export const vehicles = {
  walk: {
    art: 'a',
    name: 'piedi',
  },
  bike: {
    art: 'in',
    name: 'bicicletta',
  },
  car: {
    art: 'in',
    name: 'macchina',
  },
  // transit: {
  //   art: 'con i',
  //   name: 'mezzi pubblici',
  // },
};

export const vehiclesOpts = Object.keys(vehicles).map(id => ({
  value: id,
  display: `${vehicles[id].art} ${vehicles[id].name}`,
}));

export const getVehicleArt = id => (vehicles[id] || {}).art;
export const getVehicleName = id => (vehicles[id] || {}).name;


// Places

export const places = {
  'dove-vivo': {
    name: 'Dove vivo',
    icon: 'home',
  },
  'dove-lavoro': {
    name: 'Dove lavoro',
    icon: 'briefcase',
  },
};

export const getPlaceName = id => (places[id] || {}).name;


// NearBy
export const nearByKeyId = 'valueId';
export const nearByKeyName = 'valueName';
export const nearByKeyMin = 'valueMin';
export const nearByKeyVehicle = 'valueVehicle';
export const nearByKeyCity = 'valueCity';
export const nearByKeyAddress = 'valueAddress';
export const nearByKeyStreetNum = 'valueStreetNum';
export const nearByKeyData = 'apiData';

export const storageKeyNearBy = '_nearBy';

export const getCompiledNearBy = () => parseJSON(getItem(storageKeyNearBy)) || {};

export const getNearByMin = item => item[nearByKeyMin];
export const getNearByVehicle = item => item[nearByKeyVehicle];
export const getNearByCity = item => item[nearByKeyCity];
export const getNearByAddress = item => item[nearByKeyAddress];
export const getNearByStreetNum = item => item[nearByKeyStreetNum];
export const getNearByData = item => item[nearByKeyData];

export const getNewCustomNearById = () => `custom-${randomStr()}`;

export const createNearBy = (item) => {
  const {
    id, min, vehicle, city, address, streetNum, name, data, add = {},
  } = item;

  return {
    [nearByKeyId]: id,
    [nearByKeyMin]: [min],
    [nearByKeyVehicle]: [vehicle],
    [nearByKeyCity]: { name: city, id },
    [nearByKeyAddress]: {
      id,
      description: address,
      structured_formatting: { main_text: address },
    },
    [nearByKeyStreetNum]: streetNum || 'snc',
    [nearByKeyName]: name || 'Punto condiviso',
    [nearByKeyData]: data,
    ...add,
  };
};

export const findNearBy = (item, items = []) => {
  const isFull = !!(item[nearByKeyData] && item[nearByKeyData].location);
  const data = isFull ? item[nearByKeyData] : item;
  const loc = data ? data.location : {};
  const itemsArr = Array.isArray(items) ? items : Object.keys(items).map(i => items[i]);

  return itemsArr.filter((it) => { // eslint-disable-line
    const dataList = it[nearByKeyData];
    const locList = dataList.location || {};
    return locList.lat === loc.lat &&
          locList.lon === loc.lon &&
          dataList.travel_mode === data.travel_mode &&
          dataList.travel_time === data.travel_time;
  })[0];
};

export const ensureNearBy = (item, items = [], resolvedNearBy) => {
  let result;
  const isFull = !!(item[nearByKeyData] && item[nearByKeyData].location);
  const loc = (item && item.location) || (item[nearByKeyData] && item[nearByKeyData].location);
  const localNearBy = getCompiledNearBy();
  const itemsArrLocal = Object.keys(localNearBy).map(i => localNearBy[i]);
  let itemsArr = Array.isArray(items) ? items : Object.keys(items).map(i => items[i]);
  itemsArr = itemsArr.length ? itemsArr : itemsArrLocal;
  const searchItem = (arr) => {
    result = findNearBy(item, arr) || result;
  };

  // Find item by location
  if (loc) {
    searchItem(itemsArr);
    !result && searchItem(itemsArrLocal);
  }

  // Add custom item to localStorage
  if (loc && !result && resolvedNearBy) {
    const id = getNewCustomNearById();
    const { levels = {} } = resolvedNearBy;
    const min = isFull ? item[nearByKeyMin][0] : item.travel_time;
    const vehicle = isFull ? item[nearByKeyVehicle][0] : item.travel_mode;
    const data = isFull ? item[nearByKeyData] : item;
    result = createNearBy({
      id,
      min,
      vehicle,
      city: levels.L9,
      address: levels.L10.street,
      streetNum: levels.L10.street_number,
      data,
    });
    setItem(storageKeyNearBy, JSON.stringify({
      ...localNearBy,
      [id]: result,
    }));
  }

  return result || item;
};

// Get name from a selected POI distance item
export const getNearByName = (item, items = []) => {
  if (items.length > 0 || Object.keys(items).length > 0) {
    item = ensureNearBy(item, items);
  }

  const id = item[nearByKeyId];

  return getPlaceName(id) || item[nearByKeyName] || '';
};

/**
 * Sometimes geocoding returns a lon similar to this: 9.180453199999999
 * but the nearby api round it to: 9.1804532
 */
export const fixGeoPoint = (point) => {
  const res = parseFloat(point).toFixed(7);
  return parseFloat(res);
};

/**
 * Transform local NearBy object to the one for API
 */
export const nearbyLocalToApi = (item) => {
  const nearById = item[nearByKeyId];
  const apiData = item[nearByKeyData];
  const { location } = item[nearByKeyData];
  let type = 'custom';
  let label = item[nearByKeyName];

  if (nearById === 'dove-vivo') {
    type = 'home';
    label = 'h';
  } else if (nearById === 'dove-lavoro') {
    type = 'work';
    label = 'w';
  }

  return {
    // nearById,
    label,
    time_gap: apiData.travel_time,
    transport: apiData.travel_mode,
    town: getNearByCity(item).name,
    type,
    address: getNearByAddress(item).structured_formatting.main_text,
    number: item[nearByKeyStreetNum],
    updateDt: new Date().toISOString(),
    lat: location.lat,
    lon: location.lon,
    // deleteDt: null,
  };
};

/**
 * Transform API NearBy object to the local one
 */
export const nearbyApiToLocal = (item, { id } = {}) => {
  const {
    type, time_gap, transport, lat, lon, nearById, updateDt,
  } = item;
  let resId = id;

  if (!resId) {
    resId = getNewCustomNearById();
    type === 'home' && (resId = 'dove-vivo');
    type === 'work' && (resId = 'dove-lavoro');
  }

  return createNearBy({
    id: resId,
    name: item.label,
    min: time_gap,
    vehicle: transport,
    city: item.town,
    address: item.address,
    streetNum: item.number,
    data: {
      location: {
        lat: parseFloat(lat),
        lon: parseFloat(lon),
      },
      travel_mode: transport,
      travel_time: time_gap,
    },
    add: {
      nearById,
      updateDt,
    },
  });
};
