/* eslint-disable no-console */
// @flow
export const FETCH_SEARCHMAP = 'FETCH_SEARCHMAP';
export const FETCH_SEARCHMAP_START = 'FETCH_SEARCHMAP_START';
export const FETCH_SEARCHMAP_SUCCESS = 'FETCH_SEARCHMAP_SUCCESS';
export const FETCH_SEARCHMAP_ERROR = 'FETCH_SEARCHMAP_ERROR';

export const FETCH_SEARCHMAPSCROLL = 'FETCH_SEARCHMAPSCROLL';
export const FETCH_SEARCHMAPSCROLL_START = 'FETCH_SEARCHMAPSCROLL_START';
export const FETCH_SEARCHMAPSCROLL_SUCCESS = 'FETCH_SEARCHMAPSCROLL_SUCCESS';
export const FETCH_SEARCHMAPSCROLL_ERROR = 'FETCH_SEARCHMAPSCROLL_ERROR';

export const FETCH_SEARCHMAP_FILTERS = 'FETCH_SEARCHMAP_FILTERS';
export const FETCH_SEARCHMAP_FILTERS_START = 'FETCH_SEARCHMAP_FILTERS_START';
export const FETCH_SEARCHMAP_FILTERS_SUCCESS = 'FETCH_SEARCHMAP_FILTERS_SUCCESS';
export const FETCH_SEARCHMAP_FILTERS_ERROR = 'FETCH_SEARCHMAP_FILTERS_ERROR';

export const FETCH_SEARCHMAP_GEO = 'FETCH_SEARCHMAP_GEO';
export const FETCH_SEARCHMAP_GEO_START = 'FETCH_SEARCHMAP_GEO_START';
export const FETCH_SEARCHMAP_GEO_SUCCESS = 'FETCH_SEARCHMAP_GEO_SUCCESS';
export const FETCH_SEARCHMAP_GEO_ERROR = 'FETCH_SEARCHMAP_GEO_ERROR';

export const FETCH_SEARCHMAP_GEO_BOUNDS = 'FETCH_SEARCHMAP_GEO_BOUNDS';
export const FETCH_SEARCHMAP_GEO_BOUNDS_START = 'FETCH_SEARCHMAP_GEO_BOUNDS_START';
export const FETCH_SEARCHMAP_GEO_BOUNDS_SUCCESS = 'FETCH_SEARCHMAP_GEO_BOUNDS_SUCCESS';
export const FETCH_SEARCHMAP_GEO_BOUNDS_ERROR = 'FETCH_SEARCHMAP_GEO_BOUNDS_ERROR';

export const FETCH_SEARCHMAP_GEO_POINT = 'FETCH_SEARCHMAP_GEO_POINT';
export const FETCH_SEARCHMAP_GEO_POINT_START = 'FETCH_SEARCHMAP_GEO_POINT_START';
export const FETCH_SEARCHMAP_GEO_POINT_SUCCESS = 'FETCH_SEARCHMAP_GEO_POINT_SUCCESS';
export const FETCH_SEARCHMAP_GEO_POINT_ERROR = 'FETCH_SEARCHMAP_GEO_POINT_ERROR';

export const FETCH_SEARCHMAP_LISTINGS = 'FETCH_SEARCHMAP_LISTINGS';
export const FETCH_SEARCHMAP_LISTINGS_START = 'FETCH_SEARCHMAP_LISTINGS_START';
export const FETCH_SEARCHMAP_LISTINGS_SUCCESS = 'FETCH_SEARCHMAP_LISTINGS_SUCCESS';
export const FETCH_SEARCHMAP_LISTINGS_ERROR = 'FETCH_SEARCHMAP_LISTINGS_ERROR';

export const FETCH_SEARCHMAP_CLEAR_PERIMETER = 'FETCH_SEARCHMAP_CLEAR_PERIMETER';

export const EMPTY_SEARCHMAP_INFOBOX = 'EMPTY_SEARCHMAP_INFOBOX';

const fetchFn = (fetch: Function, verb: string, params: Object, uripath: string = '') => fetch(`/portal-srp/api/v1/search-map${uripath}`, {
  method: verb,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Connection: 'keep-alive',
  },
  body: JSON.stringify(params),
  credentials: 'same-origin',
});

const fetchLHFn = (fetch: Function, verb: string, url: string) => fetch(url, {
  method: verb,
  headers: {
    Accept: 'application/json',      
  },    
  credentials: 'same-origin',
});

export function fetchSearchMap(params: Object) {
  return ({ fetch }: { fetch: Function }) => ({
    type: FETCH_SEARCHMAP,
    payload: {
      search: 'something',
      promise: fetchFn(fetch, 'POST', params)
        .then(response => response.json())
        .catch(err => console.error('error', err)),
    },
  });
}

export function fetchSearchMapScroll(params: Object) {
  return ({ fetch }: { fetch: Function }) => ({
    type: FETCH_SEARCHMAPSCROLL,
    payload: {
      search: 'something',
      promise: fetchFn(fetch, 'POST', params, '/listings')
        .then(response => response.json())
        .catch(err => console.error('error', err)),
    },
  });
}

export function fetchListingOnFiltersChange(params: Object) {
  return ({ fetch }: { fetch: Function }) => ({
    type: FETCH_SEARCHMAP_FILTERS,
    payload: {
      search: 'something',
      promise: fetchFn(fetch, 'POST', params)
        .then(response => response.json())
        .catch(err => console.log('error', err)),
    },
  });
}

export function fetchListingOnGeoShape(params: Object) {
  params.apireq.size = 20;
  return ({ fetch }: { fetch: Function }) => ({
    type: FETCH_SEARCHMAP_GEO,
    payload: {
      search: 'something',
      promise: fetchFn(fetch, 'POST', params)
        .then(response => response.json())
        .catch(err => console.log('error', err)),
    },
  });
}

export function fetchListingOnGeoBounds(params: Object) {
  return ({ fetch }: { fetch: Function }) => ({
    type: FETCH_SEARCHMAP_GEO_BOUNDS,
    payload: {
      search: 'something',
      promise: fetchFn(fetch, 'POST', params, '/markers')
        .then(response => response.json())
        .catch(err => console.log('error', err)),
    },
  });
}

export function fetchListingOnGeoPoint(params: Object) {
  return ({ fetch }: { fetch: Function }) => ({
    type: FETCH_SEARCHMAP_GEO_POINT,
    payload: {
      search: 'something',
      promise: fetchLHFn(fetch, 'GET', params.url)
        .then(response => response.json())
        .catch(err => console.log('error', err)),
    },
  });
}

export function fetchOnClearPerimeter() {
  return { 
    type: FETCH_SEARCHMAP_CLEAR_PERIMETER,
  };
}

export function fetchListings(params) {
  return ({ fetch }) => ({
    type: FETCH_SEARCHMAP_LISTINGS,
    payload: {
      search: 'something',
      promise: fetchFn(fetch, 'POST', params, '/listings')
        .then(response => response.json())
        .catch(err => console.log('error', err)),
    },
  });
}
