/* global window */

export { default as fetch } from './fetch';
export { default as mobileDetection } from './mobileDetection';

export const canUseDOM = !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
);

export const capitalize = input => (input && (input.charAt(0).toUpperCase() + input.slice(1)) || '');
