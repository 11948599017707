import configureStore from './configureStore';

let store;

export default (state) => {
  if (state) {
    store = configureStore({
      initialState: state,
      platformReducers: {},
      platformStoreEnhancers: {},
    });
  }

  return store;
};
