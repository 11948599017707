// @flow
import * as actions from './actions';

const initialState = {};

export default function searchReducer(state: Object = initialState, action: Object) {
  switch (action.type) {
    case actions.EMPTY_SEARCHMAP_INFOBOX: {
      return Object.assign({}, state, {
        listInfoBox: [],
      });
    }

    case actions.FETCH_SEARCHMAP_START: {
      return Object.assign({}, state, {
        isFetching: true,
        isFetchingScroll: false,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCHMAP_SUCCESS: {
      return Object.assign({}, action.payload, state, {
        isFetching: false,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCHMAP_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_SEARCHMAPSCROLL_START: {
      return Object.assign({}, state, {
        isFetchingScroll: true,
        errorMessage: '',
      });
    }
    
    case actions.FETCH_SEARCHMAPSCROLL_SUCCESS: {      
      const { fillInExpanded } = action.payload;
      const list = fillInExpanded ? state.list : [...state.list, ...action.payload.list];
      const fewResults = !fillInExpanded ? action.payload.fewResults : {
        ...state.fewResults,
        listings: [...state.fewResults.listings, ...action.payload.list],
        startsExpanded: true,
      };
      
      return {
        ...state,
        isFetchingScroll: false,
        list,
        fewResults,
        errorMessage: '',
      };
    }
    
    case actions.FETCH_SEARCHMAPSCROLL_ERROR: {
      return Object.assign({}, state, {
        isFetchingScroll: false,
        errorMessage: action.payload.message,
      });
    }    

    case actions.FETCH_SEARCHMAP_FILTERS_START: {
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCHMAP_FILTERS_SUCCESS: {
      return Object.assign({}, state, action.payload, {
        sid: Math.random(),
        isFetching: false,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCHMAP_FILTERS_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_SEARCHMAP_GEO_START: {
      return Object.assign({}, state, {
        isFetching: true,
        isGeoPointReady: false,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCHMAP_GEO_SUCCESS: {
      return Object.assign({}, state, action.payload, {
        sid: Math.random(),
        isFetching: false,
        isGeoPointReady: false,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCHMAP_GEO_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        isGeoPointReady: false,
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_SEARCHMAP_GEO_BOUNDS_START: {
      return Object.assign({}, state, {
        isFetchingGeoBounds: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCHMAP_GEO_BOUNDS_SUCCESS: {
      return Object.assign({}, state, action.payload, {
        sid: Math.random(),
        isFetchingGeoBounds: false,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCHMAP_GEO_BOUNDS_ERROR: {
      return Object.assign({}, state, {
        isFetchingGeoBounds: false,
        errorMessage: action.payload.message,
      });
    }

    case actions.FETCH_SEARCHMAP_GEO_POINT_START: {
      return Object.assign({}, state, {
        isGeoPointReady: false,
        isFetching: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCHMAP_GEO_POINT_SUCCESS: {
      
      const { data } = action.payload;
      const perimeters = {};
      if (data.total) {
        data.locations.urbandistricts.map((ud) => {
          perimeters[ud.hkey] = ud.geo;
        /*  if (state.perimetersMap && Object.keys(state.perimetersMap).indexOf(ud.hkey) > -1) {
            delete state.perimetersMap[ud.hkey];
            delete perimeters[ud.hkey];
          } */
        }); 

        if (Object.keys(perimeters).length === 1) {
          if (state.test) {
            Object.keys(state.test).map(
              (el) => {
                if (el === data.locations.urbandistricts[0].hkey) {
                  delete state.test[el];
                  delete perimeters[el];
                }
              },
            );
          }
          action.payload.perimetersMap = Object.assign({}, state.perimetersMap, perimeters);          
          action.payload.test = Object.assign({}, state.test, perimeters);
          action.payload.outsidelux = false;
        }
      } else {
        action.payload.outsidelux = true;
      }

      action.payload.aggregateMap = [];
      
      return Object.assign({}, state, action.payload, { lastHKey: Object.keys(perimeters) }, {
        sid: Math.random(),
        isGeoPointReady: true,
        isFetching: false,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCHMAP_GEO_POINT_ERROR: {
      return Object.assign({}, state, {
        isGeoPointReady: true,
        isFetching: false,
        errorMessage: action.payload.message,
      });
    }    

    case actions.FETCH_SEARCHMAP_CLEAR_PERIMETER: {
      state.aggregateMap = [];
      state.perimetersMap = {};
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: '',
      });
    } 
    
    case actions.FETCH_SEARCHMAP_LISTINGS_START: {
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCHMAP_LISTINGS_SUCCESS: {
      return Object.assign({}, state, action.payload, {
        sid: Math.random(),
        isFetching: false,
        errorMessage: '',
      });
    }

    case actions.FETCH_SEARCHMAP_LISTINGS_ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload.message,
      });
    }

    default: {
      return state;
    }    
  }
}
