import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Grid from 'shared-components/components/Grid';

import ExternalLink from 'shared-vectors/icons/ExternalLink';
import Phone from 'shared-vectors/icons/Phone';
import Mail from 'shared-vectors/icons/Mail';
import { Icon } from 'shared-vectors';

import makeClassName from 'shared-utils/src/makeClassName';
import openSpecificModal from 'shared-utils/src/leads/openSpecificModal';
import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';
import trackTLEvents from 'shared-utils/src/trackTLEvents';
import sleep from 'shared-utils/src/sleep';
import deleteTealiumExtraData from 'shared-utils/src/deleteTealiumExtraData';
import CallAgencyTooltipContainer from 'shared-components/components/QRCodeTooltipFactory/CallAgencyTooltipContainer';

import { post } from '@helpers/http';
import { useCtxGlobal } from '@client/app/Container';
import { createQRCodeOnly } from '@client/components/common/DynamicLoader/qrcode';

import {
  createSpecificModal,
  createFeedbackModal,
} from '../../modals/factories';

const Buttons = ({ publisher, device, user }) => {
  const trackPosition = 'AP_Header';
  const {
    publisherId,
    publisherWebsite,
    publisherSlug,
    publisherPhone,
    showPhoneNumber,
    hasCrossSubscription,
    disableAuctions,
  } = publisher;

  const {
    openModal,
    closeModal,
    isMobile,
    updateUserFormData,
    siteUrl,
  } = useCtxGlobal();

  const [phoneReveal, setPhoneReveal] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [calling, setCalling] = useState(false);
  const hideTooltip = () => setTooltipVisible(false);

  const call = async () => {
    const label = `Agency - ${publisherId}`;

    sleep(300).then(() => trackGTMEvents({
      category: 'Lead',
      action: 'CallPhone',
      position: 'AP_Header',
      label,
    }, null, true));

    const productType = 'agency';

    const evMore = {
      contact: {
        channel: 'phone',
        productType,
        outcome: '',
        errorField: ['', '', ''],
      },
    };

    if (publisherSlug) {
      evMore.agency = {
        name: [publisherSlug],
      };
    }
    trackTLEvents({
      evObj: {
        event: {
          type: 'view',
          name: 'contact',
          origin: '',
        },
      },
      evMore,
    });

    if (hasCrossSubscription) {
      const proxyBaseUrl = '/portal-srp/api/v1/phonecall';
      post(proxyBaseUrl, {
        data: {
          publisherId,
        },
      });
    }
    deleteTealiumExtraData({ keysToDelete: ['agency'] });
    window.location.href = `tel:${publisherPhone}`;
  };

  const phoneClick = ({ fromModal = false } = {}) => {
    if (!fromModal && !phoneReveal && !isMobile) {
      sleep(300).then(() => trackGTMEvents({
        category: 'Lead',
        action: 'RevealPhone',
        position: trackPosition,
      }, null, true));
    }
    if (!isMobile) {
      setPhoneReveal(true);
      setTooltipVisible(true);
      if (phoneReveal) {
        if (!calling) {
          setCalling(true);
          setTimeout(() => {
            setCalling(false);
          }, 500);
          call();
        }
      }
    } else if (!calling) {
      setCalling(true);
      setTimeout(() => {
        setCalling(false);
      }, 750);
      call();
    }
  };

  const fakeLink = () =>
    window.open(
      publisherWebsite.indexOf('http') === -1
        ? `http://${publisherWebsite}`
        : publisherWebsite,
      '_blank',
    );

  const modalCallAction = async (payload) => {
    await closeModal(payload.closeModal);
    setTimeout(() => phoneClick({ fromModal: true }), 301);
  };

  const contactModal = async () => {
    sleep(300).then(() => trackGTMEvents({
      category: 'Interaction',
      action: 'OpenContactForm',
      position: trackPosition,
    }));

    const closeModalAction = payload => closeModal(payload.close);
    openSpecificModal({
      openModalAction: openModal,
      closeModalAction,
      createSpecificModal,
      createFeedbackModal,
      setUserFormDataAction: updateUserFormData,
      siteUrl,
      leadConf: {
        user,
        isMobile,
        device,
        app: 'P18',
        isPublisherLead: true,
        justEmail: true,
        publisher,
        startCall: modalCallAction,
      },
    });
  };

  const commonBtnCls = [
    'pbInfos__btn',
    'modal_handle',
    'tp-s--l',
    'tp-w--l',
    'c-txt--w',
    'b-r--m',
  ];

  const phoneCls = makeClassName([
    'pbInfos__btn--phone',
    'c-bg--secondary',
    ...commonBtnCls,
  ]);

  const phoneRevealCls = makeClassName([
    'pbInfos__btn--phone--reveal',
    phoneReveal ? 'revealed' : 'tp--ell',
  ]);

  const mailCls = makeClassName([
    'pbInfos__btn--mail',
    'c-bg--primary',
    ...commonBtnCls,
  ]);

  const webCls = makeClassName([
    'pbInfos__btn--website',
    'tp-s--l',
    'tp-w--m',
    'c-txt--f5',
    'is-clickable',
  ]);

  return (
    <Grid className="pbInfos__btns">
      <Grid className="pbInfos__btns-cnt">
        {showPhoneNumber && !disableAuctions && (
          <div className="pbInfos__btns--cnt-qrcode">
            <button
              type="button"
              className={phoneCls}
              data-action="phonecall"
              onClick={phoneClick}
            >
              <Icon name={Phone} />{' '}
              {device !== '' && device !== 'smartphone' ? (
                <span className={phoneRevealCls}>{publisherPhone}</span>
              ) : (
                'Chiama'
              )}
            </button>
            {phoneReveal && (
              <CallAgencyTooltipContainer
                showDynamicComponent={phoneReveal}
                createQRCodeOnly={createQRCodeOnly}
                visible={tooltipVisible}
                setTooltipVisible={hideTooltip}
                phone={publisherPhone}
                clsPositionToolip="--card-header"
                serverUri={siteUrl}
              />
            )}
          </div>
        )}
        {!disableAuctions && (
          <button
            type="button"
            className={mailCls}
            data-action="agencyLead"
            onClick={contactModal}
          >
            <Icon name={Mail} />{' '}
            {device !== '' && device !== 'smartphone'
              ? 'Invia messaggio'
              : 'Contatta'}
          </button>
        )}
      </Grid>
      {publisherWebsite && (
        <button type="button" className={webCls} onClick={fakeLink}>
          <Grid
            className="pbInfos__btn--website__icon-cnt"
            align="center"
            justify="center"
          >
            <Icon name={ExternalLink} width="24px" height="24px" />{' '}
          </Grid>
          <span className="tp--u">Sito web</span>
        </button>
      )}
    </Grid>
  );
};

export default Buttons;

Buttons.propTypes = {
  publisher: PropTypes.instanceOf(Object),
  device: PropTypes.string,
  user: PropTypes.instanceOf(Object),
};

Buttons.defaultProps = {
  publisher: {},
  device: PropTypes.string,
  user: PropTypes.instanceOf(Object),
};
