export const sortMap = {
  relevance: 'standard',
  rel: 'relevance',
  price_desc: 'highestPrice',
  price_asc: 'lowestPrice',
  date_desc: 'mostRecent',
  date_asc: 'leastRecent',
  surface_desc: 'biggest',
  surface_asc: 'smallest',
};

const tipologyMap = {
  case: 1,
  appartamento: 1,
  'attico+mansarda': 1,
  'baita+bungalow+chalet+trullo': 3,
  'casa+bi+trifamiliare': 2,
  'casa+indipendente': 2,
  loft: 1,
  palazzo: 11,
  'rustico+casale': 3,
  villa: 2,
  'villetta+a+schiera': 2,
  'nuove-costruzioni': 10,
  commerciale: 6,
  stanze: 7,
  terreni: 8,
  'garage-posti-auto': 4,
  'cantine-solai': 12,
};

export const listAdsIds = (list) => {
  if (!list || !list.length || list === 'NOT_AVAILABLE') {
    return [''];
  }
  return list.split('_');
};

export const getGeo = (dl) => {
  const geo = {};
  if (dl.propertyCountry && dl.propertyCountry !== 'NOT_AVAILABLE') {
    let l = dl.propertyCountry;
    if (dl.propertyRegion) {
      l += `_${dl.propertyRegion}`;
    }
    if (dl.propertyProvince) {
      l += `_${dl.propertyProvince}`;
    }
    if (dl.propertyCity) {
      l += `_${dl.propertyCity}`;
    }
    geo.geo = {
      locationId: l,
    };
  }
  return geo;
};

export const getOperation = (dl) => {
  const operation = {};
  if (dl.siteSection) {
    operation.operation = dl.siteSection === 'buy' ? '1' : '2';
  }
  return operation;
};

export const getAgency = (state) => {
  const agency = {};
  if (state?.publisher?.publisherSlug) {
    agency.agency = {
      name: [state?.publisher?.publisherSlug],
    };
  }
  return agency;
};

const getAllPTypes = (list) => {
  if (typeof list === 'string') {
    return list.split(',');
  }
  return list || [];
};

export const getTypology = (state) => {
  const typology = {};
  try {
    let ptype;
    const { queryFilters } = state;
    if (queryFilters) {
      const {
        category,
        propertyTypeGroup,
        propertyTypes,
      } = queryFilters;
      let ptypeGroup = propertyTypeGroup || null;
      if (category && !ptypeGroup) {
        ptypeGroup = category;
      }
      const pTypes = propertyTypes
        ? getAllPTypes(propertyTypes)
        : [];
  
      if (
        !['case', 'nuove-costruzioni'].includes(ptypeGroup)
        || !pTypes.length
        || pTypes.length > 1
      ) {
        ptype = tipologyMap[ptypeGroup];
      } else {
        ptype = tipologyMap[(pTypes[0] || '').replace(/ /g, '+')] || null;
      }
      if (ptype) {
        typology.typology = ptype;
      }
    }
  } catch (error) {
    console.log('Tealium getTypology: ', error);
  }

  return typology;
};

export const getPartnerIds = (state) => {
  let partnerIds = [''];
  if (state) {
    const { list = [], isAgencySrp = false } = state;

    if (!isAgencySrp && list && list.length) {
      const partnerIdsArr = [];
      for (let i = 0; i <= list.length - 1; i += 1) {
        if (list[i]) {
          partnerIdsArr.push(list[i].partnerId.toString());
        }
      }
      partnerIds = partnerIdsArr.join('_');
    }
  }
  return partnerIds;
};

export const getTotalResults = (state) => {
  if (state) {
    const { total } = state;
    if (total && parseInt(total, 10)) {    
      return total;
    }
  }
  return null;
};

export const getTotalPageNumber = (state) => {
  if (state) {
    const { paginator } = state;
    if (paginator && paginator.totalPages) {
      return paginator.totalPages;
    }    
  }
  return 0;
};

export const getResponseCode = (state) => {
  let rc = 200;
  try {
    if (state && state.error) {
      rc = 503;
    }
    if (
      state
      && (
        state.agency404
        || state.total === 0
      )
    ) {
      if (state.agency404) {
        rc = state.isPublisherHP && 'publisher' in state || state.seoDataHeader && state.seoDataHeader.isSrpUrl
          ? 200
          : 404;
      }
      if (state.total === 0 && !state.isPublisherHP) {
        rc = 404;
      }
    }
  } catch (error) {
    return {};
  }
  return {
    response: {
      statusCode: rc,
    },
  };
};
